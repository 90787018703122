import { currencyFromCents } from '@utils/helpers/currency';
import { SlotDurationFilter } from '@data/api/endpoints/v2/availability/check_available';
import { type AddOnPriceOption, type LocationCode } from '@data/api/endpoints/v3/my_toshi/orders/[id]';

export const slotDurationFilterMap: Record<string, SlotDurationFilter> = {
  '1-hour-upgrade': SlotDurationFilter.oneHour,
  '2-hour-upgrade': SlotDurationFilter.twoHour,
};

export const renderCurrencyFromAddOn = (option: AddOnPriceOption, locationCode: LocationCode) =>
  currencyFromCents(option.priceInCents, locationCode, true);
