{
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "language": "en-GB",
  "translations": {
    "arriveOn": "Date",
    "between": "Time",
    "bringItemsTo": "Please deliver the gift item(s) to",
    "city": "City/Town",
    "country": "Country",
    "cta": "Confirm",
    "customiseYourExperience": "Customise your experience",
    "dropoff": "Scheduled delivery",
    "dropoffDescription": "Your TOSHI Assistant will drop off your items.",
    "enterManually": "Or enter the address manually",
    "errorSubmittingGiftOrder": "There was an error submitting your details, please try again. If this problem persists, please contact us via the details listed on this screen.",
    "fetchingDates": "Fetching dates...",
    "fetchingSlots": "Fetching slots...",
    "fieldIsRequired": "Field is required.",
    "inspireMe": "Inspire Me",
    "inspireMeDescription": "Additional items that you would like to try (sizes, colours, styles, etc) - pay only for what you keep.",
    "inspireMeExtraTextPlaceholder": "Please list the additional details you would like to try, including sizes, colours, styles etc. (you’ll only pay for what you keep)!",
    "line1": "Address Line 1",
    "line2": "Address Line 2",
    "mustBeLondonZones": "Your delivery address must be in London zones 1 - 6",
    "mustBeManhattan": "Your delivery address must be in Manhattan or Brooklyn",
    "noAvailabilitySelectDifferentDate": "There is no availability on this date, please select a different date to continue.",
    "noSlotsAvailableForDate": "No slots are available for this date.",
    "orManual": "Or enter the address manually",
    "orSearch": "Or search for the address",
    "pleaseBookConvenientDate": "Please select a date for the delivery of your order.",
    "pleaseBookConvenientDateTime": "Please select a date and time for the delivery of your order.",
    "pleaseEnterAddressSelectDate": "Please enter an address and select a date.",
    "pleaseEnterAnAddress": "Please enter an address.",
    "postcode": "Postcode/Zipcode",
    "rescheduled": "Your delivery has been rescheduled successfully",
    "selectDate": "Select date",
    "selectTime": "Select time",
    "serverError": "Server Error. Please check your connection.",
    "startTyping": "Start typing your address..",
    "today": "Today",
    "tomorrow": "Tomorrow",
    "toshiAssistantAddressInfo": "A TOSHI Assistant will deliver your items to",
    "toshiAssistantWaitWhilstDecide": "Your TOSHI Assistant can wait whilst you decide and take back unwanted item(s) hassle free.",
    "toshiStandardExplanation": "TOSHI will email you a 1-hour delivery window on the morning of your delivery.",
    "toshiUpdates": "I would like to opt in to TOSHI updates",
    "viewTermsAndConditions": "View Terms and Conditions",
    "waitAndTry": "Wait & Try",
    "yourToshiAssistantWillHelpWith": "Your TOSHI Assistant can help with the following additional services:"
  }
}
