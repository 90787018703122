import { z } from 'zod';

import { zodNonEmptyString } from '@utils/helpers/validation';
import { Contact } from '@data/models/Contact';
import { transformDTO } from '@data/transformers/responses';

const _contactDTOSchema = z
  .object({
    contact_number: z.string().nullish(),
    email: zodNonEmptyString(),
    first_name: zodNonEmptyString(),
    id: z.number(),
    last_name: zodNonEmptyString(),
    title: z.string().nullish(),
  })
  .transform(transformDTO);

export const contactDTOSchema = _contactDTOSchema.transform((data) => new Contact(data));

export type ContactDTO = z.input<typeof _contactDTOSchema>;

export type IContact = z.output<typeof _contactDTOSchema>;
