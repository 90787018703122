import { z } from 'zod';

import { zodNonEmptyString } from '@utils/helpers/validation';
import { transformDTO } from '@data/transformers/responses';

export const addressDTOSchema = z
  .object({
    city_town: zodNonEmptyString(),
    closest_zone: z.string().nullish(),
    house_name: z.string().nullish(),
    id: z.number(),
    line_1: zodNonEmptyString(),
    line_2: z.string().nullish(),
    notes: z.string().nullish(),
    postcode_zipcode: zodNonEmptyString(),
    zones: z.string().nullish(),
  })
  .transform(transformDTO);

export type AddressDTO = z.input<typeof addressDTOSchema>;
export type Address = z.output<typeof addressDTOSchema>;
