/**@deprecated */
export const breakPoint2XL = 1536;
/**@deprecated */
export const breakPointXL = 1280;
/**@deprecated */
export const breakPointLG = 1024;
/**@deprecated */
export const breakPointMD = 768;
/**@deprecated */
export const breakPointSM = 640;

export const breakPoints = {
  mobile: 43,
  tablet: 62,
  laptop: 75,
} as const;

export const isMobileMq = `(max-width: ${breakPoints.mobile}rem)` as const;
export const isMobileOrTabletMq = `(max-width: ${breakPoints.laptop}rem)` as const;
export const isTabletMq = `(min-width: ${breakPoints.mobile}rem) and (max-width: ${breakPoints.laptop}rem)` as const;
export const isTabletOrDesktopMq = `(min-width: ${breakPoints.mobile}rem)` as const;
export const isDesktopMq = `(min-width: ${breakPoints.laptop}rem)` as const;

const buildMediaQuery = <T>(mq: T) => `@media screen and ${mq}` as const;

export const queriesForTheme = {
  isMobile: buildMediaQuery(isMobileMq),
  isMobileOrTablet: buildMediaQuery(isMobileOrTabletMq),
  isTablet: buildMediaQuery(isTabletMq),
  isTabletOrDesktop: buildMediaQuery(isTabletOrDesktopMq),
  isDesktop: buildMediaQuery(isDesktopMq),
} as const;
