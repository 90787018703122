import { createBrowserRouter, type RouteObject } from 'react-router-dom';

import { RequireAuth } from './contexts/auth/RequireAuth';
import { OrderInfoErrorPage } from './pages/errors/OrderInfoErrorPage';
import { OrdersList } from './components/features/ordersList/OrdersList';
import { CreateOrder } from './pages/dhl/CreateOrder';
import { GenericError } from './pages/errors/GenericError';
import { InvalidUrl } from './pages/errors/InvalidUrl';
import { Unauthorized } from './pages/errors/Unauthorized';
import { OrderComplete } from './pages/gifting/giftComplete/OrderComplete';
import { Gifting } from './pages/gifting/Gifting/Gifting';
import { BookOrder } from './pages/orders/book/BookOrder';
import { Checkout } from './pages/orders/checkout/Checkout';
import { Status } from './pages/orders/checkout/status/Status';
import { OrderDetails } from './pages/orders/orderDetails/OrderDetails';
import { RescheduleOrder } from './pages/orders/reschedule/RescheduleOrder';
import { Root } from './pages/Root';

export enum RoutePaths {
  unauthorized = '/unauthorized',
  giftingError = '/gifting/error',
  gifting = '/gifting/:id',
  order = '/orders/:id',
  orderComplete = '/orders/:id/complete',
  orderBook = '/orders/:id/book', // soon to be deprecated
  orderBookDelivery = '/orders/:id/book/delivery',
  orderBookReturn = '/orders/:id/book/return',
  orderReschedule = '/orders/:id/reschedule', // soon to be deprecated
  orderRescheduleDelivery = '/orders/:id/reschedule/delivery',
  orderRescheduleReturn = '/orders/:id/reschedule/return',
  orderCheckout = '/orders/:id/checkout',
  orderCheckoutStatus = '/orders/:id/checkout/status',
  orders = '/orders',
  createDhlOrder = '/dhl/orders/new',
  catchAll = '*',
}

export const routes: RouteObject[] = [
  {
    element: <Root />,
    errorElement: <GenericError />,
    children: [
      {
        path: RoutePaths.createDhlOrder,
        element: <CreateOrder />,
      },
      {
        path: RoutePaths.unauthorized,
        element: <Unauthorized />,
      },
      {
        path: RoutePaths.giftingError,
        element: <OrderInfoErrorPage />,
      },
      {
        path: RoutePaths.gifting,
        element: (
          <RequireAuth>
            <Gifting />
          </RequireAuth>
        ),
      },
      {
        path: RoutePaths.order,
        children: [
          {
            index: true,
            element: (
              <RequireAuth>
                <OrderDetails />
              </RequireAuth>
            ),
          },
          {
            path: RoutePaths.orderComplete,
            element: (
              <RequireAuth>
                <OrderComplete />
              </RequireAuth>
            ),
          },
          {
            path: RoutePaths.orderCheckout,
            children: [
              {
                index: true,
                element: (
                  <RequireAuth>
                    <Checkout />
                  </RequireAuth>
                ),
              },
              {
                path: RoutePaths.orderCheckoutStatus,
                element: (
                  <RequireAuth>
                    <Status />
                  </RequireAuth>
                ),
              },
            ],
          },
          {
            path: RoutePaths.orderBook,
            element: (
              <RequireAuth>
                <BookOrder />
              </RequireAuth>
            ),
          },
          {
            path: RoutePaths.orderBookDelivery,
            element: (
              <RequireAuth>
                <BookOrder />
              </RequireAuth>
            ),
          },
          {
            path: RoutePaths.orderBookReturn,
            element: (
              <RequireAuth>
                <BookOrder />
              </RequireAuth>
            ),
          },
          {
            path: RoutePaths.orderReschedule,
            element: (
              <RequireAuth>
                <RescheduleOrder />
              </RequireAuth>
            ),
          },
          {
            path: RoutePaths.orderRescheduleDelivery,
            element: (
              <RequireAuth>
                <RescheduleOrder />
              </RequireAuth>
            ),
          },
          {
            path: RoutePaths.orderRescheduleReturn,
            element: (
              <RequireAuth>
                <RescheduleOrder />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: RoutePaths.orders,
        element: (
          <RequireAuth>
            <OrdersList />
          </RequireAuth>
        ),
      },
      {
        path: RoutePaths.catchAll,
        element: <InvalidUrl />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
