import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useState, type FC } from 'react';
import styled from 'styled-components';

import { BookerForm, type BookerFormSubmitParams } from '@components/features/bookerForm/BookerForm';
import { Column, cardStyles } from '@components/ui/Containers';
import { RadioButton } from '@components/ui/formComponents/FormComponents';
import { StyledH2, StyledParagraph } from '@components/ui/Typography';
import { queriesForTheme } from '@config/styling/breakPoints';
import { DateFormat, formatDateTodayOrTomorrow, parseDate } from '@utils/helpers/dates';
import { BookerMode } from '@hooks/useBookerMode';
import { useTranslations } from '@locale/useTranslations';
import { Availability } from '@data/models/Availability';
import { type Order } from '@data/models/Order';
import { type AddOnPriceOption } from '@data/api/endpoints/v3/my_toshi/orders/[id]';
import {
  bookDeliveryPendingEndpoint,
  bookDeliveryWithPriceParamsSchema,
} from '@data/api/endpoints/v3/my_toshi/orders/[id]/pending_delivery_journeys';
import { useBookerMutation } from '@data/hooks/useBookerMutation';

import { StripeCheckout } from '../StripeCheckout';
import { translations } from '../translations';
import { renderCurrencyFromAddOn, slotDurationFilterMap } from './helpers';
import { UpgradeFormSection } from './UpgradeFormSection';

const Wrapper = styled(Column)`
  padding: 0;

  ${queriesForTheme.isTabletOrDesktop} {
    ${cardStyles}
  }
`;

const RadioContainer = styled(motion.div)`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid;
  border-color: var(--colour-ghost-grey);
  background: var(--colour-white);

  > label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.75rem;

    > p {
      font-size: var(--font-size-3);

      &#price {
        margin-inline-start: auto;
      }
    }
  }

  &:has(input:checked) {
    border-color: var(--colour-black);
    background: var(--colour-lighter-grey);

    > label > p {
      font-weight: 600;
    }
  }
`;

export const UpgradeForm: FC<{ order: Order }> = ({ order }) => {
  const { translationsContent } = useTranslations(translations);

  const productNameMap: Record<string, string> = {
    '1-hour-upgrade': translationsContent.oneHourWindow,
    '2-hour-upgrade': translationsContent.twoHourWindow,
  };

  const addOnPriceOptions = order.chargeableAddOns
    .find((addOn) => addOn.productType === 'delivery_slot')
    ?.addOnPriceOptions?.sort((first, second) => second.priceInCents - first.priceInCents);

  const [{ confirmedAddOn, selectedAddOn }, setState] = useState<{
    confirmedAddOn?: AddOnPriceOption;
    selectedAddOn?: AddOnPriceOption;
  }>({
    confirmedAddOn: undefined,
    selectedAddOn: undefined,
  });

  const setSelectedAddOn = (selectedAddOn?: AddOnPriceOption) => setState((prev) => ({ ...prev, selectedAddOn }));
  const setConfirmedAddOn = (confirmedAddOn?: AddOnPriceOption) => setState((prev) => ({ ...prev, confirmedAddOn }));

  const {
    data,
    trigger: bookDelivery,
    error,
    reset,
  } = useBookerMutation({
    url: bookDeliveryPendingEndpoint(String(order.id)),
    reqSchemaConfig: {
      schema: bookDeliveryWithPriceParamsSchema,
      schemaName: 'BookDeliveryPendingParams',
    },
  });

  // if (error) throw error;

  const onSubmit = useCallback(
    (addOn: AddOnPriceOption) =>
      async ({
        deliveryDate,
        deliverySlotId,
        services: { waitAndTry, dropoff, inspireMe },
      }: BookerFormSubmitParams) => {
        reset();

        const bookResponse = await bookDelivery(
          {
            deliveryJourney: { deliveryDate, deliverySlotId },
            services: { waitAndTry, dropoff, inspireMe },
            chargeablePriceOptions: [addOn.id],
          },
          { throwOnError: true },
        );

        if (!bookResponse) {
          throw bookResponse;
        } else {
          setConfirmedAddOn(addOn);
        }
      },
    [bookDelivery, reset],
  );

  const priceOptions = addOnPriceOptions?.map((option) => {
    const id = `slot-radio-${option.id}`;
    const selected = selectedAddOn?.id === option.id;

    return (
      <RadioContainer key={option.id}>
        <label htmlFor={id}>
          <RadioButton id={id} checked={selected} onChange={() => setSelectedAddOn(option)} />
          <p>{productNameMap[option.productOptionName]}</p>
          <p id="price">{renderCurrencyFromAddOn(option, order.locationCode)}</p>
        </label>
        <AnimatePresence mode="wait">
          {selected && (
            <BookerForm
              mode={BookerMode.delivery}
              order={order}
              onSubmit={onSubmit(option)}
              submitError={!!error}
              toastCopy="Your slot has been reserved"
              slotDurationFilter={slotDurationFilterMap[option.productOptionName]}
              minimal
            />
          )}
        </AnimatePresence>
      </RadioContainer>
    );
  });

  return (
    <Wrapper as="article">
      <StyledH2 $center>{translationsContent.upgradeHeader}</StyledH2>
      <UpgradeFormSection
        complete={Boolean(confirmedAddOn)}
        editable
        title={translationsContent.selectService}
        completeChildren={
          <Column $noPadding $gap={0.2}>
            {[
              confirmedAddOn &&
                `${productNameMap[confirmedAddOn.productOptionName]} (${renderCurrencyFromAddOn(confirmedAddOn, order.locationCode)})`,
              data?.deliveryDate &&
                formatDateTodayOrTomorrow(
                  parseDate(data.deliveryDate, DateFormat.yearMonthDate),
                  DateFormat.dayDateMonthYear,
                ),
              data?.deliverySlot && Availability.formatSlot(data.deliverySlot),
            ]
              .filter(Boolean)
              .map((content) => (
                <StyledParagraph key={content} $noMargin $size={2}>
                  {content}
                </StyledParagraph>
              ))}
          </Column>
        }
      >
        <Column $noPadding>{priceOptions}</Column>
      </UpgradeFormSection>

      <UpgradeFormSection
        title={translationsContent.enterPaymentDetails}
        editable={false}
        complete={false}
        completeChildren={<></>}
      >
        {data?.stripeCheckoutSecret && <StripeCheckout clientSecret={data.stripeCheckoutSecret} />}
      </UpgradeFormSection>
    </Wrapper>
  );
};
