import styled from 'styled-components';

import { Page } from '@pages/Page';
import giftIcon from '@assets/icons/gift-icon.svg';
import { SpanBold, StyledH3, StyledParagraphMedium } from '@components/ui/Typography';
import { DateFormat, formatDate } from '@utils/helpers/dates';
import { useOrderId } from '@hooks/useOrderId';
import { useTranslations } from '@locale/useTranslations';
import { useGetOrder } from '@data/hooks/useGetOrder';

import { translations } from './translations/index';

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f7f7f7;
  text-align: center;
  gap: 30px;
`;

const GiftScheduledTitle = styled(StyledH3)`
  text-align: center;
`;

const GiftScheduledLine = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  gap: 10px;
`;

const GiftIcon = styled.img`
  height: ${(props) => props.theme.fontSizeLarge};
  margin-right: 5px;
  background-color: transparent;
`;

export const OrderComplete = (): JSX.Element => {
  const { translationsContent } = useTranslations(translations);

  const { id } = useOrderId();

  const { data: order } = useGetOrder(id, { revalidateOnMount: false });

  if (order?.deliveryInfo) {
    return (
      <Page>
        <Wrapper>
          <GiftScheduledLine>
            <GiftIcon src={giftIcon} alt="Gift icon" />
            <GiftScheduledTitle>{translationsContent.giftScheduled}</GiftScheduledTitle>
            <GiftIcon src={giftIcon} alt="Gift icon" />
          </GiftScheduledLine>
          <StyledParagraphMedium>
            {translationsContent.itWillBeDeliveredTo} <SpanBold>{order.deliveryInfo?.deliveryAddress}</SpanBold>
            {` ${translationsContent.On}`}{' '}
            <SpanBold>{`${formatDate(new Date(order.deliveryInfo.bookingDate), DateFormat.dateMonth)}`},</SpanBold>
            {` ${translationsContent.Between}`}{' '}
            <SpanBold>
              {order.deliveryInfo.bookingTimeslotStart} - {order.deliveryInfo.bookingTimeslotEnd}.
            </SpanBold>
          </StyledParagraphMedium>
        </Wrapper>
      </Page>
    );
  }

  return <></>;
};
