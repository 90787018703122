import { useEffect, useState } from 'react';
import { substituteVariables } from './substituteVariables';
import get from 'lodash.get';

interface TranslationsContent {
  [x: string]: string | TranslationsContent;
}

export interface TranslationsJSON<T extends TranslationsContent> {
  language: string;
  errors: {
    default: string;
    translations: {
      missing: string;
    };
  };
  translations: T;
}

interface Langs {
  en: {
    gb: 'GB';
    us: 'US';
  };
}

type ValueOf<T> = T[keyof T];

type Locale = {
  [C in keyof Langs]: Langs[C] extends { [x: string]: string } ? `${C}-${ValueOf<Langs[C]>}` : never;
}[keyof Langs];

// export type useTranslationsParam<T> = {
//   [x in Locale]: TranslationsJSON<T>;
// };
export type useTranslationsParam<T extends TranslationsContent> = Record<string, TranslationsJSON<T>>;

interface useTranslationsResult<T extends TranslationsContent> {
  translationsContent: T;
  getTranslationWithValues: (translation: string, values: string[]) => string;
  getTranslationForDynamicKey: (translationKey?: string) => string;
}

/**
 *
 * @param translations A translations json file
 * @returns Translations map and two functions:
 *
 * translationsContent - Map of translation key-value pairs for the language of the given user
 *
 * getTranslationWithValues - Used for translating server responses
 *
 * getTranslationForDynamicKey - Used for translating text with variables in, e.g.
 * "SOME_KEY": "We like both {1} and {2} here at company {3}"
 */
export function useTranslations<T extends TranslationsContent>(
  translations: useTranslationsParam<T>,
): useTranslationsResult<T> {
  const detectedLocale = (navigator.language ?? navigator.languages?.[0] ?? 'en-GB') as Locale;

  const [locale] = useState<Locale>(detectedLocale);

  useEffect(() => {
    document.querySelector('html')?.setAttribute('lang', locale);
  }, [locale]);

  const { translations: translationsContent, errors } = translations[detectedLocale] ?? translations['en-GB'];

  return {
    translationsContent,
    getTranslationWithValues: substituteVariables,
    getTranslationForDynamicKey: (translationKey = '') =>
      get(translationsContent, translationKey, errors.translations.missing) as string,
  };
}
