import { type FC } from 'react';

import marker from '@assets/icons/location-marker.svg';
import { StyledParagraph } from '@components/ui/Typography';
import { type Address } from '@data/api/endpoints/shared/addressDTO';

import { IconSection } from './IconSection';

interface Props {
  translations: {
    collectionAddress: string;
    deliveryAddress: string;
  };
  deliveryAddress: Address;
  isReturnJourney: boolean;
}

export const DeliveryInfo: FC<Props> = ({
  translations,
  deliveryAddress: { cityTown, postcodeZipcode, line1, line2 },
  isReturnJourney,
}) => {
  const title = isReturnJourney ? translations.collectionAddress : translations.deliveryAddress;
  const address = [cityTown, cityTown, postcodeZipcode.toUpperCase()].join(', ');

  return (
    <IconSection
      title={title}
      iconProps={{
        src: marker,
        alt: 'Location Marker',
      }}
      content={
        <StyledParagraph $noMargin $size="md">
          {line1}
          <br />
          {line2 && (
            <>
              {line2}
              <br />
            </>
          )}
          {address}
        </StyledParagraph>
      }
    />
  );
};
