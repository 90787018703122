import { type FC } from 'react';
import styled from 'styled-components';

import shoppingBag from '@assets/icons/shopping-bag.svg';
import { StyledParagraph } from '@components/ui/Typography';
import { Column } from '@components/ui/Containers';
import { type Order } from '@data/models/Order';

import { IconSection } from './IconSection';
import { type translationsType } from './translations';

export const InspireMeText = styled(StyledParagraph).attrs({ $size: 'sm', $noMargin: true })``;

export const FadedParagraphDescription = styled(InspireMeText)`
  color: ${(props) => props.theme.fontColorFaded};
`;

interface OrderServiceProps {
  serviceTitle: string;
  serviceDescription: string;
  inspireMeText?: string;
}

const OrderService: FC<OrderServiceProps> = ({ serviceTitle, serviceDescription, inspireMeText }) => (
  <Column $noPadding $gap={0.5}>
    <StyledParagraph $size="md" $noMargin>
      {serviceTitle}
    </StyledParagraph>
    {inspireMeText && <InspireMeText>{`Your request: "${inspireMeText}"`}</InspireMeText>}
    <FadedParagraphDescription>{serviceDescription}</FadedParagraphDescription>
  </Column>
);

interface OrderServicesProps {
  order: Order;
  translationsContent: translationsType;
}

export const OrderServices: FC<OrderServicesProps> = ({ order, translationsContent }) => (
  <IconSection
    title={translationsContent.selectedToshiServices}
    iconProps={{
      src: shoppingBag,
      alt: 'Shopping bag icon',
    }}
    content={
      <>
        {(Object.keys(order.services) as Array<keyof Order['services']>).map((service) => {
          if (!order.services[service].enabled || service === 'dropoff') {
            return undefined;
          }

          const serviceTitle = translationsContent[service];
          const serviceDescription = translationsContent[`${service}Description`];
          const inspireMeText = service === 'inspireMe' ? order.services['inspireMe'].text : undefined;

          if (!serviceTitle || !serviceDescription) {
            return undefined;
          }

          return (
            <OrderService
              key={service}
              serviceTitle={serviceTitle}
              serviceDescription={serviceDescription}
              inspireMeText={inspireMeText}
            />
          );
        })}
      </>
    }
  />
);
