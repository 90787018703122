import { type FC } from 'react';

import { Page } from '@pages/Page';
import { FullPageCenteredColumn } from '@components/ui/Containers';
import { StyledParagraph } from '@components/ui/Typography';
import { useMyToshiDocumentTitle } from '@hooks/useMyToshiDocumentTitle';
import { useTranslations } from '@locale/useTranslations';

import { translations } from './translations';

export const InvalidUrl: FC = () => {
  const { translationsContent } = useTranslations(translations);

  useMyToshiDocumentTitle(translationsContent.pageTitleInvalidUrl);

  return (
    <Page>
      <FullPageCenteredColumn>
        <StyledParagraph $size="md" $noMargin>
          {translationsContent.invalidUrlHeader}
        </StyledParagraph>
        <StyledParagraph $size="sm" $noMargin>
          {translationsContent.invalidUrlBody}
        </StyledParagraph>
        <StyledParagraph $size="sm" $noMargin>
          {translationsContent.invalidUrlFooter}
        </StyledParagraph>
      </FullPageCenteredColumn>
    </Page>
  );
};
