{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "giftScheduled": "Your gift has been scheduled for delivery!",
    "itWillBeDeliveredTo": "Your gift will be delivered by a TOSHI Assistant to",
    "On": "on",
    "Between": "between"
  }
}
