import { memo, type FC, type PropsWithChildren } from 'react';
import { useHref, useLocation, useNavigate, useParams } from 'react-router';

import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { useDebouncedEffect } from '@hooks/useDebouncedEffect';
import { useTranslations } from '@locale/useTranslations';
import { RoutePaths } from 'src/router';

import { useAuth } from './AuthContext';
import { translations } from './translations';

export const RequireAuth: FC<PropsWithChildren> = memo(({ children }) => {
  const { user, loggingIn, logInError } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const redirectedFrom = useHref(location, { relative: 'path' });
  const path = useHref(location);
  const { id } = useParams<{ id: string }>();

  const { translationsContent } = useTranslations(translations);

  useDebouncedEffect(
    () => {
      if (logInError || (!user && !loggingIn))
        navigate(RoutePaths.unauthorized, { state: { redirectedFrom, path, orderId: id } });
    },
    1500,
    [id, logInError, loggingIn, navigate, path, redirectedFrom, user],
  );

  if (!user) {
    return <LoadingSpinner copy={translationsContent.waitingForAuth} />;
  }

  return children;
});

RequireAuth.displayName = 'RequireAuth';
