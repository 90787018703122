import { type FC } from 'react';

import receipt from '@assets/icons/receipt.svg';
import { StyledParagraph } from '@components/ui/Typography';

import { type translationsType } from './translations';
import { IconSection } from './IconSection';

interface Props {
  brandName: string;
  orderNumber: string;
  translations: translationsType;
}

export const OrderNumber: FC<Props> = ({ brandName, orderNumber, translations }) => (
  <IconSection
    title={translations.orderNumber}
    iconProps={{
      src: receipt,
      alt: 'Receipt Icon',
    }}
    content={
      <StyledParagraph $noMargin $size="md">
        {brandName} - #{orderNumber}
      </StyledParagraph>
    }
  />
);
