import { z } from 'zod';

import { addressParamSchema } from '@data/api/endpoints/shared/addressParam';
import { deliveryDateSlotParamSchema } from '@data/api/endpoints/shared/deliveryDateDeliverySlotParam';
import { transformParams } from '@data/transformers/responses';

import { allToshiServicesDTOSchema } from '.';
import { v3MyToshiBaseUrl } from '../../baseUrl';

export const rescheduleDeliveryEndpoint = (orderId: string) =>
  `${v3MyToshiBaseUrl}/orders/${orderId}/rescheduled_delivery_journeys` as const;

export const rescheduleDeliveryParamsSchema = z
  .object({
    address: addressParamSchema.optional(),
    deliveryJourney: deliveryDateSlotParamSchema.extend({
      receiveUpdates: z.boolean(),
      gifted: z.boolean().optional(),
    }),
    services: allToshiServicesDTOSchema.pick({ inspire_me: true, wait_and_try: true }).optional(),
    toshiUpdates: z.boolean().optional(),
  })
  .transform(transformParams);

export type RescheduleDeliveryParams = z.infer<typeof rescheduleDeliveryParamsSchema>;
