import { type FC, type ChangeEvent } from 'react';
import { type UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';

import { StyledInput } from '@components/ui/formComponents/FormComponents';

import { type SearchResult } from './AddressFields';
import { useBookerFormTranslations } from './useBookerFormTranslations';
import { type BookerFormFieldValues } from './BookerForm';
import { StyledParagraph } from '@components/ui/Typography';

const AddressLookupContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const ResultsContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: 123px;
  flex-direction: column;
  width: 100%;
`;

const AddressSelectButton = styled.button`
  margin: 0; /* fixes Safari weirdness */
  padding: 12px 24px 14px;
  border: 1px solid black;
  border-top: none;
  background-color: ${(props) => props.theme.lightGrey};
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.invertColor};
  }
`;

interface AddressFinderProps {
  setValue: UseFormSetValue<BookerFormFieldValues>;
  setManualLookUp: (manualLookup: boolean) => void;
  results: SearchResult[];
  term: string;
  setTerm: (term: string) => void;
}

const parseSearchResultToString = (result: SearchResult): string => Object.values(result).filter(Boolean).join(', ');

export const AddressFinder: FC<AddressFinderProps> = ({ results, setValue, setManualLookUp, term, setTerm }) => {
  const { translationsContent } = useBookerFormTranslations();

  const handleAddressResultClick = (result: SearchResult) => {
    setValue('line1', result.addressLine1);
    setValue('line2', result.addressLine2);
    setValue('city', result.town ?? '');
    setValue('postcode', result.postcode);
    setValue('country', result.country);
    setManualLookUp(true);
  };

  return (
    <AddressLookupContainer>
      <StyledParagraph bold $size={'md'} style={{ marginBottom: '2rem' }}>
        {translationsContent.bringItemsTo}
      </StyledParagraph>
      <StyledInput
        value={term}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setTerm(event.target.value)}
        placeholder={translationsContent.startTyping}
      />
      <ResultsContainer>
        {results.map((result) => (
          <AddressSelectButton key={JSON.stringify(result)} onClick={() => handleAddressResultClick(result)}>
            {parseSearchResultToString(result)}
          </AddressSelectButton>
        ))}
      </ResultsContainer>
    </AddressLookupContainer>
  );
};
