import { type FC } from 'react';

import { ColoredContainer } from '@components/ui/Containers';
import { SpanBold, StyledParagraph } from '@components/ui/Typography';
import { IconSection } from './IconSection';

interface Props {
  shippingFrom: string;
}

export const ShippingFrom: FC<Props> = ({ shippingFrom }) => (
  <ColoredContainer
    $bordered
    $borderColor="#0074CC"
    $borderWidth="2px"
    $backgroundColor="rgba(0, 116, 204, 0.1)"
    $padding={0.5}
  >
    <IconSection
      title={
        <StyledParagraph $noMargin $center $size="md">
          Shipping from <SpanBold>{shippingFrom.toUpperCase()}</SpanBold>
        </StyledParagraph>
      }
      iconProps={{
        src: 'https://toshi-images.s3.eu-west-2.amazonaws.com/marketing/emails/Italy+flag.png',
        alt: 'Receipt Icon',
      }}
    />
  </ColoredContainer>
);
