import { type Address } from '@data/api/endpoints/shared/addressDTO';
import { type Journey, type JourneyTransition } from '@data/api/endpoints/shared/journeyDTO';
import {
  type AllToshiServices,
  type ChargeableAddOns,
  type DeliveryInfo,
  type IOrder,
  type InStoreServices,
  type LocationCode,
  type OrderItem,
  type ServiceLevel,
  type OrderSource,
} from '@data/api/endpoints/v3/my_toshi/orders/[id]';

import { Base } from './Base';
import { type Contact } from './Contact';

type OrderWithNonNullable<T extends keyof Order> = Order & {
  [key in T]: NonNullable<Order[T]>;
};

export class Order extends Base<IOrder> implements IOrder {
  billableDeliveryCount!: number;
  billableReturnCount!: number;
  brandLogoUrl?: string;
  brandName!: string;
  cancelled!: boolean;
  chargeableAddOns!: ChargeableAddOns[];
  coBrandingEnabled!: boolean;
  customer!: Contact;
  delayedAtBrand!: boolean;
  deliveryInfo?: DeliveryInfo;
  dhl!: boolean;
  giftMessage?: string;
  giftReceiver?: Contact;
  giftSender?: Contact;
  id!: string;
  journeys!: Journey[];
  locationCode!: LocationCode;
  multiPartyBooking!: boolean;
  onBehalfOfBrandName?: string;
  orderInterface!: string;
  orderItems!: OrderItem[];
  orderNumber!: string;
  rescheduleEnabled!: boolean;
  serviceLevel!: ServiceLevel;
  services!: AllToshiServices;
  shippingFrom?: string;
  source!: OrderSource;
  storeId!: number;
  storeServices!: InStoreServices;
  unscheduledOrderAddress?: Address;
  maxDeliveryAttemptCount?: number;

  isScheduled(this: Order): this is OrderWithNonNullable<'deliveryInfo'> {
    return Boolean(this.deliveryInfo);
  }

  isGifted(this: Order): this is OrderWithNonNullable<'giftReceiver' | 'giftSender'> {
    return Boolean(this.giftReceiver);
  }

  hasDeliveryJourneys(this: Order): this is OrderWithNonNullable<'mostRecentDeliveryJourney' | 'lastTransition'> {
    return Boolean(this.deliveryJourneys.length > 0);
  }

  hasReturnJourneys(this: Order): this is OrderWithNonNullable<'mostRecentReturnJourney' | 'lastTransition'> {
    return Boolean(this.returnJourneys.length > 0);
  }

  get hasServices(): boolean {
    return Object.entries(this.services).some(([key, value]) => value.enabled && key !== 'dropoff');
  }

  get hasStoreServices(): boolean {
    return Object.values(this.storeServices).some((value) => value);
  }

  get isDelivered(): boolean {
    return Boolean(this.mostRecentDeliveryJourney && this.lastTransition?.toState === 'completed');
  }

  get isCollected(): boolean {
    return Boolean(
      this.mostRecentReturnJourney &&
        ['collected_items', 'en_route_to_store', 'arrived_at_store', 'completed'].includes(
          this.mostRecentReturnJourney.status,
        ),
    );
  }

  get canBeRescheduled(): boolean | undefined {
    if (!this.rescheduleEnabled) {
      return false;
    }

    if (this.isLastJourneyReturn) {
      if (this.hasReturnJourneys() && this.billableReturnCount >= (this.maxDeliveryAttemptCount || 2)) {
        return false;
      }

      if (this.lastTransition?.toState) {
        switch (this.lastTransition.toState) {
          case 'scheduled':
          case 'unable_to_pickup':
            return true;

          default:
            return false;
        }
      }
    }

    if (this.hasDeliveryJourneys() && this.billableDeliveryCount >= (this.maxDeliveryAttemptCount || 2)) {
      return false;
    }

    if (this.lastTransition?.toState) {
      switch (this.lastTransition.toState) {
        case 'scheduled':
        case 'arrived_at_toshi_depot':
        case 'en_route_to_pickup':
        case 'unable_to_dropoff':
          return true;

        default:
          return false;
      }
    }

    return false;
  }

  get allJourneys(): Journey[] {
    return this.journeys
      .sort((journeyA, journeyB) => journeyB.createdAt.getTime() - journeyA.createdAt.getTime())
      .filter(({ instantReturn }) => !instantReturn);
  }

  get deliveryJourneys(): Journey[] {
    return this.allJourneys.filter(({ type, status }) => type === 'DeliveryJourney' && status !== 'pending');
  }

  get returnJourneys(): Journey[] {
    return this.allJourneys.filter(({ type, status }) => type === 'ReturnJourney' && status !== 'pending');
  }

  get mostRecentDeliveryJourney(): Journey | undefined {
    return this.deliveryJourneys[0];
  }

  get mostRecentReturnJourney(): Journey | undefined {
    return this.returnJourneys[0];
  }

  get mostRecentJourney(): Journey | undefined {
    return this.allJourneys[0];
  }

  get isLastJourneyReturn(): boolean {
    return Boolean(this.mostRecentJourney?.type === 'ReturnJourney');
  }

  static getLastTransition = (journey?: Journey) => journey?.journeyTransitions[journey.journeyTransitions.length - 1];

  get lastTransition(): JourneyTransition | undefined {
    if (this.isLastJourneyReturn) {
      return Order.getLastTransition(this.mostRecentReturnJourney);
    }
    return Order.getLastTransition(this.mostRecentDeliveryJourney);
  }

  get failedDelivery(): boolean {
    if (this.isLastJourneyReturn) {
      return (
        this.billableReturnCount >= (this.maxDeliveryAttemptCount || 2) &&
        this.lastTransition?.toState === 'unable_to_pickup'
      );
    }
    return (
      this.billableDeliveryCount >= (this.maxDeliveryAttemptCount || 2) &&
      this.lastTransition?.toState === 'unable_to_dropoff'
    );
  }

  get unableToComplete(): boolean {
    if (this.isLastJourneyReturn) {
      return this.lastTransition?.toState === 'unable_to_pickup';
    }

    return this.lastTransition?.toState === 'unable_to_dropoff';
  }

  // Delivery - Unable to pickup items from store or warehouse
  get unableToPickup(): boolean {
    return Boolean(this.mostRecentDeliveryJourney && this.mostRecentDeliveryJourney.status === 'unable_to_pickup');
  }

  get isEcommOrder(): boolean {
    return this.source === 'Online';
  }
}
