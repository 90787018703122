import { AnimatePresence } from 'framer-motion';
import { useOutlet } from 'react-router';

import { AuthProvider } from '@contexts/auth/AuthContext';
import { ServicesProvider } from '@contexts/ServicesContext';
import { useSessionRewind } from '@hooks/useSessionRewind';

import { Layout } from './layout/Layout';

export const Root = (): JSX.Element => {
  document.title = 'My TOSHI';

  const element = useOutlet();

  useSessionRewind();

  return (
    <AuthProvider>
      <ServicesProvider>
        <Layout>
          <AnimatePresence mode="wait" initial={false}>
            {element}
          </AnimatePresence>
        </Layout>
      </ServicesProvider>
    </AuthProvider>
  );
};
