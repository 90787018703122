import { type FC } from 'react';
import styled from 'styled-components';

import toshiLogo from '@assets/icons/toshi-logo.svg';
import xIcon from '@assets/icons/x-icon.svg';
import { queriesForTheme } from '@config/styling/breakPoints';
import { Row } from '@components/ui/Containers';

const CenteredRow = styled(Row)`
  align-items: center;
  justify-content: center;
  width: 100%;

  ${queriesForTheme.isMobileOrTablet} {
    display: none;
  }
`;

const StyledLogo = styled.img`
  width: 8.5rem;

  ${queriesForTheme.isDesktop} {
    width: 16rem;
  }
`;

const ToshiLogo = styled(StyledLogo).attrs({
  src: toshiLogo,
  alt: 'Toshi Logo',
})`
  fill: var(--colour-black);
`;

const BrandLogo = styled(StyledLogo)`
  ${(props) => (props.src ? '' : 'opacity: 0.5;')};
`;

const XContainer = styled.img`
  width: 1rem;
  height: 1rem;

  ${queriesForTheme.isDesktop} {
    width: 2.25rem;
    height: 2.25rem;
  }
`;

interface ToshiBrandProps {
  brandName: string;
  brandLogoUrl?: string;
  coBrandingEnabled?: boolean;
}

export const ToshiBrand: FC<ToshiBrandProps> = ({ brandName, brandLogoUrl, coBrandingEnabled }) => {
  const brandLogo = <BrandLogo alt={`Brand Logo for ${brandName}`} src={brandLogoUrl} />;

  return (
    <CenteredRow $gap={2.5}>
      {coBrandingEnabled ? (
        brandLogo
      ) : (
        <>
          <ToshiLogo />
          {brandLogoUrl && (
            <>
              <XContainer src={xIcon} alt={'X icon'} />
              {brandLogo}
            </>
          )}
        </>
      )}
    </CenteredRow>
  );
};
