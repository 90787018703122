import { type FC } from 'react';

import { Column } from '@components/ui/Containers';
import { StyledParagraphLarge, StyledParagraphLargeGrey } from '@components/ui/Typography';
import { DateFormat, formatDate, transformDate } from '@utils/helpers/dates';
import { type Journey } from '@data/api/endpoints/shared/journeyDTO';

import { type TranslationProp } from './DeliveryDetails';

interface DeliveredCollectedInfoProps extends TranslationProp {
  lastJourney: Journey;
  mode: 'delivered' | 'collected';
}

export const DeliveredCollectedInfo: FC<DeliveredCollectedInfoProps> = ({ lastJourney, translations, mode }) => {
  const completedTransition = lastJourney.journeyTransitions.filter(
    (transition) => transition.toState === (mode === 'delivered' ? 'arrived_at_dropoff' : 'finished_pickup'),
  )?.[0];
  const localisedEndDatetime = transformDate(completedTransition.createdAt);
  const dateTime = formatDate(localisedEndDatetime, DateFormat.dateMonthHourMinuteAmPm);

  return (
    <Column as="header" $noPadding $gap={0.5}>
      <StyledParagraphLarge $bold $noMargin>
        {translations[mode]}
      </StyledParagraphLarge>
      <StyledParagraphLargeGrey $bold $noMargin>
        {dateTime}
      </StyledParagraphLargeGrey>
    </Column>
  );
};
