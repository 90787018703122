{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "title": "Need help with your delivery or return?",
    "getInTouch": "Please get in touch with our customer service team:",
    "mobileCallText": "CALL TOSHI"
  }
}
