import { useIsPresent, motion } from 'framer-motion';
import { type FC, type PropsWithChildren } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { isDesktopMq } from '../config/styling/breakPoints';

export const Page: FC<PropsWithChildren> = ({ children }) => {
  const isPresent = useIsPresent();

  const dimensions = document.querySelector<HTMLDivElement>('div#main')?.getBoundingClientRect();
  const desktop = useMediaQuery(isDesktopMq);

  return (
    <>
      {children}
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: 'circOut' } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: 'circIn' } }}
        style={{
          originX: isPresent ? 0 : 1,
          height: desktop ? dimensions?.height + 'px' : '100%',
          top: dimensions?.top + 'px',
        }}
        className="privacy-screen"
      />
    </>
  );
};
