import { type FC } from 'react';
import styled from 'styled-components';

import { useTranslations } from '@locale/useTranslations';

import toshiLogo from '@assets/icons/toshi-logo-black.svg';
import { queriesForTheme } from '@config/styling/breakPoints';
import { translations } from './translations';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  padding: 0.25rem 1.5rem;
  background-color: ${(props) => props.theme.color};

  * {
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.whiteColor};
    font-size: 0.5rem;
  }

  ${queriesForTheme.isDesktop} {
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.figure`
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.color};
  gap: 0.25rem;
`;

export const Header: FC = () => {
  const {
    translationsContent: { header },
  } = useTranslations(translations);

  return (
    <HeaderContainer>
      <LogoContainer>
        <img src={toshiLogo} alt={'Toshi Logo'} />
        <figcaption>{header.brand}</figcaption>
      </LogoContainer>
    </HeaderContainer>
  );
};
