import currency from 'currency.js';
import { LocationCode } from '@data/api/endpoints/v3/my_toshi/orders/[id]';

const GBP = '£';
const USD = '$';

function currencySymbolFromLocationCode(code: LocationCode): string {
  switch (code) {
    case LocationCode.LDN:
      return GBP;
    case LocationCode.NYC:
    case LocationCode.LA:
      return USD;
  }
}

export const currencyFromCents = (
  cents: number,
  locationCode: LocationCode = LocationCode.LDN,
  showCents: boolean = false,
): string =>
  currency(cents, { fromCents: true, symbol: currencySymbolFromLocationCode(locationCode) }).format(
    (currency, options) => {
      const str = currency?.format(options);

      return (showCents ? str : str?.substring(0, str.length - 3)) ?? '';
    },
  );
