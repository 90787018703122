import { type FC, type PropsWithChildren } from 'react';
import { type ToastService } from '@services/ToastService.interface';
import { ToastServiceImpl } from '@services/ToastService';

import { defineContext } from './defineContext';

interface IServicesContext {
  toastService: ToastService;
}

const defaultValue: IServicesContext = {
  toastService: ToastServiceImpl,
};

export const [ServicesContext, useServices] = defineContext<IServicesContext>('Services');

export const ServicesProvider: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <ServicesContext.Provider value={defaultValue}>{children}</ServicesContext.Provider>
);
