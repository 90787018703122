import axios, { type AxiosError, type AxiosResponse } from 'axios';
import { type z, type ZodTypeAny } from 'zod';

import { getJWT, HTTPMethods, updateHeaders } from './helpers';
import { getBaseUrl } from './url';
import { validateSchema, type ValidateConfig } from './validator';

export const RemoteDataSource = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    common: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  },
  withCredentials: true,
});

const bareFetcher = <T>(url: string, options: Partial<RequestInit>) => {
  const jwt = getJWT();

  return RemoteDataSource.request<T>({
    url,
    method: options.method,
    data: options.body,
    signal: options.signal as AbortSignal | undefined,
    headers: jwt
      ? {
          Authorization: `Bearer ${jwt}`,
        }
      : undefined,
  });
};

const handleResponse = async <ResSchema extends ZodTypeAny>(
  { data: dto }: AxiosResponse,
  options: FetcherOptions<ResSchema>,
) => validateSchema(dto, options.resSchemaConfig);

type FetcherOptions<ResSchema extends ZodTypeAny> = {
  resSchemaConfig: ValidateConfig<ResSchema>;
  signal?: AbortSignal;
};

export async function fetcher<ResSchema extends ZodTypeAny>(
  url: string,
  options: FetcherOptions<ResSchema>,
): Promise<z.output<ResSchema>> {
  const baseRequestInit: RequestInit = {
    method: HTTPMethods.GET,
    signal: options.signal,
    credentials: 'include',
    mode: 'cors',
  };

  const response = await bareFetcher(url, updateHeaders(baseRequestInit));

  return await handleResponse(response, options);
}

type MutatorOptions<ResSchema extends ZodTypeAny, ReqSchema extends ZodTypeAny> = FetcherOptions<ResSchema> & {
  method: HTTPMethods;
  reqSchemaConfig: ValidateConfig<ReqSchema>;
  reqBody: z.input<ReqSchema>;
};

export async function mutator<ResSchema extends ZodTypeAny, ReqSchema extends ZodTypeAny>(
  url: string,
  options: MutatorOptions<ResSchema, ReqSchema>,
): Promise<z.output<ResSchema>> {
  const baseRequestInit: RequestInit = {
    method: options.method,
    signal: options.signal,
    credentials: 'include',
    mode: 'cors',
  };

  const validatedReqBody = validateSchema(options.reqBody, options.reqSchemaConfig);

  const response = await bareFetcher(url, updateHeaders({ ...baseRequestInit, body: validatedReqBody }));

  return await handleResponse(response, options);
}

export type APIV3Error = { error: string };
export type FetcherError = AxiosError<APIV3Error>;
