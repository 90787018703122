import styled from 'styled-components';
import pencil from '@assets/icons/pencil.svg';
import { type ButtonHTMLAttributes, forwardRef, useState } from 'react';
import { translations } from '../translations';
import { useTranslations } from '@locale/useTranslations';

const Pencil = styled.img.attrs({ src: pencil, alt: 'pencil logo' })`
  width: 1rem;
  height: 1rem;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  padding-block-end: 0.25rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  gap: 0.25rem;

  &:hover {
    padding-block-end: calc(0.25rem - 1px);
    border-bottom: 1px solid var(--colour-black);
  }
`;

export const EditButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>((props, ref) => {
  const [clicked, setClicked] = useState(false);
  const { translationsContent } = useTranslations(translations);

  return (
    <Button
      ref={ref}
      {...props}
      onClick={(e) => {
        setClicked((prev) => !prev);
        props.onClick?.(e);
      }}
    >
      {clicked ? (
        translationsContent.cancel
      ) : (
        <>
          <Pencil />
          {translationsContent.edit}
        </>
      )}
    </Button>
  );
});

EditButton.displayName = 'EditButton';
