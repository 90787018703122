import styled from 'styled-components';
import { useTranslations } from '@locale/useTranslations';
import { OrderHelp } from '@components/features/orderHelp/OrderHelp';
import { translations } from '@components/ui/translations';

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
  gap: 30px;
`;

export const OrderInfoErrorPage = (): JSX.Element => {
  const { translationsContent } = useTranslations(translations);
  return (
    <Wrapper>
      <div>{translationsContent.errorLoadingOrder}</div>
      <OrderHelp centered />
    </Wrapper>
  );
};
