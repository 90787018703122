{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "errorLoadingOrder": "There was an error loading your order details - please close this browser and click the link from your email again. If that does not work, please contact us.",
    "orderItem": "Order item",
    "edit": "Edit",
    "cancel": "Cancel"
  }
}
