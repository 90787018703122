import { z } from 'zod';

import { zodNonEmptyString } from '@utils/helpers/validation';
import { transformParams } from '@data/transformers/responses';

export const addressEligibleEndpoint = (orderId: string) =>
  `v2/gifts/orders/${orderId}/postcode_verifications` as const;

export const addressEligibleParamsSchema = z
  .object({
    orderId: zodNonEmptyString(),
    postcode: zodNonEmptyString(),
    storeId: z.number(),
  })
  .transform(transformParams);

export type AddressEligibleParams = z.input<typeof addressEligibleParamsSchema>;
export const addressEligibleDTOSchema = z.object({
  eligible: z.boolean(),
});
export type AddressEligibleDTO = z.infer<typeof addressEligibleDTOSchema>;
