import { zodResolver } from '@hookform/resolvers/zod';
import { type FC } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useEffectOnce } from 'usehooks-ts';
import { z } from 'zod';

import { Page } from '@pages/Page';
import { useAuth } from '@contexts/auth/AuthContext';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { Column, FullPageCenteredColumn } from '@components/ui/Containers';
import { MainCTA } from '@components/ui/buttons/buttons';
import { StyledInput } from '@components/ui/formComponents/FormComponents';
import { StyledParagraph } from '@components/ui/Typography';
import { queriesForTheme } from '@config/styling/breakPoints';
import { useMyToshiDocumentTitle } from '@hooks/useMyToshiDocumentTitle';
import { useTranslations } from '@locale/useTranslations';
import {
  generateReauthEmailEndpoint,
  reauthDTOSchema,
  reauthParamsSchema,
} from '@data/api/endpoints/v2/mytoshi/reauth';
import { useMutation } from '@data/hooks/useMutation';

import { translations } from './translations';

const CustomColumn = styled(Column)`
  align-items: center;

  ${MainCTA}, ${StyledInput} {
    width: 100%;
  }

  ${queriesForTheme.isTabletOrDesktop} {
    max-width: 50%;

    ${MainCTA}, ${StyledInput} {
      width: 20rem;
    }
  }
`;

const formSchema = z.object({
  email: z.string().email(),
});

type FormFieldValues = z.infer<typeof formSchema>;

export const Unauthorized: FC = () => {
  const { translationsContent } = useTranslations(translations);

  useMyToshiDocumentTitle(translationsContent.pageTitleUnauthorised);

  const { user, logOut } = useAuth();

  useEffectOnce(() => {
    if (user) {
      logOut;
    }
  });

  const { register, handleSubmit, formState } = useForm<FormFieldValues>({
    resolver: zodResolver(formSchema),
  });

  // For now, we are assuming that the only endpoint mytoshi has is the gifting one.
  // Once we have a dashboard, we will be redirecting to that and so won't need this
  // location stuff
  const location = useLocation();

  const { data, trigger, isMutating } = useMutation({
    url: generateReauthEmailEndpoint,
    reqSchemaConfig: {
      schema: reauthParamsSchema,
      schemaName: 'ReauthParams',
    },
    resSchemaConfig: {
      schema: reauthDTOSchema,
      schemaName: 'ReauthResponse',
    },
  });

  const reauthorise = async ({ email }: FormFieldValues) => {
    const path: string = location?.state?.path || location.pathname;

    const match = path.match(/\/(orders|gifting)\/(\w+)(\/\w+)*$/);
    const orderId = location?.state?.orderId || match?.[2];

    await trigger({
      orderId,
      email,
    });
  };

  if (isMutating) return <LoadingSpinner />;

  return (
    <Page>
      <FullPageCenteredColumn>
        <CustomColumn $gap={2} as="form" onSubmit={handleSubmit(reauthorise)}>
          {data ? (
            <StyledParagraph $noMargin $size={4}>
              {translationsContent.sentEmailTo}
            </StyledParagraph>
          ) : (
            <>
              <StyledParagraph $noMargin $size={4}>
                {translationsContent.sessionEnded}
              </StyledParagraph>
              <StyledInput {...register('email')} type="email" autoComplete="email" data-testid="email" />
              <MainCTA type="submit" disabled={formState.isValidating || !formState.isValid}>
                {translationsContent.sendEmail}
              </MainCTA>
            </>
          )}
        </CustomColumn>
      </FullPageCenteredColumn>
    </Page>
  );
};
