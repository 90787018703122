import { type FC } from 'react';

import { FadedParagraphLarge } from '@components/ui/Typography';
import { type Order } from '@data/models/Order';
import { type Journey } from '@data/api/endpoints/shared/journeyDTO';

import { type translationsType } from '../translations';
import { DeliveredCollectedInfo } from './DeliveredCollectedInfo';
import { InProgressInfo } from './InProgressInfo';
import { UnableToComplete } from './UnableToComplete';

export interface TranslationProp {
  translations: translationsType;
}
const NotBookedInfo: FC<TranslationProp> = ({ translations }) => (
  <FadedParagraphLarge $bold $noMargin>
    {translations.notYetBooked}
  </FadedParagraphLarge>
);

const Cancelled: FC<TranslationProp> = ({ translations }) => (
  <FadedParagraphLarge $bold $noMargin>
    {translations.cancelled}
  </FadedParagraphLarge>
);

interface DeliveryDetailsProps extends TranslationProp {
  order: Order;
  mostRecentJourney?: Journey;
}

export const DeliveryDetails: FC<DeliveryDetailsProps> = ({ order, translations, mostRecentJourney }) => {
  if (!order) {
    return null;
  }

  if (order.cancelled || order.failedDelivery || mostRecentJourney?.status === 'cancelled') {
    return <Cancelled translations={translations} />;
  }

  if (mostRecentJourney && ['unable_to_dropoff', 'unable_to_pickup'].includes(mostRecentJourney.status)) {
    return <UnableToComplete mostRecentJourney={mostRecentJourney} translations={translations} />;
  }

  if (!mostRecentJourney || !order.deliveryInfo) {
    return <NotBookedInfo translations={translations} />;
  }

  if (order.isLastJourneyReturn && order.isCollected) {
    return <DeliveredCollectedInfo mode="collected" lastJourney={mostRecentJourney} translations={translations} />;
  }

  // TODO: also add a conditional for in 'scheduled' state before this - ie either no journeys, or all delivery journeys cancelled/pending
  if (!mostRecentJourney.inTerminalState && mostRecentJourney.status !== 'pending') {
    return <InProgressInfo deliveryInfo={order.deliveryInfo} translations={translations} />;
  }

  return <DeliveredCollectedInfo mode="delivered" lastJourney={mostRecentJourney} translations={translations} />;
};
