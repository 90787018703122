import { z } from 'zod';

import { addressParamSchema } from '@data/api/endpoints/shared/addressParam';
import { deliveryDateSlotParamSchema } from '@data/api/endpoints/shared/deliveryDateDeliverySlotParam';
import { transformParams } from '@data/transformers/responses';
import { v3MyToshiBaseUrl } from '../../baseUrl';

export const bookDeliveryEndpoint = (orderId: string) =>
  `${v3MyToshiBaseUrl}/orders/${orderId}/delivery_journeys` as const;

export const bookDeliveryParamsBaseSchema = z.object({
  deliveryJourney: deliveryDateSlotParamSchema,
  services: z.object({
    dropoff: z.object({
      enabled: z.boolean(),
    }),
    waitAndTry: z.object({
      enabled: z.boolean(),
    }),
    inspireMe: z.object({
      enabled: z.boolean(),
      text: z.string().optional(),
    }),
  }),
  toshiUpdates: z.boolean().optional(),
});

const bookDeliveryParamsGiftingSchema = bookDeliveryParamsBaseSchema.extend({
  address: addressParamSchema,
  gifted: z.boolean(),
});

export const bookDeliveryParamsSchema = z
  .union([bookDeliveryParamsGiftingSchema, bookDeliveryParamsBaseSchema])
  .transform(transformParams);
