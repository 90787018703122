import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import Modal from 'react-modal';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { router } from './router';
import { getEnvironment } from './utils/helpers/getEnvironment';

if (getEnvironment() !== 'development' && process.env.NODE_ENV !== 'test') {
  // API key goes here as it would be visible even if we made it a secret due to us
  // reporting front end errors to Bugsnag anyway
  Bugsnag.start({
    apiKey: 'a14e994b3bb253c787338f3d4f0e7047',
    plugins: [new BugsnagPluginReact()],
    collectUserIp: false,
    releaseStage: getEnvironment(),
  });
}

const domNode = document.querySelector<HTMLDivElement>('#root');

if (domNode) {
  const root = createRoot(domNode);

  root.render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );

  Modal.setAppElement('#root');
}
