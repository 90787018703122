import { z } from 'zod';

import { zodNonEmptyString } from '@utils/helpers/validation';
import { transformDTO, transformParams } from '@data/transformers/responses';

export const addressSearchEndpoint = 'v2/gifts/address_searches';

export const addressSearchParamsSchema = z
  .object({
    query: zodNonEmptyString(),
    storeId: z.number(),
  })
  .transform(transformParams);

export type AddressSearchParams = z.input<typeof addressSearchParamsSchema>;

export const addressSearchDTOSchema = z
  .object({
    address_components: z.object({
      address_line_1: z.string(),
      address_line_2: z.string().nullable(),
      country: z.string(),
      postcode: z.string(),
      province: z.string(),
      town: z.string().nullable(),
    }),
  })
  .transform(transformDTO);

export type AddressAutocomplete = z.output<typeof addressSearchDTOSchema>;
export const addressAutocompleteCollectionDTOSchema = z.object({
  predictions: z.array(addressSearchDTOSchema),
});
export type AddressAutocompleteCollectionDTO = z.input<typeof addressAutocompleteCollectionDTOSchema>;
export type AddressAutocompleteCollection = z.output<typeof addressAutocompleteCollectionDTOSchema>;
