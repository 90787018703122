import styled from 'styled-components';

import { StyledParagraph } from '@components/ui/Typography';
import { useTranslations } from '@locale/useTranslations';
import { ToshiURLs } from '@utils/constants/toshiURLs';
import { translations } from './translations';

const StyledUl = styled.ul`
  all: unset;
  list-style-position: inside;
`;

export const ToshiInfoList = () => {
  const {
    translationsContent: { checkoutInfo },
  } = useTranslations(translations);

  const infoList = [
    { content: checkoutInfo.bullet1, link: undefined },
    { content: checkoutInfo.bullet2, link: undefined },
    { content: checkoutInfo.bullet3, link: undefined },
    { content: checkoutInfo.bullet4, link: ToshiURLs.faq },
    { content: checkoutInfo.bullet5, link: ToshiURLs.terms },
  ];

  return (
    <StyledUl>
      {infoList.map(({ content, link }) => {
        const linkProps = link
          ? {
              target: '_blank',
              href: link,
              rel: 'noreferrer',
              as: 'a',
            }
          : {};

        return (
          <li key={content}>
            <StyledParagraph $size={4} $noMargin {...linkProps} style={{ display: 'inline' }}>
              {content}
            </StyledParagraph>
          </li>
        );
      })}
    </StyledUl>
  );
};
