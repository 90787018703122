import { type CamelCasedPropertiesDeep } from 'type-fest';

import { type IOption } from '@components/ui/formComponents/FormComponents';
import { DateFormat, formatDate, isTodayOrTomorrow, parseDate } from '@utils/helpers/dates';
import { type AvailabilityDTO, type DeliverySlot } from '@data/api/endpoints/v2/availability/check_available';

export interface IAvailability extends Omit<CamelCasedPropertiesDeep<AvailabilityDTO>, 'dates'> {
  datesSlots: { [date: string]: DeliverySlot[] };
}

export class Availability {
  available!: boolean;
  orderHoldMinutes!: number;
  datesSlots!: { [date: string]: DeliverySlot[] };

  constructor(availability: IAvailability) {
    Object.assign(this, availability);
  }

  get dates(): Date[] {
    return Object.keys(this.datesSlots).map((date) => parseDate(date, DateFormat.yearMonthDate));
  }

  static get defaultTodayTomorrowParams() {
    return {
      todayText: 'Today',
      tomorrowText: 'Tomorrow',
    };
  }

  selectDates({ todayText, tomorrowText } = Availability.defaultTodayTomorrowParams): IOption[] {
    return this.dates.map((date) => {
      const todayTomorrow = isTodayOrTomorrow(date);
      const todayOrTomorrowText = todayTomorrow
        ? { today: ` (${todayText})`, tomorrow: ` (${tomorrowText})` }[todayTomorrow]
        : '';

      return {
        date,
        content: formatDate(date, DateFormat.dayDateMonthYear) + todayOrTomorrowText,
        value: formatDate(date, DateFormat.yearMonthDate),
      };
    });
  }

  slotsPresentForDate(date: string): boolean {
    return Boolean((this.datesSlots[date]?.length ?? 0) > 0);
  }

  slotsForDate(date: string): DeliverySlot[] {
    return this.datesSlots[date] ?? [];
  }

  static formatSlotTime = (time: string) =>
    formatDate(parseDate(time, DateFormat.longDateFormat), DateFormat.hourMinuteAmPm);

  static formatSlot = (slot: Pick<DeliverySlot, 'startTime' | 'endTime'>) =>
    `${Availability.formatSlotTime(slot.startTime)} - ${Availability.formatSlotTime(slot.endTime)}`;

  selectSlotsForDate(date: string): IOption[] {
    return this.slotsForDate(date).map((slot) => ({
      value: String(slot.deliverySlotId),
      content: Availability.formatSlot(slot),
    }));
  }
}
