{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "FOO": "foovaluehere",
    "BAR": "Page {0} of {1}"
  }
}
