import { type FC, type ReactElement } from 'react';
import { Column, Row } from '@components/ui/Containers';
import { StyledParagraph } from '@components/ui/Typography';

interface Props {
  leadingComponent: ReactElement;
  title: string | ReactElement;
  content?: ReactElement;
  rowGap?: number;
  columnGap?: number;
}

export const IndentedColumnWithLeadingComponent: FC<Props> = ({
  title,
  content,
  leadingComponent,
  rowGap = 0.5,
  columnGap = 1,
}) => (
  <Row $noPadding $gap={rowGap}>
    {leadingComponent}
    <Column $noPadding $gap={columnGap}>
      {typeof title === 'string' ? (
        <StyledParagraph $size="md" bold $noMargin>
          {title}
        </StyledParagraph>
      ) : (
        <>{title}</>
      )}
      {content}
    </Column>
  </Row>
);
