import { useCallback, useRef, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { timeAgo } from '@utils/helpers/dates';

type useLastUpdatedResult = [text: string, revalidate: () => void];

interface useLastUpdatedParams {
  interval: number;
}

export function useLastUpdated({ interval }: useLastUpdatedParams = { interval: 60_000 }): useLastUpdatedResult {
  const lastUpdated = useRef<Date>(new Date());

  const [text, setText] = useState<string>(() => timeAgo(lastUpdated.current));

  useInterval(() => {
    setText(timeAgo(lastUpdated.current));
  }, interval);

  const revalidate = useCallback(() => {
    lastUpdated.current = new Date();
    setText(timeAgo(lastUpdated.current));
  }, []);

  return [text, revalidate];
}
