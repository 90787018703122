import { type FC } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { Column } from '@components/ui/Containers';
import { FadedParagraphLarge, StyledParagraphLarge, StyledParagraphLargeGrey } from '@components/ui/Typography';
import { breakPointLG } from '@config/styling/breakPoints';
import { DateFormat, formatDate, isOtherDate, isTodayOrTomorrow, parseDate, transformDate } from '@utils/helpers/dates';
import { type DeliveryInfo } from '@data/api/endpoints/v3/my_toshi/orders/[id]';

import { type translationsType } from '../translations';

interface InProgressInfoProps {
  deliveryInfo: DeliveryInfo;
  translations: translationsType;
}

export const InProgressInfo: FC<InProgressInfoProps> = ({ deliveryInfo, translations }) => {
  const localisedDate = transformDate(deliveryInfo.bookingDate);
  const todayOrTomorrow = isTodayOrTomorrow(localisedDate);
  const todayOrTomorrowText =
    todayOrTomorrow &&
    {
      today: translations.bookedForToday,
      tomorrow: translations.bookedForTomorrow,
    }[todayOrTomorrow];
  const otherDate = isOtherDate(localisedDate);
  const otherDatetext = `Booked for ${otherDate}`;

  const desktop = useMediaQuery(`(min-width: ${breakPointLG}px)`);

  const bookedDate = formatDate(localisedDate, DateFormat.dateMonth);
  const bookedTime = `${formatDate(
    parseDate(deliveryInfo.bookingTimeslotStart, DateFormat.hourMinute),
    DateFormat.hourMinuteAmPm,
  )}-${formatDate(parseDate(deliveryInfo.bookingTimeslotEnd, DateFormat.hourMinute), DateFormat.hourMinuteAmPm)}`;
  {
    /* Hide ETA until we build Live ETA */
  }
  // const { arrivalEta } = deliveryInfo;

  if (desktop) {
    return (
      <Column as="header" $noPadding $gap={0.5}>
        <StyledParagraphLarge $bold $noMargin>
          {todayOrTomorrow ? todayOrTomorrowText : otherDatetext}
        </StyledParagraphLarge>
        <StyledParagraphLargeGrey $bold $noMargin>
          {bookedDate} | {bookedTime}
        </StyledParagraphLargeGrey>
        {/* Hide ETA until we build Live ETA */}
        {/* {arrivalEta && <FadedParagraphLarge bold>ETA {arrivalEta}</FadedParagraphLarge>} */}
      </Column>
    );
  }

  return (
    <Column as="header" $noPadding $gap={0.5}>
      <StyledParagraphLarge $bold $noMargin>
        {todayOrTomorrow ? todayOrTomorrowText : otherDatetext}
      </StyledParagraphLarge>
      <FadedParagraphLarge $bold $noMargin>
        {bookedDate}
      </FadedParagraphLarge>
      <FadedParagraphLarge $bold $noMargin>
        {bookedTime}
      </FadedParagraphLarge>
      {/* Hide ETA until we build Live ETA */}
      {/* {arrivalEta && <FadedParagraphLarge bold>ETA {arrivalEta}</FadedParagraphLarge>} */}
    </Column>
  );
};
