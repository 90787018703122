import { useCallback, type FC } from 'react';
import { Navigate, useNavigate } from 'react-router';

import { ToshiBrand } from '@components/brand/ToshiBrand';
import { OrderInfoCard } from '@components/features/OrderInfoCard/OrderInfoCard';
import { BookerForm, type BookerFormSubmitParams } from '@components/features/bookerForm/BookerForm';
import { ResponsiveGridWithHeader } from '@components/ui/Containers';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { StyledParagraph } from '@components/ui/Typography';
import {
  bookDeliveryEndpoint,
  bookDeliveryParamsSchema,
} from '@data/api/endpoints/v3/my_toshi/orders/[id]/delivery_journeys';
import {
  bookReturnEndpoint,
  bookReturnParamsSchema,
} from '@data/api/endpoints/v3/my_toshi/orders/[id]/return_journeys';
import { useBookerMutation } from '@data/hooks/useBookerMutation';
import { useGetOrder } from '@data/hooks/useGetOrder';
import { useBookerMode } from '@hooks/useBookerMode';
import { useMyToshiDocumentTitle } from '@hooks/useMyToshiDocumentTitle';
import { useOrderId } from '@hooks/useOrderId';
import { useTranslations } from '@locale/useTranslations';
import { Page } from '@pages/Page';
import { OrderInfoErrorPage } from '@pages/errors/OrderInfoErrorPage';

import { translations } from './translations';

export const BookOrder: FC = () => {
  const { mode, isDeliveryMode } = useBookerMode();

  const { translationsContent } = useTranslations(translations);

  useMyToshiDocumentTitle(isDeliveryMode ? translationsContent.pageTitleDelivery : translationsContent.pageTitleReturn);

  const { id } = useOrderId();

  const { data: order, error } = useGetOrder(id);

  const navigate = useNavigate();

  const { trigger: bookDelivery, error: bookDeliveryError } = useBookerMutation({
    url: bookDeliveryEndpoint(id),
    reqSchemaConfig: {
      schema: bookDeliveryParamsSchema,
      schemaName: 'BookDeliveryParams',
    },
  });

  const { trigger: bookReturn, error: bookReturnError } = useBookerMutation({
    url: bookReturnEndpoint(id),
    reqSchemaConfig: {
      schema: bookReturnParamsSchema,
      schemaName: 'BookReturnParams',
    },
  });

  const onSubmit = useCallback(
    async ({ deliveryDate, deliverySlotId, services: { waitAndTry, dropoff, inspireMe } }: BookerFormSubmitParams) => {
      const dateSlot = { deliveryDate, deliverySlotId };

      const bookResponse = isDeliveryMode
        ? await bookDelivery(
            {
              deliveryJourney: dateSlot,
              services: { waitAndTry, dropoff, inspireMe },
            },
            { throwOnError: true },
          )
        : await bookReturn({ returnJourney: dateSlot }, { throwOnError: true });

      if (!bookResponse) {
        throw bookResponse;
      } else {
        navigate(`/orders/${id}`);
      }
    },
    [bookDelivery, bookReturn, id, isDeliveryMode, navigate],
  );

  if (error && Object.keys(error).length > 0) {
    return <OrderInfoErrorPage />;
  }

  if (!order) {
    return <LoadingSpinner />;
  }

  if (isDeliveryMode && (order.cancelled || order.hasDeliveryJourneys())) {
    return <Navigate replace to={`/orders/${id}`} />;
  }

  if (order.isGifted()) {
    return <Navigate replace to={`/gifting/${id}`} />;
  }

  return (
    <Page>
      <ResponsiveGridWithHeader>
        <ToshiBrand
          coBrandingEnabled={order?.coBrandingEnabled}
          brandName={order?.brandName}
          brandLogoUrl={order?.brandLogoUrl}
        />
        <article>
          <StyledParagraph $size="xl" $noMargin $bold>
            {translationsContent.hello} {order.customer.fullName},
          </StyledParagraph>
          <BookerForm
            mode={mode}
            order={order}
            onSubmit={onSubmit}
            submitError={Boolean(bookDeliveryError || bookReturnError)}
          />
        </article>
        <OrderInfoCard order={order} />
      </ResponsiveGridWithHeader>
    </Page>
  );
};
