import Bugsnag from '@bugsnag/js';
import { memo, useCallback, useEffect, useMemo, type FC, type PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';

import { defineContext } from '@contexts/defineContext';
import { authDTOSchema, authEndpoint, authParamsSchema, type User } from '@data/api/endpoints/v2/mytoshi/auth';
import { useMutation } from '@data/hooks/useMutation';
import { SearchParams } from '@utils/helpers/searchParams';

interface IAuthContext {
  user: User | null;
  loggedIn: boolean;
  loggingIn: boolean;
  logOut: VoidFunction;
  logInError: boolean;
}

export const [AuthContext, useAuth] = defineContext<IAuthContext>('Auth');

export const JWT_KEY = 'my_toshi_user';

export const AuthProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [user, setUser] = useSessionStorage<User | null>(JWT_KEY, null);

  const [searchParams, setSearchParams] = useSearchParams();

  const code = searchParams.get(SearchParams.authToken);
  const id = searchParams.get(SearchParams.customerId);

  const navigate = useNavigate();

  const loggedIn = useMemo(() => !!user, [user]);

  const { isMutating, trigger, error } = useMutation({
    url: authEndpoint,
    reqSchemaConfig: {
      schema: authParamsSchema,
      schemaName: 'AuthParams',
    },
    resSchemaConfig: {
      schema: authDTOSchema,
      schemaName: 'AuthResponse',
    },
  });

  const logIn = useCallback(
    async (code: string, id: string) => {
      const data = await trigger({ code, id });

      if (data.user) {
        const user = data.user;

        setUser(user);

        if (Bugsnag.isStarted()) {
          Bugsnag.setUser(id, user.email, `${user.firstName} ${user.lastName}`);
        }
      }
    },
    [setUser, trigger],
  );

  const clearAuthParams = useCallback(() => {
    searchParams.delete(SearchParams.authToken);
    searchParams.delete(SearchParams.customerId);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const logOut = useCallback(() => {
    setUser(null);
    clearAuthParams();
  }, [clearAuthParams, setUser]);

  useEffect(() => {
    if (error) {
      logOut();
    } else if (!user && code && id) {
      logIn(code, id);
      clearAuthParams();
    }
  }, [clearAuthParams, code, error, id, logIn, logOut, user]);

  const location = useLocation();

  useEffect(() => {
    if (user && location.state?.redirectedFrom) {
      navigate(location.state.redirectedFrom);
    }
  }, [location.state, location.state?.redirectedFrom, navigate, user]);

  const contextValue = useMemo<IAuthContext>(
    () => ({
      loggedIn,
      user,
      loggingIn: isMutating,
      logOut,
      logInError: Boolean(error),
    }),
    [error, isMutating, logOut, loggedIn, user],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
});

AuthProvider.displayName = 'AuthProvider';
