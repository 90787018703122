import { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

export const useDebouncedEffect = (
  effect: React.EffectCallback,
  delay: number,
  deps: React.DependencyList = [],
): void => {
  const effectRef = useRef<React.EffectCallback>(effect);

  useIsomorphicLayoutEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  useEffect(() => {
    const handler = setTimeout(effectRef.current, delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay]);
};
