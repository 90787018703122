import { JWT_KEY } from '@contexts/auth/AuthContext';
import { safeParseJSON } from '@utils/helpers/strings';

import { type User } from './endpoints/v2/mytoshi/auth';

export const getJWT = (): string | undefined => {
  const user = sessionStorage.getItem(JWT_KEY);

  if (user) return safeParseJSON<User>(user)?.jwt;
};

export function updateHeaders(options: RequestInit = {}): RequestInit {
  const headers = new Headers({ ...options.headers, 'Content-type': 'application/json; charset=UTF-8' });

  const jwt = getJWT();

  if (jwt) {
    headers.append('Authorization', `Bearer ${jwt}`);
  }

  return { ...options, headers };
}

export enum HTTPMethods {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}
