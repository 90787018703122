export function isSnakeCase(input: string): boolean {
  // Snake case should only contain lowercase letters, numbers, and underscores
  const snakeCaseRegex = /^[a-z0-9_]+$/;

  return snakeCaseRegex.test(input);
}

export function safeParseJSON<T>(string: string): T | undefined {
  try {
    return JSON.parse(string);
  } catch {
    return undefined;
  }
}
