import { motion, type Variants } from 'framer-motion';
import { type FC } from 'react';
import styled from 'styled-components';
import { useUpdateEffect } from 'usehooks-ts';

import activeCircle from '@assets/icons/active-circle.svg';
import truck from '@assets/icons/truck.svg';
import { GridWrapper } from '@components/ui/Containers';
import { StyledParagraph, StyledParagraphMedium } from '@components/ui/Typography';
import { queriesForTheme } from '@config/styling/breakPoints';
import { DateFormat, formatDate, transformDate } from '@utils/helpers/dates';
import { useLastUpdated } from '@hooks/useLastUpdated';
import { useTranslations } from '@locale/useTranslations';
import { type Order } from '@data/models/Order';
import { type JourneyTransition, type JourneyTransitionStatus } from '@data/api/endpoints/shared/journeyDTO';

import { translations } from './translations/index';

const statusAchieved = (transitions: JourneyTransition[], status: JourneyTransitionStatus): boolean =>
  Boolean(transitions.find((transition) => transition.toState === status));

const statusCreatedAt = (transitions: JourneyTransition[], status: JourneyTransitionStatus): string => {
  const createdAt = transitions.find(({ toState }) => toState === status)?.createdAt;

  if (!createdAt) return '';

  const localisedTime = transformDate(createdAt);
  const timestamp = formatDate(localisedTime, DateFormat.hourMinuteAmPm);

  return timestamp;
};

const activeStatus = (lastTransition: JourneyTransition, status: JourneyTransitionStatus): boolean =>
  lastTransition.toState === status;

interface StatusWrapperProps {
  active?: boolean;
}

const StatusWrapper = styled(motion.div)<StatusWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  padding: 10px;
  border: ${(props) => (props.active ? `1px solid ${props.theme.blackColor}` : '')};
  border-radius: 1px;
  background-color: white;
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 40%);

  ${queriesForTheme.isDesktop} {
    margin-bottom: 28px;
    padding: 10px 20px;
  }

  &:last-child {
    margin-block-end: 0;
  }
`;

const CustomParagraph = styled(StyledParagraph).attrs({ $noMargin: true, $size: 4 })`
  color: var(--colur-main-grey);

  ${queriesForTheme.isDesktop} {
    font-size: var(--font-size-3);
  }
`;

const RefreshWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-left: 40px;
`;

const Refresh = styled.button`
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.fontColorFaded};
  font-size: ${(props) => props.theme.fontSizeSmall};

  &:first-child {
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const CircleHolder = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:nth-child(3) {
    ${(props) => (props.noPadding ? '' : 'padding-top: 20px;')}
  }

  &:nth-child(5) {
    margin-top: -20px;
  }

  &:nth-last-child(2) {
    ${(props) => (props.noPadding ? '' : 'padding-bottom: 16px;')}
    margin-top: -3px;
  }
`;
const ActiveCircle = styled.img.attrs({ src: activeCircle, alt: 'Truck icon' })`
  width: 20px;
  height: 20px;
  margin-left: 1px;
`;
const Circle = styled.div`
  width: 12px;
  height: 12px;
  margin-left: 1px;
  border: ${(props) => `1px solid ${props.theme.fontColorFaded}`};
  border-radius: 100%;
  background-color: var(--colour-background);
`;

const Line = styled(motion.div).attrs({
  initial: { height: 0 },
  transition: {
    delay: 1,
    duration: 0.5,
  },
})`
  width: 1px;
  background: ${(props) => props.theme.fontColorFaded};
`;

const OuterLine = styled(Line).attrs({
  animate: { height: 'calc(100% - 12px)' },
})``;

const InnerLine = styled(Line).attrs({
  animate: { height: 'calc((100% - 12px) / 2)' },
})``;

const UpdatesToAppearBox = styled.p`
  color: ${(props) => props.theme.fontColorFaded};
  font-size: ${(props) => props.theme.fontSizeInput};
`;
const Cancelled = styled(UpdatesToAppearBox)`
  color: ${(props) => props.theme.blackColor};
`;
const TruckIcon = styled.img.attrs({
  src: truck,
  alt: 'Truck Icon',
})`
  align-self: center;
  width: 24px;
  height: 32px;
  margin-top: 8px;
  margin-right: 12px;
  vertical-align: middle;
`;

interface CircleLineProps {
  transitions: JourneyTransition[];
  nextStatus: JourneyTransitionStatus;
}

const CircleLines: FC<CircleLineProps> = ({ transitions, nextStatus }) => {
  if (nextStatus === 'completed') return <CircleLinesComplete transitions={transitions} nextStatus={nextStatus} />;

  if (statusAchieved(transitions, nextStatus)) {
    return (
      <CircleHolder>
        <Circle />
        <OuterLine />
      </CircleHolder>
    );
  }

  return (
    <CircleHolder noPadding>
      <ActiveCircle />
    </CircleHolder>
  );
};

const CircleLinesComplete: FC<CircleLineProps> = ({ transitions }) => {
  if (statusAchieved(transitions, 'completed')) {
    return (
      <CircleHolder>
        <InnerLine />
        <Circle />
        <InnerLine />
      </CircleHolder>
    );
  }

  return (
    <CircleHolder>
      <OuterLine />
      <ActiveCircle />
    </CircleHolder>
  );
};

interface ToshiAssistantUpdatesProps {
  transitions?: JourneyTransition[];
  order: Order;
  refresh: () => void;
}

const itemVariants: Variants = {
  visible: { opacity: 1, x: 0, animationDelay: '0.2s' },
  hidden: { opacity: 0, x: -100 },
};

const containerVariants: Variants = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.3,
      duration: 0.5,
      type: 'spring',
    },
  },
};

export const ToshiAssistantUpdates: FC<ToshiAssistantUpdatesProps> = ({ transitions, order, refresh }) => {
  const hasJourney = transitions && order.lastTransition;
  const journeyNotStartedStates: JourneyTransitionStatus[] = order.isLastJourneyReturn
    ? ['scheduled']
    : ['scheduled', 'arrived_at_toshi_depot', 'en_route_to_pickup', 'arrived_at_pickup'];

  const assistantJourneyNotStarted =
    !order.lastTransition || journeyNotStartedStates.includes(order.lastTransition.toState);
  const journeyNotStartedYet = !hasJourney || (assistantJourneyNotStarted && order.lastTransition?.mostRecent);

  const showLastUpdatedStates: JourneyTransitionStatus[] = order.isLastJourneyReturn
    ? ['en_route_to_pickup', 'arrived_at_pickup']
    : ['finished_pickup', 'en_route_to_dropoff', 'arrived_at_dropoff'];

  const showLastUpdated = showLastUpdatedStates.includes(order.lastTransition?.toState ?? 'cancelled');

  const [text, revalidate] = useLastUpdated();

  useUpdateEffect(() => {
    revalidate();
  }, [order]);

  const { translationsContent, getTranslationWithValues } = useTranslations(translations);

  let cancellationMessage = '';
  if (order.delayedAtBrand) {
    cancellationMessage = getTranslationWithValues(translationsContent.delayedAtBrand, [order.brandName]);
  } else if (order.cancelled || order.lastTransition?.toState === 'cancelled') {
    cancellationMessage = getTranslationWithValues(translationsContent.orderHasBeenCancelled, [order.brandName]);
  } else if (order.failedDelivery) {
    cancellationMessage = order.isLastJourneyReturn
      ? getTranslationWithValues(translationsContent.cancelledAfterMaximumCollectionAttempt, [order.brandName])
      : getTranslationWithValues(translationsContent.cancelledAfterMaximumDeliveryAttempt, [order.brandName]);
  } else if (order.unableToComplete) {
    cancellationMessage = order.isLastJourneyReturn
      ? translationsContent.attemptedCollection
      : translationsContent.attemptedDelivery;
  } else if (order.unableToPickup) {
    cancellationMessage = getTranslationWithValues(translationsContent.attemptedPickup, [order.brandName]);
  }

  if (cancellationMessage) {
    return (
      <GridWrapper rowGap={order.cancelled ? 10 : undefined} collapseLeftCol>
        <TruckIcon />
        <StyledParagraphMedium bold>{translationsContent.deliveryUpdates}</StyledParagraphMedium>
        <br />
        <Cancelled>{cancellationMessage}</Cancelled>
      </GridWrapper>
    );
  }

  if (order.isLastJourneyReturn) {
    return (
      <>
        <GridWrapper collapseLeftCol variants={containerVariants} initial="hidden" animate="visible">
          <TruckIcon />
          <StyledParagraphMedium bold>{translationsContent.deliveryUpdates}</StyledParagraphMedium>
          {journeyNotStartedYet ? (
            <>
              <br />
              <UpdatesToAppearBox>{translationsContent.realtimeCollectionUpdatesWillAppear}</UpdatesToAppearBox>
            </>
          ) : (
            <>
              {statusAchieved(transitions, 'en_route_to_pickup') && (
                <>
                  <CircleLines transitions={transitions} nextStatus="finished_pickup" />
                  <StatusWrapper
                    variants={itemVariants}
                    active={activeStatus(order.lastTransition, 'en_route_to_pickup')}
                  >
                    <CustomParagraph>{translationsContent.onTheWay}</CustomParagraph>
                    <CustomParagraph>{statusCreatedAt(transitions, 'en_route_to_pickup')}</CustomParagraph>
                  </StatusWrapper>
                </>
              )}
              {statusAchieved(transitions, 'finished_pickup') && (
                <>
                  <CircleHolder>
                    <OuterLine />
                    <Circle />
                  </CircleHolder>
                  <StatusWrapper variants={itemVariants}>
                    <CustomParagraph>{translationsContent.collectionCompleted}</CustomParagraph>
                    <CustomParagraph>{statusCreatedAt(transitions, 'finished_pickup')}</CustomParagraph>
                  </StatusWrapper>
                </>
              )}
            </>
          )}
        </GridWrapper>
        {showLastUpdated && (
          <RefreshWrapper>
            <Refresh onClick={refresh}>Refresh updates</Refresh>
            <Refresh>{`Last updated ${text} ago`}</Refresh>
          </RefreshWrapper>
        )}
      </>
    );
  }

  return (
    <>
      <GridWrapper collapseLeftCol variants={containerVariants} initial="hidden" animate="visible">
        <TruckIcon />
        <StyledParagraphMedium bold>{translationsContent.deliveryUpdates}</StyledParagraphMedium>
        {journeyNotStartedYet ? (
          <>
            <div />
            <UpdatesToAppearBox>{translationsContent.realtimeUpdatesWillAppear}</UpdatesToAppearBox>
          </>
        ) : (
          <>
            {statusAchieved(transitions, 'finished_pickup') && (
              <>
                <CircleLines transitions={transitions} nextStatus="en_route_to_dropoff" />
                <StatusWrapper variants={itemVariants} active={activeStatus(order.lastTransition, 'finished_pickup')}>
                  <CustomParagraph>{translationsContent.itemsCollected}</CustomParagraph>
                  <CustomParagraph>{statusCreatedAt(transitions, 'finished_pickup')}</CustomParagraph>
                </StatusWrapper>
              </>
            )}
            {statusAchieved(transitions, 'en_route_to_dropoff') && (
              <>
                <CircleLines transitions={transitions} nextStatus="arrived_at_dropoff" />
                <StatusWrapper
                  variants={itemVariants}
                  active={activeStatus(order.lastTransition, 'en_route_to_dropoff')}
                >
                  <CustomParagraph>{translationsContent.onTheWay}</CustomParagraph>
                  <CustomParagraph>{statusCreatedAt(transitions, 'en_route_to_dropoff')}</CustomParagraph>
                </StatusWrapper>
              </>
            )}
            {statusAchieved(transitions, 'completed') && (
              <>
                <CircleHolder>
                  <OuterLine />
                  <Circle />
                </CircleHolder>
                <StatusWrapper variants={itemVariants}>
                  <CustomParagraph>{translationsContent.deliveryCompleted}</CustomParagraph>
                  <CustomParagraph>{statusCreatedAt(transitions, 'arrived_at_dropoff')}</CustomParagraph>
                </StatusWrapper>
              </>
            )}
          </>
        )}
      </GridWrapper>
      {showLastUpdated && (
        <RefreshWrapper variants={itemVariants}>
          <Refresh onClick={refresh}>Refresh updates</Refresh>
          <Refresh>{`Last updated ${text} ago`}</Refresh>
        </RefreshWrapper>
      )}
    </>
  );
};
