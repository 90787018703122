import { useEffect, useMemo } from 'react';

import {
  availabilityDTOSchema,
  getAvailabilityEndpoint,
  getAvailabilityParamsSchema,
  type GetAvailabilityParams,
} from '@data/api/endpoints/v2/availability/check_available';
import { type FetcherError } from '@data/api/fetchers';
import { useMutation } from '@data/hooks/useMutation';
import { type Availability } from '@data/models/Availability';

interface UseAvailabilityParams {
  availabilityParams: Partial<GetAvailabilityParams>;
  onError?: (err: FetcherError | Error) => unknown;
}

interface UseAvailabilityResult {
  availability?: Availability;
  availabilityDataPresent: boolean;
  loading: boolean;
  slotsPresentForSelectedDate: boolean;
}

const canFetch = (params: Partial<GetAvailabilityParams>): params is GetAvailabilityParams =>
  Boolean(params.postcode && params.addressLine1);

export const useAvailability = ({ availabilityParams, onError }: UseAvailabilityParams): UseAvailabilityResult => {
  const {
    data: availability,
    isMutating,
    trigger,
  } = useMutation({
    url: getAvailabilityEndpoint,
    reqSchemaConfig: {
      schema: getAvailabilityParamsSchema,
      schemaName: 'AvailabilityParams',
    },
    resSchemaConfig: {
      schema: availabilityDTOSchema,
      schemaName: 'AvailabilityResponse',
    },
    onError,
  });

  useEffect(() => {
    if (canFetch(availabilityParams)) {
      trigger(availabilityParams);
    }
  }, [availabilityParams, onError, trigger]);

  const availabilityDataPresent = useMemo(() => Boolean(availability?.dates?.length), [availability?.dates?.length]);

  const slotsPresentForSelectedDate = useMemo(
    () => Boolean(availability?.slotsPresentForDate(availabilityParams.selectedDate ?? '')),
    [availability, availabilityParams],
  );

  return {
    availability,
    availabilityDataPresent,
    loading: isMutating,
    slotsPresentForSelectedDate,
  };
};
