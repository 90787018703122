import { motion } from 'framer-motion';
import { type FC, type PropsWithChildren, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useResizeObserver } from 'usehooks-ts';

import { queriesForTheme } from '@config/styling/breakPoints';

export const GridWrapper = styled(motion.div)<{ rowGap?: number; collapseLeftCol?: boolean }>`
  display: grid;
  grid-template-columns: auto 10fr;
  row-gap: ${(props) => (props.rowGap ? props.rowGap : 3)}px;
`;

export const ResponsiveGrid = styled.section`
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: var(--colour-background);
  gap: 2rem 4rem;

  ${queriesForTheme.isDesktop} {
    grid-auto-flow: column;
    grid-template-columns: 1.2fr 1fr;
    padding-block: 3rem;
    padding-inline: 8rem;
    gap: 3rem 6rem;
  }
`;

export const ResponsiveGridWithHeader = styled(ResponsiveGrid)`
  ${queriesForTheme.isDesktop} {
    grid:
      [header-start] 'header header header' auto [header-end]
      [main-start] 'article article aside' 1fr [main-end]
      / 0.6fr 0.6fr 1fr;

    > :first-child {
      grid-area: header;
    }

    > :nth-child(2) {
      grid-area: article;
    }

    > :last-child {
      grid-area: aside;
    }
  }
`;

export const cardStyles = css`
  width: 100%;
  height: fit-content;
  padding: 2.5rem;
  background-color: var(--colour-white);
  box-shadow: 2px 2px 10px 0 rgb(0 0 0 / 40%);
`;

export const WhiteCard = styled.div`
  ${cardStyles}
`;

interface ContainerProps {
  $bordered?: boolean;
  $noPadding?: boolean;
  $gap?: number;
  $padding?: number;
  $borderColor?: string;
  $borderWidth?: string;
  $backgroundColor?: string;
}
type containerPropsFn = (props: ContainerProps) => string;
const border: containerPropsFn = (props) => (props.$bordered ? `border: 1px solid var(--colour-black);` : '');
const noPadding: containerPropsFn = (props) => (props.$noPadding ? `padding: 0;` : '');
const gap: containerPropsFn = (props) => (props.$gap !== undefined ? `gap: ${props.$gap}rem;` : '');
const padding: containerPropsFn = (props) => (props.$padding !== undefined ? `padding: ${props.$padding}rem;` : '');
const borderColor: containerPropsFn = (props) => (props.$borderColor ? `border-color: ${props.$borderColor};` : '');
const borderWidth: containerPropsFn = (props) => (props.$borderWidth ? `border-width: ${props.$borderWidth};` : '');
const backgroundColor: containerPropsFn = (props) =>
  props.$backgroundColor ? `background-color: ${props.$backgroundColor};` : '';
const containerProps: containerPropsFn = (props) =>
  [border, noPadding, gap, padding, borderColor, borderWidth, backgroundColor].map((fn) => fn(props)).join('');

const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  ${containerProps}
`;

export const Column = styled(Container)`
  flex-direction: column;
`;

export const Row = styled(Container)`
  flex-direction: row;
`;

export const FullPageCenteredColumn = styled(Column)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  background-color: var(--colour-background);
  text-align: center;
`;

export const Separator = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: ${(props) => props.theme.ghostGrey};
`;

export const ColoredContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.$bordered ? '4px' : '')};
  ${containerProps}
`;

export const AnimateChangeInHeight: FC<PropsWithChildren & { initialHeight?: string | number }> = ({
  children,
  initialHeight,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { height = 0 } = useResizeObserver({
    ref,
    box: 'border-box',
  });

  return (
    <motion.div
      initial={{ height: initialHeight }}
      style={{ overflow: 'hidden' }}
      animate={{
        height,
        transition: {
          height: {
            type: 'tween',
            ease: 'easeInOut',
            duration: 0.8,
          },
        },
      }}
    >
      <div ref={ref}>{children}</div>
    </motion.div>
  );
};
