import { type FC } from 'react';
import styled from 'styled-components';

import dhlLogo from '@assets/images/dhl_express_logo.png';
import { Column } from '@components/ui/Containers';
import { StyledParagraph } from '@components/ui/Typography';

import { type translationsType } from '../translations';

const DhlLogo = styled.img.attrs({ src: dhlLogo, alt: 'DHL logo' })`
  width: 9rem;
  height: 2.5rem;
`;

const DhlContainer = styled(Column)`
  --colour-dhl: #fc0;

  align-items: center;
  background: var(--colour-dhl);
  border-block-start: 0.25rem solid var(--colour-black);
  border-block-end: 0.25rem solid var(--colour-dhl);

  ${StyledParagraph} {
    line-height: initial;
  }
`;

export const DhlBanner: FC<{ translations: translationsType }> = ({ translations }) => (
  <DhlContainer $gap={0.5} $padding={0.5}>
    <StyledParagraph $size="sm" $noMargin $bold>
      {translations.inPartnershipWith}
    </StyledParagraph>
    <DhlLogo />
  </DhlContainer>
);
