export const getBaseUrl = (): URL['href'] => {
  const baseUri = window.location.host.includes('toshi.co') ? 'toshi.co' : 'toshitech.co';

  switch (window.location.host) {
    case `next.my.${baseUri}`:
      return `https://next.api.toshi.co/`;
    case `staging.my.${baseUri}`:
      return `https://staging.api.toshi.co/`;
    case `my.${baseUri}`:
      return `https://api.toshi.co/`;
    case 'my.toshi.test:8081':
      return 'https://api.toshi.test/';
    default:
      return 'https://api.toshi.test/';
  }
};
