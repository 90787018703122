import { type CamelCasedProperties } from 'type-fest';
import { z } from 'zod';

import { zodNonEmptyString } from '@utils/helpers/validation';
import { nullToUndefined } from '@data/transformers/nullToUndefined';
import { transformParams } from '@data/transformers/responses';
import { mapAvailabilityDTO } from '@data/transformers/responses/mapAvailabilityDTO';
import { ServiceLevel } from '../../v3/my_toshi/orders/[id]';

export const getAvailabilityEndpoint = 'v2/availability/check_available';

export enum SlotDurationFilter {
  oneHour = 'hourly',
  twoHour = 'two_hourly',
}
// double check which params are optional
export const getAvailabilityParamsSchema = z
  .object({
    addressLine1: zodNonEmptyString(),
    addressLine2: z.string().optional(),
    interface: z.string(),
    orderId: z.string().optional(),
    postcode: zodNonEmptyString(),
    returnTask: z.boolean().optional(),
    selectedDate: z.string().optional(),
    storeId: z.number(),
    town: z.string(),
    slotDurationFilter: z.nativeEnum(SlotDurationFilter).optional(),
    serviceLevel: z.nativeEnum(ServiceLevel),
  })
  .transform(transformParams);

export type GetAvailabilityParams = z.input<typeof getAvailabilityParamsSchema>;

export const deliverySlotDTOSchema = z.object({
  available: z.boolean(),
  delivery_date: z.string(),
  delivery_slot_id: z.number(),
  end_time: z.string(),
  start_time: z.string(),
});

export type DeliverySlotDTO = z.infer<typeof deliverySlotDTOSchema>;
export type DeliverySlot = CamelCasedProperties<DeliverySlotDTO>;

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

const dateKeySchema = z.string().refine((string) => dateRegex.test(string), {
  message: 'Invalid date format. Expected format: YYYY-MM-DD',
});

export const _availabilityDTOSchema = z.object({
  available: z.boolean(),
  dates: z.record(dateKeySchema, z.array(deliverySlotDTOSchema)),
  order_hold_minutes: z.number(),
});

export const availabilityDTOSchema = _availabilityDTOSchema.transform(nullToUndefined).transform(mapAvailabilityDTO);

export type AvailabilityDTO = z.infer<typeof _availabilityDTOSchema>;
