interface Constructable<T> {
  new (arg: T | Partial<T>): T;
}

export class Base<T> {
  constructor(baseData: T) {
    Object.assign(this, baseData);
  }

  copyWith(data: Partial<typeof this>): typeof this {
    return new (this.constructor as Constructable<typeof this>)({ ...this, ...data });
  }
}
