import styled, { css } from 'styled-components';
import { queriesForTheme } from '@config/styling/breakPoints';

const CTA = styled.button<{ $size?: 'sm' | 'md' | 'lg' }>`
  padding: 1rem;
  border-radius: 5px;
  font-size: var(--font-size-3);
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  ${({ $size }) => {
    switch ($size) {
      case 'sm': {
        return css`
          width: 30%;
        `;
      }
      case 'md': {
        return css`
          width: 50%;
        `;
      }
      case 'lg': {
        return css`
          width: 70%;
          font-size: var(--font-size-4);
        `;
      }

      default:
        break;
    }
  }}

  ${queriesForTheme.isMobileOrTablet} {
    width: 100%;
  }
`;

CTA.defaultProps = {
  $size: 'lg',
};

export const MainCTA = styled(CTA)`
  transition: background-color 0.3s ease-in-out;
  border: none;
  background-color: var(--colour-black);
  color: var(--colour-white);

  &:disabled {
    background-color: var(--colour-light-grey);
    color: var(--colour-dark-grey);
    cursor: auto;
  }
`;

export const SecondaryCTA = styled(CTA)`
  border: 1px solid var(--colour-black);
  background-color: var(--colour-white);
  font-weight: 600;

  &:hover {
    background-color: var(--colour-lighter-grey);
  }

  ${queriesForTheme.isDesktop} {
    width: fit-content;
  }
`;
