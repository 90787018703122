{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "hello": "Hello",
    "pageTitleDelivery": "Reschedule your delivery",
    "pageTitleReturn": "Reschedule your collection",
    "pleaseBookConvenientDate": "Please select a date for the delivery of your order.",
    "pleaseBookConvenientDateTime": "Please select a date and time for the delivery of your order.",
    "rescheduleDeliveryHeader": "Reschedule your delivery",
    "rescheduleReturnHeader": "Reschedule your collection",
    "toshiStandardExplanation": "TOSHI will email you a 1-hour delivery window on the morning of your delivery.",
    "unableToSchedule": "We were unable to reschedule your delivery - please try again or contact TOSHI at clientservice@toshi.co"
  }
}
