import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion, type MotionProps } from 'framer-motion';
import { useCallback, useEffect, useMemo, useReducer, useState, type FC } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';
import styled, { css } from 'styled-components';
import { type KeyedMutator } from 'swr';
import { z } from 'zod';

import { useAvailability } from '@components/features/bookerForm/useAvailability';
import { Column, Row } from '@components/ui/Containers';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { StyledParagraph, StyledParagraphGrey, StyledParagraphMedium } from '@components/ui/Typography';
import { MainCTA } from '@components/ui/buttons/buttons';
import { Checkbox, Options, StyledLabel, StyledSelect } from '@components/ui/formComponents/FormComponents';
import { SelectMode } from '@components/ui/formComponents/SelectMode.enum';
import { queriesForTheme } from '@config/styling/breakPoints';
import { useServices } from '@contexts/ServicesContext';
import { type Address } from '@data/api/endpoints/shared/addressDTO';
import {
  type GetAvailabilityParams,
  type SlotDurationFilter,
} from '@data/api/endpoints/v2/availability/check_available';
import { ServiceLevel, type AllToshiServices } from '@data/api/endpoints/v3/my_toshi/orders/[id]';
import { type Order } from '@data/models/Order';
import { BookerMode } from '@hooks/useBookerMode';
import { DateFormat, formatDate, parseDate } from '@utils/helpers/dates';
import { zodNonEmptyString } from '@utils/helpers/validation';

import { AddressFields } from './AddressFields';
import { Services } from './Services';
import { useBookerFormTranslations } from './useBookerFormTranslations';

const heightAnimation: Partial<MotionProps> = {
  initial: { height: 0, opacity: 0 },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
        delay: 0.25,
      },
    },
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.4,
      },
      opacity: {
        duration: 0.25,
      },
    },
  },
};

const StyledForm = styled(motion.form).attrs<{ $minimal?: boolean }>({ ...heightAnimation })`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  background-color: transparent;
  container-type: inline-size;
  ${({ $minimal }) =>
    $minimal
      ? css`
          ${StyledLabel} {
            p {
              display: none;
              height: 0;
            }
          }
        `
      : css`
          ${queriesForTheme.isDesktop} {
            max-width: 75%;
          }
        `}
`;

const FormItemContainer = styled.div`
  width: 100%;

  &:last-child {
    margin-block-end: 0;
  }
`;

/* jsdom does not currently support container queries */
const containerSmallQuery = (styles: TemplateStringsArray) =>
  process.env.NODE_ENV !== 'test'
    ? css`
        @container (width < 34rem) {
          ${styles}
        }
      `
    : '';

const selectCTADesktopWidth = 'calc(50% - 0.5rem)';

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1rem;

  ${queriesForTheme.isDesktop} {
    justify-content: space-between;

    > * {
      flex: 1;
      width: ${selectCTADesktopWidth};
    }
  }

  ${queriesForTheme.isMobileOrTablet} {
    gap: 2rem;
    flex-direction: column;

    > * {
      flex: 1;
      width: 100%;
    }
  }

  ${containerSmallQuery`
    gap: 2rem;
    flex-direction: column;
    > * {
      width: 100%;
      flex: 1;
    }
  `}
`;

const BookerFormCTA = styled(MainCTA)`
  ${queriesForTheme.isDesktop} {
    width: ${selectCTADesktopWidth};
  }

  ${containerSmallQuery`
    width: 100%;
  `}
`;

const ErrorContainer = styled(motion.div).attrs({ ...heightAnimation })`
  color: var(--colour-red);
`;

export type servicesReducerAction = {
  service: keyof AllToshiServices;
  enabled: boolean;
  text?: string;
};
const servicesReducer = (state: AllToshiServices, action: servicesReducerAction): AllToshiServices => {
  const newState = {
    ...state,
    [action.service]: {
      enabled: action.enabled,
    },
  };

  if (action.service === 'inspireMe') {
    newState.inspireMe.text = action.text;
  }
  return newState;
};

const formSchema = z.object({
  search: z.string().optional(),
  line1: zodNonEmptyString(),
  line2: z.string().optional().or(z.literal('')),
  town: z.string().optional(),
  city: zodNonEmptyString(),
  country: z.string().optional(),
  postcode: zodNonEmptyString(),
  deliveryDate: zodNonEmptyString(),
  deliverySlotId: zodNonEmptyString(),
  receiveUpdates: z.boolean(),
  services: z.object({
    dropoff: z.object({
      enabled: z.boolean(),
    }),
    waitAndTry: z.object({
      enabled: z.boolean(),
    }),
    upDownSize: z.object({
      enabled: z.boolean(),
    }),
    perfectFit: z.object({
      enabled: z.boolean(),
    }),
    alteration: z.object({
      enabled: z.boolean(),
    }),
    inspireMe: z.object({
      enabled: z.boolean(),
      text: z.string().optional(),
    }),
  }),
  gifted: z.boolean(),
});

export type BookerFormFieldValues = z.infer<typeof formSchema>;

export type BookerFormSubmitParams = Omit<BookerFormFieldValues, 'search'>;

interface BookerFormProps {
  mode: BookerMode;
  order: Order;
  onSubmit: (data: BookerFormSubmitParams) => Promise<void>;
  submitError: string | boolean;
  mutateOrder?: KeyedMutator<Order>;
  minimal?: boolean;
  toastCopy?: string;
  slotDurationFilter?: SlotDurationFilter;
}

export const BookerForm: FC<BookerFormProps> = ({
  mode,
  order,
  onSubmit,
  submitError,
  mutateOrder,
  minimal = false,
  toastCopy,
  slotDurationFilter,
}) => {
  const { translationsContent } = useBookerFormTranslations();

  const toshiStandardDelivery = order.serviceLevel === 'toshi_standard' && mode === BookerMode.delivery;

  const existingAddress = useMemo<Address | undefined>(() => {
    if (mode === BookerMode.delivery && order.hasDeliveryJourneys()) {
      return order.mostRecentDeliveryJourney.endAddress;
    }

    if (order.hasReturnJourneys()) {
      return order.mostRecentReturnJourney.startAddress;
    }

    return order.unscheduledOrderAddress;
  }, [mode, order]);

  const { register, handleSubmit, setError, clearErrors, control, formState, getValues, setValue } =
    useForm<BookerFormFieldValues>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        line1: existingAddress?.line1,
        line2: existingAddress?.line2,
        town: existingAddress?.cityTown,
        city: existingAddress?.cityTown,
        postcode: existingAddress?.postcodeZipcode,
        gifted: order.isGifted(),
      },
    });

  const { errors } = formState;

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const selectedDate = useWatch({
    control,
    name: 'deliveryDate',
  });

  const selectedTime = useWatch({
    control,
    name: 'deliverySlotId',
  });

  const postcode = useWatch({
    control,
    name: 'postcode',
  });

  const availabilityParams = useMemo<GetAvailabilityParams>(
    () => ({
      addressLine1: getValues('line1'),
      addressLine2: getValues('line2'),
      town: getValues('city'),
      postcode,
      city: getValues('city'),
      storeId: order.storeId,
      interface: order.orderInterface,
      orderId: order.id,
      returnTask: mode == BookerMode.return,
      selectedDate,
      serviceLevel: mode == BookerMode.return ? ServiceLevel.toshiPlus : order.serviceLevel,
      slotDurationFilter,
    }),
    [
      getValues,
      postcode,
      order.storeId,
      order.orderInterface,
      order.id,
      order.serviceLevel,
      mode,
      selectedDate,
      slotDurationFilter,
    ],
  );

  const handleAvailabilityError = useCallback(() => navigate('/gifting/error'), [navigate]);

  const { availability, availabilityDataPresent, loading, slotsPresentForSelectedDate } = useAvailability({
    availabilityParams,
    onError: handleAvailabilityError,
  });

  useEffect(() => {
    const dates = availability?.selectDates({
      todayText: translationsContent.today,
      tomorrowText: translationsContent.tomorrow,
    });

    if (dates && !selectedDate) {
      const firstDate = dates[0].value;
      setValue('deliveryDate', firstDate);
      setValue('deliverySlotId', '');
    }
  }, [availability, selectedDate, setValue, translationsContent.today, translationsContent.tomorrow]);

  useEffect(() => {
    const firstSlot = availability?.slotsForDate(selectedDate)?.[0];

    setValue('deliverySlotId', String(firstSlot?.deliverySlotId ?? ''));
  }, [availability, selectedDate, setValue]);

  const [servicesState, dispatch] = useReducer(servicesReducer, order.services);

  useEffect(() => {
    setValue('services', servicesState);
  }, [setValue, servicesState]);

  const orderScheduled = Boolean(order.deliveryInfo);

  const { toastService } = useServices();

  const submitForm = async (data: BookerFormFieldValues) => {
    delete data.search;

    setIsSubmitting(true);
    try {
      await onSubmit(data);

      if (orderScheduled) toastService.showSuccessToast(toastCopy ?? translationsContent.rescheduled);
      if (mutateOrder) {
        const deliverySlot = availability
          ?.slotsForDate(selectedDate)
          .find((slot) => String(slot.deliverySlotId) === data.deliverySlotId);
        if (deliverySlot) {
          const _order = order.copyWith({
            deliveryInfo: {
              bookingDate: selectedDate,
              bookingTimeslotStart: formatDate(
                parseDate(deliverySlot.startTime, DateFormat.longDateFormat),
                DateFormat.hourMinute,
              ),
              bookingTimeslotEnd: formatDate(
                parseDate(deliverySlot.endTime, DateFormat.longDateFormat),
                DateFormat.hourMinute,
              ),
              deliveryAddress: [data.line1, data.line2, data.city, data.postcode].filter(Boolean).join(' '),
            },
          });

          mutateOrder?.(_order, { revalidate: false, populateCache: true });
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const pleaseBookSentence = toshiStandardDelivery
    ? translationsContent.pleaseBookConvenientDate
    : translationsContent.pleaseBookConvenientDateTime;

  const addressComponents = useWatch({
    control,
    name: ['postcode', 'line1', 'city'],
  });

  const getDefaultDateSelectText = (): string => {
    if (!addressComponents.every(Boolean)) return translationsContent.pleaseEnterAnAddress;

    if (loading) return translationsContent.fetchingDates;

    return translationsContent.selectDate;
  };

  return (
    <>
      {isSubmitting && <LoadingSpinner />}
      <StyledForm $minimal={minimal} onSubmit={handleSubmit(submitForm)}>
        <FormItemContainer>
          <AddressFields
            mode={mode}
            control={control}
            setValue={setValue}
            errors={errors}
            register={register}
            setError={setError}
            clearErrors={clearErrors}
            orderId={order.id}
            order={order}
          />
        </FormItemContainer>
        {!minimal && (
          <StyledParagraphMedium bold $noMargin>
            {pleaseBookSentence}
          </StyledParagraphMedium>
        )}
        {toshiStandardDelivery ? (
          <StyledParagraphMedium $noMargin>{translationsContent.toshiStandardExplanation}</StyledParagraphMedium>
        ) : null}
        <FormItemContainer>
          <DropdownContainer>
            <StyledLabel htmlFor={'deliveryDate'}>
              <Column $noPadding $gap={0.75}>
                <StyledParagraphGrey bold>{translationsContent.arriveOn}</StyledParagraphGrey>
                <StyledSelect
                  disabled={!availabilityDataPresent}
                  id={'deliveryDate'}
                  {...register('deliveryDate', {
                    required: true,
                  })}
                  selected={selectedDate}
                  mode={SelectMode.Date}
                >
                  {availability && availability.dates.length ? (
                    <Options
                      options={availability.selectDates({
                        todayText: translationsContent.today,
                        tomorrowText: translationsContent.tomorrow,
                      })}
                    />
                  ) : (
                    <option value="">{getDefaultDateSelectText()}</option>
                  )}
                </StyledSelect>
              </Column>
            </StyledLabel>

            {!toshiStandardDelivery && (
              <StyledLabel htmlFor={'deliverySlotId'}>
                <Column $noPadding $gap={0.75}>
                  <StyledParagraphGrey bold>{translationsContent.between}</StyledParagraphGrey>
                  <StyledSelect
                    disabled={!selectedDate || loading || !slotsPresentForSelectedDate}
                    id={'deliverySlotId'}
                    {...register('deliverySlotId', { required: true })}
                    selected={selectedTime}
                    mode={SelectMode.Time}
                  >
                    {loading ? (
                      <option value="">{translationsContent.fetchingSlots}</option>
                    ) : slotsPresentForSelectedDate ? (
                      <Options options={availability!.selectSlotsForDate(selectedDate)} />
                    ) : (
                      <option value="">{translationsContent.noSlotsAvailableForDate}</option>
                    )}
                  </StyledSelect>
                </Column>
              </StyledLabel>
            )}
          </DropdownContainer>
          <AnimatePresence initial mode="wait">
            {selectedDate && !slotsPresentForSelectedDate && !loading && (
              <ErrorContainer style={{ marginTop: '1rem' }}>
                {translationsContent.noAvailabilitySelectDifferentDate}
              </ErrorContainer>
            )}
          </AnimatePresence>
        </FormItemContainer>
        {/* Disable & hide this until re-reviewing the service */}
        {mode == BookerMode.delivery && order.hasStoreServices && !order.isScheduled() && order.isEcommOrder && (
          <FormItemContainer>
            <Services
              orderScheduled={orderScheduled}
              servicesState={servicesState}
              updateServiceState={dispatch}
              storeServices={order.storeServices}
              isGiftedOrder={Boolean(order.giftReceiver)}
            />
          </FormItemContainer>
        )}
        <FormItemContainer>
          <StyledLabel htmlFor={'receiveUpdates'} id={'ftime'} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Row $noPadding $gap={0.75}>
              <Checkbox {...register('receiveUpdates')} id={'receiveUpdates'} style={{ marginBlockStart: '0.2rem' }} />
              <StyledParagraph $size="sm" $noMargin as="span">
                {translationsContent.toshiUpdates}
              </StyledParagraph>
            </Row>
          </StyledLabel>
        </FormItemContainer>
        <BookerFormCTA $size={minimal ? 'sm' : 'md'} type="submit" disabled={loading || !selectedTime}>
          {translationsContent.cta}
        </BookerFormCTA>
        <AnimatePresence initial mode="wait">
          {submitError && (
            <ErrorContainer>
              {typeof submitError === 'string' ? submitError : translationsContent.errorSubmittingGiftOrder}
            </ErrorContainer>
          )}
        </AnimatePresence>
      </StyledForm>
    </>
  );
};
