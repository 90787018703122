import { getOrderInfoEndpoint, orderDTOSchema } from '../api/endpoints/v3/my_toshi/orders/[id]';

import { useGetData, type UseGetDataOptions } from './useGetData';

export const useGetOrder = (id: string | number, options?: UseGetDataOptions) =>
  useGetData({
    key: `${getOrderInfoEndpoint}/${id}`,
    schemaConfig: { schema: orderDTOSchema, schemaName: 'OrderResponse' },
    options,
  });
