import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { useServices } from '@contexts/ServicesContext';
import { SearchParams } from '@utils/helpers/searchParams';
import { zodNonEmptyString } from '@utils/helpers/validation';

const toastURLSchema = z.object({
  message: zodNonEmptyString(),
  type: z.union([z.literal('success'), z.literal('error')]),
});

type TtoastURLSchema = z.infer<typeof toastURLSchema>;

const parseToastState = (encodedState: string) => {
  try {
    return JSON.parse(decodeURIComponent(encodedState)) as TtoastURLSchema;
  } catch (_) {
    return undefined;
  }
};

export const createToastState = (state: TtoastURLSchema): string =>
  new URLSearchParams({
    [SearchParams.toast]: JSON.stringify(state),
  }).toString();

export const useURLToast = ({ style }: { style?: React.CSSProperties } = {}) => {
  const { toastService } = useServices();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const urlState = searchParams.get(SearchParams.toast);

    if (urlState) {
      const result = toastURLSchema.safeParse(parseToastState(urlState));

      if (result.success) {
        const { message, type } = result.data;
        switch (type) {
          case 'success': {
            toastService.showSuccessToast(message, style);
            break;
          }
          case 'error': {
            toastService.showErrorToast(message, style);
            break;
          }
        }

        searchParams.delete(SearchParams.toast);
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, setSearchParams, style, toastService]);
};
