import deliveryTexts from './translationTextsDelivery-engb.json';
import returnTexts from './translationTextsReturn-engb.json';

export const deliveryTranslations = {
  [deliveryTexts.language]: deliveryTexts,
};
export const returnTranslations = {
  [returnTexts.language]: returnTexts,
};

export type translationsType = typeof deliveryTexts.translations | typeof returnTexts.translations;
