{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "alteration": "Fitting and Pinning",
    "alterationDescription": "Our assistant will adjust your sunglasses to ensure comfort and fit. (On acetate and metallic frames only)",
    "atTheStore": "At the store",
    "attemptedCollection": "We attempted and were unable to collect your order. Please rebook your collection using the button above.",
    "attemptedDelivery": "We attempted and were unable to deliver your order. Please rebook your delivery using the button above.",
    "attemptedPickup": "We attempted and were unable to collect your order. Please reach out to {0} for any additional information.",
    "bookCollection": "Book my collection",
    "bookDelivery": "Book my delivery",
    "bookYourGiftDelivery": "Book your gift delivery",
    "bookedForToday": "Booked for Today",
    "bookedForTomorrow": "Booked for Tomorrow",
    "cancelled": "Cancelled",
    "cancelledAfterMaximumCollectionAttempt": "Your order has been cancelled, after the maximum collection attempts. Please reach out to {0} for any additional information.",
    "cancelledAfterMaximumDeliveryAttempt": "Your order has been cancelled, after the maximum delivery attempts. Please reach out to {0} for any additional information.",
    "collected": "Collected",
    "collectionAddress": "Collection Address",
    "collectionCompleted": "Order complete - items collected",
    "delayedAtBrand": "Your order has been delayed at the brand. Please reach out to {0} for additional information.",
    "delivered": "Delivered",
    "deliveryAddress": "Delivery Address",
    "deliveryCompleted": "Order complete - items delivered",
    "deliveryUpdates": "Order Updates",
    "inspireMe": "Inspire Me",
    "inspireMeDescription": "Your TOSHI assistant will bring additional items for you to try (sizes, colors, styles, etc) - pay only for what you keep and we’ll take back unwanted items",
    "itemsCollected": "Items collected",
    "needToReturnAnItem": "Need to return an item?",
    "notYetBooked": "Not yet booked",
    "onTheWay": "On the way to you",
    "orderHasBeenCancelled": "Your order has been cancelled, there will be no further order updates. Please reach out to {0} for any additional information.",
    "orderNumber": "Order number",
    "pageTitle": "Order details",
    "perfectFit": "Perfect Fit",
    "perfectFitDescription": "Your TOSHI Assistant will be equipped to pin your items. We will assess and determine what adjustments are possible.",
    "realtimeCollectionUpdatesWillAppear": "Real time updates will appear here closer to the time of your collection.",
    "realtimeUpdatesWillAppear": "Real time updates will appear here closer to the time of your delivery.",
    "rebookCollection": "Rebook my collection",
    "rebookDelivery": "Rebook my delivery",
    "rescheduleCollection": "Reschedule my collection",
    "rescheduleDelivery": "Reschedule my delivery",
    "returnItemsFromThisOrder": "Return items from this order",
    "selectedToshiServices": "Selected TOSHI services",
    "upDownSize": "Size up / size down",
    "upDownSizeDescription": "Your TOSHI assistant will bring you different sizes to try on and take back unwanted sizes, hassle free",
    "unableToCollect": "Unable to collect",
    "unableToDeliver": "Unable to deliver",
    "unableToPickup": "Unable to pickup",
    "waitAndTry": "Wait & Try",
    "waitAndTryDescription": "Your TOSHI assistant will wait while you decide what to keep, and take back unwanted item(s), hassle free",
    "yourOrder": "YOUR ORDER DETAILS"
  }
}
