export const colorVars = {
  blackColor: '#1A1919',
  whiteColor: '#FFFFFF',
  lightGrey: '#E5E5E5',
  lighterGrey: '#F7F7F7',
  mainGrey: '#687887',
  darkGrey: '#CCCCCC',
  redColor: 'red',
  ghostGrey: '#999999',
};

export const theme = {
  blackColor: colorVars.blackColor,
  whiteColor: colorVars.whiteColor,
  lightGrey: colorVars.lightGrey,
  lighterGrey: colorVars.lighterGrey,
  mainGrey: colorVars.mainGrey,
  darkGrey: colorVars.darkGrey,
  ghostGrey: colorVars.ghostGrey,
  errorColor: colorVars.redColor,
  fontFamily: 'MarkOT',
  color: colorVars.blackColor,
  invertColor: colorVars.whiteColor,
  fontColorFaded: '#999999',
  fontSizeSmall: '12px',
  fontSizeNormal: '15px',
  fontSizeInput: '18px',
  fontSizeLarge: '22px',
  fontSizeMedium: '16px',
  fontSizeSubTitle: '28px',
  fontSizeTitle: '36px',
  backgroundColor: colorVars.lighterGrey,
  textBackgroundColor: colorVars.whiteColor,
  outline: 'none',
  boxShadow: '0px 4px 16px 4px rgba(0, 0, 0, 0.1)',
} as const;

export type ThemeType = typeof theme;
