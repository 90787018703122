import handsIcon from '@assets/icons/hands-icon.svg';
import styled from 'styled-components';
import { queriesForTheme } from '@config/styling/breakPoints';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.invertColor};
`;

interface BrandLogoProps {
  brandName: string;
  mobile?: boolean;
  brandLogoUrl?: string;
}

const StyledContainer = styled.div<{ mobile?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${(props) => (props.mobile ? '200px' : '300px')};
  max-height: ${(props) => (props.mobile ? '200px' : '400px')};
  background-color: ${(props) => props.theme.whiteColor};
  ${(props) => (!props.mobile ? 'display: none;' : '')};

  ${queriesForTheme.isDesktop} {
    ${(props) => (props.mobile ? 'display: none;' : '')};
    ${(props) => (!props.mobile ? 'display: flex;' : '')};
  }
`;

const Logo = styled.img<{ usingFallback: boolean; mobile?: boolean }>`
  width: ${(props) => (props.mobile ? '130px' : '239px')};
  background-color: ${(props) => props.theme.whiteColor};
  ${(props) => (props.usingFallback ? 'opacity: 0.5;' : '')};
`;

export const BrandLogo = ({ brandName, mobile, brandLogoUrl }: BrandLogoProps): JSX.Element => {
  const brandLogo = brandLogoUrl || handsIcon;

  return (
    <StyledContainer mobile={mobile}>
      <LogoContainer>
        <Logo
          mobile={mobile}
          usingFallback={!brandLogoUrl}
          alt={`Toshi Logo and Brand Logo for ${brandName}`}
          src={brandLogo}
        />
      </LogoContainer>
    </StyledContainer>
  );
};
