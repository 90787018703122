import * as styled from 'styled-components';
import { queriesForTheme } from './breakPoints';

export const GlobalStyles = styled.createGlobalStyle`
  * {
    box-sizing: border-box;
    outline: ${(props) => props.theme.outline};
    color: ${(props) => props.theme.color};
    font-family: MarkOT;
    font-size: ${(props) => props.theme.fontSizeNormal};
    font-weight: 400;
  }

  body,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  body {
    position: relative;
    min-height: 100%;
    background-color: var(--colour-background);
  }

  a {
    background-color: transparent;
    text-decoration: underline;
  }

  :root {
    --font-size-1: 0.75rem; /* fontSizeSmall */
    --font-size-2: 0.9rem; /* fontSizeNormal */
    --font-size-3: 1rem; /* fontSizeInput */
    --font-size-4: 1.2rem; /* fontSizeLarge */
    --font-size-5: 1.4rem; /* fontSizeMedium */
    --font-size-6: 1.8rem; /* fontSizeSubTitle */
    --font-size-7: 2rem; /* fontSizeTitle */
    --font-size-8: 2.2rem; /* fontSizeTitle */

    ${queriesForTheme.isMobile} {
      --font-size-1: 0.5rem;
      --font-size-2: 0.75rem;
      --font-size-3: 0.9rem;
      --font-size-4: 1rem;
      --font-size-5: 1.2rem;
      --font-size-6: 1.4rem;
      --font-size-7: 1.8rem;
      --font-size-8: 2rem;
    }

    --colour-black: #1a1919;
    --colour-white: #fff;
    --colour-red: red;
    --colour-light-grey: #e5e5e5;
    --colour-lighter-grey: #f7f7f7;
    --colour-main-grey: #687887;
    --colour-dark-grey: #ccc;
    --colour-ghost-grey: #999;
    --colour-green: #f2f8f3;
    --colour-background: var(--colour-lighter-grey);
    --toastify-color-success: var(--colour-green);
    --toastify-toast-bd-radius: 0px;

    /* ~header height */
    --toastify-toast-top: 5.5rem;

    ${queriesForTheme.isDesktop} {
      --toastify-toast-top: 10rem;
      --toastify-toast-right: 8rem;
    }
  }

  /* stylelint-disable selector-class-pattern */
  .Toastify__toast-container {
    padding: 0;
    ${queriesForTheme.isMobile} {
      top: var(--toastify-toast-top);
    }
  }

  .Toastify__toast {
    border: 1px solid var(--colour-black);
    ${queriesForTheme.isMobile} {
      margin-inline: 2rem;
    }
  }

  .ReactModal__Overlay {
    transition: opacity 300ms ease-in-out;
    opacity: 0;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .privacy-screen {
    position: fixed;
    z-index: 2;
    right: 0;
    left: 0;
    background-color: var(--colour-light-grey);
  }
`;
