import Bugsnag, { type NotifiableError } from '@bugsnag/js';
import { useEffect, type FC } from 'react';
import { useRouteError } from 'react-router';
import styled from 'styled-components';

import { Page } from '@pages/Page';
import { getEnvironment } from '@utils/helpers/getEnvironment';
import { StyledParagraphLarge, StyledParagraphMedium } from '@components/ui/Typography';
import { Layout } from '@pages/layout/Layout';
import { useTranslations } from '@locale/useTranslations';
// import { notifiableErrorSchema } from '@utils/helpers/validation';

import { translations } from './translations';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10%;
`;

export const GenericError: FC = () => {
  const { translationsContent } = useTranslations(translations);

  const error = useRouteError();

  useEffect(() => {
    // const result = notifiableErrorSchema.safeParse(error);
    // const shouldNotify = error && ['production', 'staging'].includes(getEnvironment()) && result.success;
    const shouldNotify = error && ['production', 'staging'].includes(getEnvironment());

    if (shouldNotify) Bugsnag.notify(error as NotifiableError);
  }, [error]);

  return (
    <Page>
      <Layout>
        <Main>
          <StyledParagraphLarge>{translationsContent.genericErrorTitle}</StyledParagraphLarge>
          <StyledParagraphMedium>
            {translationsContent.genericErrorBody}{' '}
            <StyledParagraphMedium as={'a'} href="mailto:clientservice@toshi.co">
              clientservice@toshi.co
            </StyledParagraphMedium>
          </StyledParagraphMedium>
        </Main>
      </Layout>
    </Page>
  );
};
