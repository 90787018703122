import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { RoutePaths } from 'src/router';

export const useNavigateUnauthorised = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(() => {
    navigate(RoutePaths.unauthorized, {
      state: {
        path: location.pathname,
      },
    });
  }, [location.pathname, navigate]);
};
