import { useCallback, useState, type FC } from 'react';
import {
  useWatch,
  type Control,
  type FieldErrors,
  type UseFormClearErrors,
  type UseFormRegister,
  type UseFormSetError,
  type UseFormSetValue,
} from 'react-hook-form';
import styled from 'styled-components';

import { StyledParagraph, type BoldProp } from '@components/ui/Typography';
import {
  addressAutocompleteCollectionDTOSchema,
  addressSearchEndpoint,
  addressSearchParamsSchema,
  type AddressAutocomplete,
} from '@data/api/endpoints/v2/gifts/address_searches';
import {
  addressEligibleDTOSchema,
  addressEligibleEndpoint,
  addressEligibleParamsSchema,
} from '@data/api/endpoints/v2/gifts/orders/[id]/postcode_verifications';
import { LocationCode } from '@data/api/endpoints/v3/my_toshi/orders/[id]';
import { useMutation } from '@data/hooks/useMutation';
import { type Order } from '@data/models/Order';
import { BookerMode } from '@hooks/useBookerMode';
import { useDebouncedEffect } from '@hooks/useDebouncedEffect';

import { AddressFinder } from './AddressFinder';
import { type BookerFormFieldValues } from './BookerForm';
import { FormField } from './FormField';
import { useBookerFormTranslations } from './useBookerFormTranslations';

const EnterManualLink = styled.button`
  display: flex;
  margin-top: 8px;
  margin-bottom: 22px;
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
`;

export type SearchResult = AddressAutocomplete['addressComponents'];

const ErrorContainer = styled.div`
  color: var(--colours-red);
`;

const Hidden = styled.div`
  display: none;
`;

const Span = styled.span<BoldProp>`
  font-size: ${(props) => props.theme.fontSizeLarge};
  ${(props) => (props.bold ? 'font-weight: 600' : '')};
`;

interface WelcomeMessageProps {
  brandName: string;
  orderNumber: string;
  mode: BookerMode;
}

export const WelcomeMessage = ({ brandName, orderNumber, mode }: WelcomeMessageProps): JSX.Element => {
  if (mode == BookerMode.delivery) {
    return (
      <StyledParagraph $size={'md'}>
        Welcome to TOSHI - your luxury delivery partner. We will be delivering your <Span bold>{brandName}</Span> order{' '}
        <Span bold>#{orderNumber}</Span> to the following address:
      </StyledParagraph>
    );
  }

  return (
    <StyledParagraph $size={'md'}>
      Welcome to TOSHI - your luxury delivery partner. We will be collecting your <Span bold>{brandName}</Span> order{' '}
      <Span bold>#{orderNumber}</Span> from the following address:
    </StyledParagraph>
  );
};

interface AddressFieldsProps {
  register: UseFormRegister<BookerFormFieldValues>;
  errors: FieldErrors<BookerFormFieldValues>;
  setError: UseFormSetError<BookerFormFieldValues>;
  clearErrors: UseFormClearErrors<BookerFormFieldValues>;
  setValue: UseFormSetValue<BookerFormFieldValues>;
  control: Control<BookerFormFieldValues>;
  orderId: string;
  order: Order;
  mode: BookerMode;
}

export const AddressFields: FC<AddressFieldsProps> = ({
  register,
  errors,
  setError,
  clearErrors,
  setValue,
  control,
  orderId,
  order,
}) => {
  const [manualLookUp, setManualLookUp] = useState(false);
  const [term, setTerm] = useState('');
  const { translationsContent } = useBookerFormTranslations();
  const postcodeWatch = useWatch({
    control,
    name: 'postcode',
  });

  const { trigger: triggerEligibilityCheck } = useMutation({
    url: addressEligibleEndpoint(orderId),
    reqSchemaConfig: {
      schema: addressEligibleParamsSchema,
      schemaName: 'AddressEligibleParams',
    },
    resSchemaConfig: {
      schema: addressEligibleDTOSchema,
      schemaName: 'AddressEligibleResponse',
    },
    onError: () =>
      setError('postcode', {
        type: 'invalid',
        message: translationsContent.serverError,
      }),
  });

  const handlePostcodeEligibility = useCallback(async () => {
    if (!postcodeWatch) {
      clearErrors('postcode');
      return;
    }

    const data = await triggerEligibilityCheck({
      postcode: postcodeWatch,
      storeId: order.storeId,
      orderId: orderId,
    });

    if (!data.eligible) {
      setError('postcode', {
        type: 'invalid',
        message:
          order.locationCode === LocationCode.LDN
            ? translationsContent.mustBeLondonZones
            : translationsContent.mustBeManhattan,
      });
    } else {
      clearErrors('postcode');
    }
  }, [
    postcodeWatch,
    triggerEligibilityCheck,
    order.storeId,
    order.locationCode,
    orderId,
    clearErrors,
    setError,
    translationsContent.mustBeLondonZones,
    translationsContent.mustBeManhattan,
  ]);

  const {
    data,
    trigger: triggerAddressSearch,
    reset,
  } = useMutation({
    url: addressSearchEndpoint,
    reqSchemaConfig: {
      schema: addressSearchParamsSchema,
      schemaName: 'AddressSearchParams',
    },
    resSchemaConfig: {
      schema: addressAutocompleteCollectionDTOSchema,
      schemaName: 'AddressSearchResponse',
    },
    onError: () =>
      setError('search', {
        type: 'server',
        message: translationsContent.serverError,
      }),
  });

  const handleAddressSearch = useCallback(async () => {
    if (term === '') {
      clearErrors('search');
      reset();
      return;
    }

    await triggerAddressSearch({
      query: term,
      storeId: order.storeId,
    });
  }, [term, triggerAddressSearch, order.storeId, clearErrors, reset]);

  useDebouncedEffect(
    () => {
      handlePostcodeEligibility();
    },
    1000,
    [postcodeWatch, handlePostcodeEligibility],
  );

  useDebouncedEffect(
    () => {
      handleAddressSearch();
    },
    1000,
    [term, handleAddressSearch],
  );

  const manualForm = (
    <>
      <StyledParagraph bold $size={'md'} style={{ marginBottom: '2rem' }}>
        {translationsContent.bringItemsTo}
      </StyledParagraph>
      <FormField
        id="line1"
        errorText={errors.line1}
        required
        labelText={translationsContent.line1}
        {...register('line1')}
      />
      <FormField id="line2" errorText={errors.line2} {...register('line2')} labelText={translationsContent.line2} />
      <FormField
        id="city"
        errorText={errors.city}
        required
        {...register('city')}
        labelText={translationsContent.city}
      />
      <FormField
        id="postcode"
        required
        {...register('postcode')}
        labelText={translationsContent.postcode}
        customError={
          <>
            {errors.postcode && errors.postcode.type == 'required' && (
              <ErrorContainer>{translationsContent.fieldIsRequired}</ErrorContainer>
            )}
            {errors.postcode && errors.postcode.type == 'invalid' && (
              <ErrorContainer>{errors.postcode.message}</ErrorContainer>
            )}
          </>
        }
      />
      <FormField
        isLastField
        id="country"
        errorText={errors.country}
        {...register('country')}
        labelText={translationsContent.country}
      />
    </>
  );

  const unscheduledOrderAddress = order.unscheduledOrderAddress;
  const formattedUnscheduledOrderAddress = unscheduledOrderAddress
    ? [unscheduledOrderAddress.line1, unscheduledOrderAddress.cityTown, unscheduledOrderAddress.postcodeZipcode]
        .filter(Boolean)
        .join(', ')
    : undefined;

  const address = order.deliveryInfo?.deliveryAddress ?? formattedUnscheduledOrderAddress;

  if (address) {
    return <Hidden>{manualForm}</Hidden>;
  }

  return (
    <>
      {manualLookUp ? (
        manualForm
      ) : (
        <AddressFinder
          term={term}
          setTerm={setTerm}
          results={data?.predictions.map(({ addressComponents }) => addressComponents) ?? []}
          setValue={setValue}
          setManualLookUp={setManualLookUp}
        />
      )}

      <EnterManualLink onClick={() => setManualLookUp((prev) => !prev)} type="button">
        {manualLookUp ? translationsContent.orSearch : translationsContent.orManual}
      </EnterManualLink>
    </>
  );
};
