import { type FC } from 'react';
import { StyledParagraphLarge, StyledParagraphLargeGrey } from '@components/ui/Typography';
import { transformDate, formatDate, DateFormat } from '@utils/helpers/dates';

import { type TranslationProp } from './DeliveryDetails';
import { Column } from '@components/ui/Containers';
import { type Journey } from '@data/api/endpoints/shared/journeyDTO';

interface UnableToProps extends TranslationProp {
  mostRecentJourney: Journey;
}

export const UnableToComplete: FC<UnableToProps> = ({ mostRecentJourney, translations }) => {
  const unableToCompleteTransition = mostRecentJourney.journeyTransitions.filter(
    (transition) => transition.toState === 'unable_to_dropoff' || transition.toState === 'unable_to_pickup',
  )?.[0];
  const localisedAttemptedDatetime = transformDate(unableToCompleteTransition.createdAt);
  const attemptedDateTime = formatDate(localisedAttemptedDatetime, DateFormat.dateMonthHourMinuteAmPm);

  let headerText: string;

  const statusToHeaderTextMap: Record<string, string> = {
    unable_to_dropoff: translations.unableToDeliver,
    unable_to_pickup: translations.unableToPickup,
  };

  if (mostRecentJourney.type === 'DeliveryJourney') {
    headerText = statusToHeaderTextMap[mostRecentJourney.status];
  } else {
    headerText = translations.unableToCollect;
  }

  return (
    <Column as="header" $noPadding $gap={0.5}>
      <StyledParagraphLarge $bold $noMargin>
        {headerText}
      </StyledParagraphLarge>
      <StyledParagraphLargeGrey $bold $noMargin>
        {attemptedDateTime}
      </StyledParagraphLargeGrey>
    </Column>
  );
};
