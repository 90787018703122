import { z } from 'zod';

import { v3MyToshiBaseUrl } from '../../../baseUrl';
import { transformDTO } from '@data/transformers/responses';

export const checkStripeStatus = (id: string | number) => `${v3MyToshiBaseUrl}/stripe/checkout_sessions/${id}`;

export enum StripePaymentStatus {
  noPaymentRequired = 'no_payment_required',
  paid = 'paid',
  unpaid = 'unpaid',
}
export enum ToshiPaymentStatus {
  paid = 'paid',
  unpaid = 'unpaid',
}

export const checkStripeStatusDTOSchema = z
  .object({
    stripe_payment_status: z.nativeEnum(StripePaymentStatus),
    toshi_payment_status: z.nativeEnum(ToshiPaymentStatus),
  })
  .transform(transformDTO);

export type CheckStripeStatusDTO = z.input<typeof checkStripeStatusDTOSchema>;
export type CheckStripeStatus = z.output<typeof checkStripeStatusDTOSchema>;
