{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "genericErrorBody": "I'm sorry, this link is currently not working as expected. Please either try again, or contact us on",
    "genericErrorTitle": "Unexpected error",
    "invalidUrlBody": "If you believe this is a mistake, please contact our support team for further assistance.",
    "invalidUrlFooter": "Thank you for your understanding.",
    "invalidUrlHeader": "Oops! The page you're trying to access doesn't seem to exist. Please check the URL for any typos or errors and try again.",
    "pageTitleInvalidUrl": "Invalid address",
    "pageTitleUnauthorised": "Unauthorised",
    "sendEmail": "Send email",
    "sentEmailTo": "We've sent an email to the address listed above, if we have it in our records.",
    "sessionEnded": "Your previous session on My TOSHI has ended and you are no longer authorised - please enter your email address in the box below to receive an email with a new link, in order to use My TOSHI again."
  }
}
