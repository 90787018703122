import isPropValid from '@emotion/is-prop-valid';
import { type FC, type PropsWithChildren } from 'react';
import { ToastContainer } from 'react-toastify';
import styled, { StyleSheetManager, ThemeProvider } from 'styled-components';

import { GlobalFonts } from '@assets/fonts/font-faces';
import { GlobalStyles } from '@config/styling/GlobalStyles';
import { theme } from '@config/styling/theme';
import { Footer } from './Footer';
import { Header } from './Header';

const StyledLayout = styled.main`
  --header-footer-height: minmax(auto, 5rem);

  display: grid;
  grid-auto-flow: column;
  grid-template-rows: var(--header-footer-height) 1fr var(--header-footer-height);
  min-height: 100vh;
`;

export const Layout: FC<PropsWithChildren> = ({ children }) => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <ThemeProvider theme={theme}>
      <GlobalFonts />
      <GlobalStyles />
      <ToastContainer />
      <StyledLayout>
        <Header />
        <div id="main">{children}</div>
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  </StyleSheetManager>
);

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}
