{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "hello": "Hello",
    "pageTitleDelivery": "Book your delivery",
    "pageTitleReturn": "Book your collection"
  }
}
