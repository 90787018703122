import { isToday, isTomorrow, format, parse, parseISO, formatDistance } from 'date-fns';

// See https://date-fns.org/v2.30.0/docs/format
export enum DateFormat {
  dateDay = `EEEE`, // => Tuesday
  dateMonth = 'MMMM do', // => December 15th
  dateMonthHourMinuteAmPm = `MMMM do | h:mmaaa`, // => December 15th | 2:30pm
  dateMonthYear = `do MMMM y`, // => December 15th 2023
  dayDateMonthYear = 'EEEE do MMMM y', // => Tuesday December 15th 2023
  hourMinute = `HH:mm`, // => 14:30
  hourMinuteAmPm = `h:mmaaa`, // => 2:30pm
  longDateFormat = `yyyy-MM-dd'T'HH:mm:ss'.'SSS'Z'`, // => 2023-12-15T14:30:00.000Z
  yearMonthDate = `yyyy-MM-dd`, // => 2023-12-15
}

export const formatDate = (date: Date, dateFormat: DateFormat): string => format(date, dateFormat);

export const parseDate = (date: string, dateFormat: DateFormat): Date => parse(date, dateFormat, new Date());

export const isTodayOrTomorrow = (date: Date): 'today' | 'tomorrow' | '' => {
  if (isToday(date)) {
    return 'today';
  }

  if (isTomorrow(date)) {
    return 'tomorrow';
  }

  return '';
};

export const isOtherDate = (date: Date): string => formatDate(date, DateFormat.dateDay);

export const transformDate = (date: Date | string): Date => (date instanceof Date ? date : parseISO(date));

export const timeAgo = (date: Date): string => formatDistance(new Date(), date);

export const formatDateTodayOrTomorrow = (
  date: Date,
  dateFormat: DateFormat,
  todayTomorrowCopy = {
    todayText: 'Today',
    tomorrowText: 'Tomorrow',
  },
): string => {
  const todayTomorrow = isTodayOrTomorrow(date);
  const todayOrTomorrowText = todayTomorrow
    ? { today: ` (${todayTomorrowCopy.todayText})`, tomorrow: ` (${todayTomorrowCopy.tomorrowText})` }[todayTomorrow]
    : '';

  return formatDate(date, dateFormat) + todayOrTomorrowText;
};
