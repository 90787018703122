import { forwardRef, type InputHTMLAttributes, type ReactElement } from 'react';
import { type FieldError } from 'react-hook-form';
import styled from 'styled-components';

import { StyledLabel, StyledInput } from '@components/ui/formComponents/FormComponents';
import { StyledParagraphGrey } from '@components/ui/Typography';
import { Column } from '@components/ui/Containers';

import { useBookerFormTranslations } from './useBookerFormTranslations';

interface FormItemContainerProps {
  paddingOff?: boolean;
}

const FormItemContainer = styled.div<FormItemContainerProps>`
  width: 100%;
  padding-bottom: ${(props) => (props.paddingOff ? '0px' : '20px')};
`;

const LabelTextContainer = styled.div`
  padding-bottom: 8px;
`;

const ErrorContainer = styled.div`
  color: var(--colours-red);
`;

interface FormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  errorText?: FieldError;
  isLastField?: boolean;
  customError?: JSX.Element;
}

const FieldRequiredError = () => {
  const { translationsContent } = useBookerFormTranslations();
  return <ErrorContainer>{translationsContent.fieldIsRequired}</ErrorContainer>;
};

export const FormField = forwardRef<HTMLInputElement, FormFieldProps>(
  ({ labelText, errorText, customError, isLastField, ...inputProps }, ref) => (
    <FormItemContainer paddingOff={isLastField}>
      <StyledLabel>
        <Column $noPadding $gap={0.75}>
          <StyledParagraphGrey bold>{labelText}</StyledParagraphGrey>
          <StyledInput {...inputProps} ref={ref} />
          {!customError && errorText && <FieldRequiredError />}
          {customError && customError}
        </Column>
      </StyledLabel>
    </FormItemContainer>
  ),
);

FormField.displayName = 'FormField';

interface DisabledFormFieldProps {
  labelText: ReactElement;
  isLastField?: boolean;
  value: React.InputHTMLAttributes<HTMLInputElement>['value'];
}
export const DisabledFormField = ({ labelText, isLastField, value }: DisabledFormFieldProps): JSX.Element => (
  <FormItemContainer paddingOff={isLastField}>
    <StyledLabel>
      <LabelTextContainer>{labelText}</LabelTextContainer>
      <StyledParagraphGrey bold>{value}</StyledParagraphGrey>
    </StyledLabel>
  </FormItemContainer>
);
