import Bugsnag from '@bugsnag/js';
import { type z } from 'zod';

export interface ValidateConfig<T extends z.ZodTypeAny> {
  schema: T;
  schemaName: string;
}

export function validateSchema<T extends z.ZodTypeAny>(obj: unknown, config: ValidateConfig<T>): z.infer<T> {
  const result = config.schema.safeParse(obj);

  if (result.success) {
    return result.data;
  } else {
    handleError(`API Validation Error: ${config.schemaName}`, result.error);

    throw result.error;
  }
}

function handleError(message: string, error: Error): void {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    console.error(message, error);
  } else Bugsnag.notify(error);
}
