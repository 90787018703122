import styled from 'styled-components';
import { breakPoint2XL, queriesForTheme } from './breakPoints';

export const ColumnForty = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${queriesForTheme.isDesktop} {
    align-items: center;
    width: 40%;
    padding: 30px;
  }

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: ${breakPoint2XL}px) {
    padding: 48px 70px;
  }
`;

export const ColumnFifty = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  ${queriesForTheme.isDesktop} {
    width: 50%;
    padding: 48px 70px;
  }
`;

export const ColumnSixty = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  ${queriesForTheme.isDesktop} {
    align-items: center;
    width: 60%;
    padding: 30px;
  }

  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: ${breakPoint2XL}px) {
    padding: 48px 70px;
  }
`;
