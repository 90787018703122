// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.

import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getEnvironment } from '@utils/helpers/getEnvironment';

const stripePromiseKey =
  getEnvironment() === 'production'
    ? 'pk_live_51IqGiIENe9taMJBgAjxUw0GaG4C3r4MOowyWsX0Yl7mFPLpJPYnFFFllCULyA8X0pKXCITRvcDlzVtq9f91QvDFY00eXwoxCf7'
    : 'pk_test_51IqGiIENe9taMJBg6McpmA4MN6YN6Axjoy2CxAoTvLyYT3rRiPLYvyJzIMd2qlD340GIJBgseBW0CzlM3JOWVEM700egs8tuFo';

const stripePromise = loadStripe(stripePromiseKey);

export const StripeCheckout = ({ clientSecret }: { clientSecret: string }) => (
  <div id="checkout">
    <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  </div>
);
