{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "waitingForAuth": "Waiting for auth..."
  }
}
