import { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import close from '@assets/icons/close.svg';
import giftIcon from '@assets/icons/gift-icon.svg';
import gift from '@assets/images/gift.svg';
import { StyledParagraph, StyledParagraphLarge, StyledParagraphMedium } from '@components/ui/Typography';
import { queriesForTheme } from '@config/styling/breakPoints';
import { useTranslations } from '@locale/useTranslations';
import { translations } from './translations/index';

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color};
`;

const LabelContainerTop = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 14px 22px 20px;
  background-color: ${(props) => props.theme.color};
`;

const GiftImage = styled.img`
  position: absolute;
  right: 50%;
  bottom: -10px;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.theme.color};

  ${queriesForTheme.isDesktop} {
    position: static;
    width: 40px;
    height: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.color};

  ${queriesForTheme.isDesktop} {
    align-items: flex-start;
    margin-left: 22px;
  }
`;

const LabelContainerBottom = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.invertColor};
`;

const MessageLinkContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 9px 22px 15px;
  background-color: ${(props) => props.theme.color};

  ${queriesForTheme.isDesktop} {
    justify-content: flex-start;
  }
`;

const LabelLink = styled.button`
  justify-content: center;
  border: none;
  background: transparent;
  color: ${(props) => props.theme.invertColor};
  text-decoration: underline;
  cursor: pointer;
`;

const SenderText = styled(StyledParagraph)`
  background: transparent;
  color: ${(props) => props.theme.invertColor};
  text-align: left;
  text-transform: uppercase;
`;

const GiftIcon = styled.img`
  height: ${(props) => props.theme.fontSizeTitle};
  margin-right: 5px;
  background-color: transparent;
`;

const ModalContent = styled.div`
  background-color: white;

  & div {
    background-color: white;
  }
`;

const CloseButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
const CloseIcon = styled.img`
  height: ${(props) => props.theme.fontSizeNormal};
  margin-right: 5px;
  opacity: 0.5;
  background-color: transparent;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  gap: 20px;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: end;
`;

interface GiftLabelProps {
  giftSender: string | null;
  giftMessage?: string;
  brandName: string;
}

export const GiftLabel = ({ giftSender, giftMessage, brandName }: GiftLabelProps): JSX.Element => {
  const { translationsContent, getTranslationWithValues } = useTranslations(translations);
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <LabelContainer>
      <LabelContainerTop>
        <GiftImage src={gift} alt={translationsContent.giftIconAlt} />
        <TextContainer>
          {giftSender && (
            <SenderText $size="lg" $noMargin bold>
              {giftSender}
            </SenderText>
          )}
          <StyledParagraph center invertColor $uppercase $noMargin>
            {getTranslationWithValues(translationsContent.giftReceivedSentence, [brandName])}
          </StyledParagraph>
        </TextContainer>
      </LabelContainerTop>
      {giftMessage && giftMessage !== '' ? (
        <LabelContainerBottom>
          <MessageLinkContent>
            <LabelLink onClick={() => setIsOpen(true)}>{translationsContent.readMessageLink}</LabelLink>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              contentLabel={translationsContent.giftMessageModalContentLabel}
              style={{
                content: {
                  width: '70%',
                  height: 'fit-content',
                  padding: 20,
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                },
              }}
              closeTimeoutMS={300}
            >
              <ModalContent>
                <ActionBar>
                  <CloseButton onClick={() => setIsOpen(false)}>
                    <CloseIcon src={close} alt="Close button" />
                  </CloseButton>
                </ActionBar>
                <TextContent>
                  <StyledParagraphLarge>{giftMessage}</StyledParagraphLarge>
                  <StyledParagraphMedium>
                    <GiftIcon src={giftIcon} alt="Gift icon" />
                  </StyledParagraphMedium>
                </TextContent>
              </ModalContent>
            </Modal>
          </MessageLinkContent>
        </LabelContainerBottom>
      ) : null}
    </LabelContainer>
  );
};
