import Bugsnag, { type NotifiableError } from '@bugsnag/js';
import { AxiosError } from 'axios';

import { notifiableErrorSchema } from '@utils/helpers/validation';
import { ZodError } from 'zod';
import { type FetcherError } from './fetchers';

export async function handleApiError(
  err: AxiosError | unknown,
  navigateUnauthorised: VoidFunction,
  onError?: (err: FetcherError | ZodError | Error) => unknown,
) {
  if (err instanceof ZodError) {
    throw err;
  }

  const parsedErrorResult = notifiableErrorSchema.safeParse(err);

  if (parsedErrorResult.success) {
    Bugsnag.notify(err as NotifiableError);
  }

  if (err instanceof AxiosError) {
    const status = err.response?.status || 0;

    if (status === 401) {
      navigateUnauthorised();
      return;
    }

    if (status < 200 || status >= 400) {
      onError?.(err);
      throw err;
    }
  }

  throw err;
}
