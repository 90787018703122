import { useCallback, useState, type FC } from 'react';
import { Navigate, useNavigate } from 'react-router';
import styled from 'styled-components';

import { Page } from '@pages/Page';
import { BookerForm, type BookerFormSubmitParams } from '@components/features/bookerForm/BookerForm';
import { BrandLogo } from '@components/brand/BrandLogo';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { OrderInfoErrorPage } from '@pages/errors/OrderInfoErrorPage';
import { SpanBold, StyledParagraph } from '@components/ui/Typography';
import { GiftLabel } from '@pages/gifting/Gifting/components/giftLabel/GiftLabel';
import { ColumnForty, ColumnSixty } from '@config/styling/Columns';
import { queriesForTheme } from '@config/styling/breakPoints';
import { StyledWrapper } from '@config/styling/styledWrapper';
import { BookerMode } from '@hooks/useBookerMode';
import { useOrderId } from '@hooks/useOrderId';
import { useTranslations } from '@locale/useTranslations';
import {
  bookDeliveryEndpoint,
  bookDeliveryParamsSchema,
} from '@data/api/endpoints/v3/my_toshi/orders/[id]/delivery_journeys';
import { useGetOrder } from '@data/hooks/useGetOrder';
import { useBookerMutation } from '@data/hooks/useBookerMutation';

import { translations } from './translations/index';

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${queriesForTheme.isDesktop} {
    max-width: 740px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${queriesForTheme.isDesktop} {
    max-width: 740px;
  }
`;

const PaddingContainer = styled.div`
  padding-bottom: 20px;
`;

export const Gifting: FC = () => {
  const navigate = useNavigate();
  const { translationsContent, getTranslationWithValues } = useTranslations(translations);

  const { id } = useOrderId();

  const { data: order, error, mutate: mutateOrder } = useGetOrder(id);

  const { trigger } = useBookerMutation({
    url: bookDeliveryEndpoint(id),
    reqSchemaConfig: {
      schema: bookDeliveryParamsSchema,
      schemaName: 'BookDeliveryParams',
    },
  });

  const [submitError, setSubmitError] = useState(false);

  const onSubmit = useCallback(
    async (data: BookerFormSubmitParams) => {
      const scheduleOrderResponse = await trigger({
        ...data,
        deliveryJourney: {
          deliverySlotId: data.deliverySlotId,
          deliveryDate: data.deliveryDate,
        },
        address: {
          line1: data.line1,
          line2: data.line2,
          cityTown: data.city,
          postcodeZipcode: data.postcode,
        },
        gifted: data.gifted,
      });

      if (!scheduleOrderResponse) {
        setSubmitError(true);
      } else {
        if (data.deliveryDate) {
          navigate(`/orders/${id}`);
        }
      }
    },
    [id, trigger, navigate],
  );

  if (error && Object.keys(error).length > 0) {
    return <OrderInfoErrorPage />;
  }

  if (!order) {
    return <LoadingSpinner />;
  }

  if (order.isScheduled()) {
    return <Navigate replace to={`/orders/${id}`} />;
  }

  if (!order.isGifted()) {
    return <Navigate replace to={`/orders/${id}/book/delivery`} />;
  }

  return (
    <Page>
      <BrandLogo mobile brandName={order.brandName} />
      <StyledWrapper>
        <ColumnForty>
          <LeftContainer>
            <BrandLogo brandName={order.brandName} brandLogoUrl={order.brandLogoUrl} />
          </LeftContainer>
        </ColumnForty>
        <ColumnSixty>
          <RightContainer>
            <PaddingContainer>
              <GiftLabel
                giftMessage={order.giftMessage}
                giftSender={order.giftSender.fullName}
                brandName={order.brandName}
              />
            </PaddingContainer>
            <PaddingContainer>
              <StyledParagraph $size="xl" $noMargin $bold>
                {getTranslationWithValues(translationsContent.customerAddressorHello, [order.giftReceiver.fullName])}
              </StyledParagraph>
            </PaddingContainer>
            <PaddingContainer>
              <StyledParagraph $size="md" $noMargin>
                {translationsContent.pleaseBookSentencePt1} <SpanBold>{order.brandName}</SpanBold>{' '}
                {translationsContent.pleaseBookSentencePt2}{' '}
                {order.locationCode === 'NYC'
                  ? translationsContent.toshiAssistantSentenceNYC
                  : translationsContent.toshiAssistantSentenceLDN}
              </StyledParagraph>
            </PaddingContainer>
            <BookerForm
              mode={BookerMode.delivery}
              order={order}
              onSubmit={onSubmit}
              submitError={submitError}
              mutateOrder={mutateOrder}
            />
          </RightContainer>
        </ColumnSixty>
      </StyledWrapper>
    </Page>
  );
};
