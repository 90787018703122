import { type FC } from 'react';

import { DhlOrderInfo } from '@pages/orders/checkout/dhlOrderInfo/DhlOrderInfo';
import { Column, Separator, WhiteCard } from '@components/ui/Containers';
import { StyledParagraph } from '@components/ui/Typography';
import { useTranslations } from '@locale/useTranslations';
import { type Order } from '@data/models/Order';

import { translations } from './translations';
import { DeliveryInfo } from './DeliveryInfo';
import { OrderNumber } from './OrderNumber';
import { OrderServices } from './OrderServices';
import { type Address } from '@data/api/endpoints/shared/addressDTO';
import { ShippingFrom } from './ShippingFrom';

interface OrderInfoCard {
  order: Order;
}

export const OrderInfoCard: FC<OrderInfoCard> = ({ order }) => {
  const { translationsContent } = useTranslations(translations);
  let deliveryAddress: Address | undefined;

  if (order.mostRecentJourney) {
    deliveryAddress = order.isLastJourneyReturn
      ? order.mostRecentJourney.startAddress
      : order.mostRecentJourney.endAddress;
  } else {
    deliveryAddress = order.unscheduledOrderAddress!;
  }

  if (order.dhl) return <DhlOrderInfo card order={order} />;

  return (
    <WhiteCard>
      <Column $gap={2} $noPadding>
        <StyledParagraph $noMargin $bold $size={6} $center>
          {translationsContent.yourOrder}
        </StyledParagraph>
        <Separator />
        {order.shippingFrom && <ShippingFrom shippingFrom={order.shippingFrom} />}
        <OrderNumber brandName={order.brandName} orderNumber={order.orderNumber} translations={translationsContent} />
        {deliveryAddress && (
          <DeliveryInfo
            translations={translationsContent}
            deliveryAddress={deliveryAddress}
            isReturnJourney={order.isScheduled() ? order.isLastJourneyReturn : order.multiPartyBooking}
          />
        )}
        {!order.isLastJourneyReturn && order.hasStoreServices && order.hasServices && (
          <OrderServices order={order} translationsContent={translationsContent} />
        )}
      </Column>
    </WhiteCard>
  );
};
