{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "giftReceivedSentence": "would like to send you a gift from {0} via TOSHI",
    "readMessageLink": "Read your message",
    "giftIconAlt": "parcel",
    "arrangeYourDelivery": "Arrange your delivery",
    "giftMessageModalContentLabel": "Modal showing your gift message"
  }
}
