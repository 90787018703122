import styled from 'styled-components';

import { Checkbox } from '@components/ui/formComponents/FormComponents';
import { StyledParagraphMedium, StyledParagraphGrey } from '@components/ui/Typography';
import { IndentedColumnWithLeadingComponent } from '@components/ui/IndentedColumnWithLeadingComponent';

import { type servicesReducerAction } from './BookerForm';

import { useBookerFormTranslations } from './useBookerFormTranslations';

const ServiceWrapper = styled.div<{ serviceName: string; hidden: boolean }>`
  flex-direction: column;
  margin-block-end: 5px;
  ${(props) => (props.serviceName === 'Inspire Me' ? 'padding-top: 16px;' : '')}
  ${(props) => (props.hidden ? 'display: none;' : 'display: flex;')}

  &:last-child {
    margin-block-end: 0;
  }
`;
const DescriptionParagraph = styled(StyledParagraphMedium)`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${(props) => props.theme.fontSizeMedium};
`;
const FadedParagraphMedium = styled(StyledParagraphMedium)`
  margin-left: 40px;
  color: ${(props) => props.theme.fontColorFaded};
`;
const ExtraTextArea = styled.textarea`
  box-sizing: content-box;
  min-width: 50%;
  min-height: 2em;
  margin-top: 16px;
  margin-left: 40px;
  padding: 16px 24px;
  border-radius: 5px;
  background-color: #fff;
  font-size: ${(props) => props.theme.fontSizeInput};
`;

interface ServiceProps {
  serviceName: string;
  serviceDescription: string;
  extraText?: string;
  orderScheduled: boolean;
  enabled: boolean;
  updateServiceState: (enabled: boolean, text?: string) => void;
  isLastItem?: boolean;
  extraTextPlaceholder?: string;
}
const Service = ({
  serviceName,
  serviceDescription,
  extraText,
  orderScheduled,
  enabled,
  updateServiceState,
  extraTextPlaceholder,
}: ServiceProps): JSX.Element | null => {
  if (orderScheduled && !enabled) {
    return null;
  }
  return (
    /* Disable & hide Inspire Me service until re-reviewing the service */
    <ServiceWrapper serviceName={serviceName} hidden={serviceName === 'Inspire Me'}>
      <IndentedColumnWithLeadingComponent
        leadingComponent={
          <Checkbox
            id={serviceName}
            checked={enabled}
            onChange={orderScheduled ? undefined : () => updateServiceState(!enabled, extraText)}
            readOnly={orderScheduled}
            style={{ marginBlockStart: '0.25rem' }}
          />
        }
        title={<StyledParagraphGrey bold>{serviceName}</StyledParagraphGrey>}
        content={<DescriptionParagraph>{serviceDescription}</DescriptionParagraph>}
        rowGap={0.75}
        columnGap={0.5}
      />
      {serviceName === 'Inspire Me' && (
        <>
          {extraText && orderScheduled ? <FadedParagraphMedium>{`"${extraText}"`}</FadedParagraphMedium> : null}
          {!orderScheduled && enabled && (
            <>
              <ExtraTextArea
                placeholder={extraTextPlaceholder}
                rows={5}
                onChange={(e) => updateServiceState(enabled, e.target.value)}
              />
            </>
          )}
        </>
      )}
    </ServiceWrapper>
  );
};

const ServiceList = styled.div`
  margin-top: 20px;
`;

interface ServicesProps {
  orderScheduled: boolean;
  servicesState: {
    dropoff: {
      enabled: boolean;
    };
    waitAndTry: {
      enabled: boolean;
    };
    inspireMe: {
      enabled: boolean;
      text?: string;
    };
  };
  isGiftedOrder?: boolean;
  updateServiceState: (action: servicesReducerAction) => void;
  storeServices: {
    waitAndTry: boolean;
    inspireMe: boolean;
  };
}
export const Services = ({
  orderScheduled,
  servicesState,
  updateServiceState,
  isGiftedOrder,
  storeServices,
}: ServicesProps): JSX.Element | null => {
  const { translationsContent } = useBookerFormTranslations();

  if (isGiftedOrder && !orderScheduled) {
    return null;
  }

  if (orderScheduled && !servicesState.waitAndTry.enabled && !servicesState.inspireMe.enabled) {
    return null;
  }

  return (
    <div>
      <StyledParagraphMedium bold>{translationsContent.yourToshiAssistantWillHelpWith}</StyledParagraphMedium>
      <ServiceList>
        {storeServices.waitAndTry && (
          <Service
            serviceName={translationsContent.waitAndTry}
            serviceDescription={translationsContent.toshiAssistantWaitWhilstDecide}
            orderScheduled={orderScheduled}
            enabled={servicesState.waitAndTry.enabled}
            updateServiceState={(enabled) => updateServiceState({ enabled, service: 'waitAndTry' })}
          />
        )}
        {storeServices.inspireMe && (
          <Service
            serviceName={translationsContent.inspireMe}
            serviceDescription={translationsContent.inspireMeDescription}
            orderScheduled={orderScheduled}
            extraText={servicesState.inspireMe.text}
            enabled={servicesState.inspireMe.enabled}
            updateServiceState={(enabled, extraText) =>
              updateServiceState({ enabled, service: 'inspireMe', text: extraText })
            }
            extraTextPlaceholder={translationsContent.inspireMeExtraTextPlaceholder}
          />
        )}
      </ServiceList>
    </div>
  );
};
