import { type FC, type PropsWithChildren, type ReactElement, useState } from 'react';

import { Column, Row } from '@components/ui/Containers';
import { EditButton } from '@components/ui/buttons/EditButton';
import { StyledParagraph } from '@components/ui/Typography';

export const UpgradeFormSection: FC<
  PropsWithChildren<{
    complete: boolean;
    title: string;
    editable: boolean;
    completeChildren: ReactElement;
  }>
> = ({ complete, title, editable, children, completeChildren }) => {
  const [showEdit, setshowEdit] = useState(false);

  return (
    <Column $bordered as="article" $padding={1.5} style={{ backgroundColor: 'var(--colour-white)' }}>
      <Row $noPadding style={{ justifyContent: 'space-between' }} as="header">
        <StyledParagraph bold $size={4} $noMargin>
          {title}
        </StyledParagraph>
        {complete && editable && <EditButton onClick={() => setshowEdit((prev) => !prev)} />}
      </Row>
      {complete && !showEdit ? completeChildren : children}
    </Column>
  );
};
