import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { type ToastService } from './ToastService.interface';

export const ToastServiceImpl: ToastService = {
  showSuccessToast(content, style) {
    return toast.success(content, {
      autoClose: 5_000,
      hideProgressBar: true,
      position: 'top-right',
      theme: 'colored',
      style,
    });
  },
  showErrorToast(content, style) {
    return toast.error(content, {
      autoClose: 5_000,
      hideProgressBar: true,
      position: 'top-right',
      style,
    });
  },

  hideToast(toastId) {
    toast.done(toastId);
  },
};
