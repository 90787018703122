import { useBookerMode } from '@hooks/useBookerMode';
import { useTranslations } from '@locale/useTranslations';

import { deliveryTranslations, returnTranslations } from './translations/index';

export function useBookerFormTranslations() {
  const { isDeliveryMode } = useBookerMode();

  return useTranslations(isDeliveryMode ? deliveryTranslations : returnTranslations);
}
