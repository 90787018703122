import { type IContact } from '@data/api/endpoints/shared/contactDTO';

import { Base } from './Base';

export class Contact extends Base<IContact> implements IContact {
  contactNumber?: string;
  email!: string;
  firstName!: string;
  id!: number;
  lastName!: string;
  title?: string;

  get fullName(): string {
    let fullName = '';

    if (this.title) fullName += `${this.title} `;
    if (this.firstName) fullName += `${this.firstName} `;
    if (this.lastName) fullName += `${this.lastName}`;

    return fullName;
  }

  override toString() {
    return this.fullName;
  }
}
