import { z } from 'zod';

import { zodNonEmptyString } from '@utils/helpers/validation';
import { mapKeysToSnake } from '@data/transformers/objects/mapKeysToSnake';
import { transformDTO } from '@data/transformers/responses';

import { v3MyToshiBaseUrl } from '../../baseUrl';

export const createDhlOrder = `${v3MyToshiBaseUrl}/dhl/orders`;

export const createDhlOrderParamsSchema = z
  .object({
    encryptedParams: zodNonEmptyString(),
  })
  .transform(mapKeysToSnake);

export type CreateDhlOrderParams = z.input<typeof createDhlOrderParamsSchema>;

export const createDhlOrderDTOSchema = z
  .object({
    customer_id: zodNonEmptyString(),
    order_id: zodNonEmptyString(),
    auth_token: zodNonEmptyString(),
  })
  .transform(transformDTO);

export type CreateDhlOrderDTO = z.input<typeof createDhlOrderDTOSchema>;
export type CreateDhlOrder = z.output<typeof createDhlOrderDTOSchema>;
