import { useEffect, useRef } from 'react';
import { useScript } from 'usehooks-ts';

import { getEnvironment } from '@utils/helpers/getEnvironment';

declare global {
  interface Window {
    SessionRewindConfig: unknown;
  }
}

const sessionRewindScriptUrl = 'https://rec.sessionrewind.com/srloader.js';

export const useSessionRewind = () => {
  const productionRef = useRef<boolean>(getEnvironment() === 'production');

  useEffect(() => {
    if (productionRef.current) {
      window.SessionRewindConfig = {
        apiKey: 'ur1AJZwnSp5vHE5YVgeBX5Y2pAVSQeXK9hCwTPyf',
        startRecording: true,
      };
    }
  }, []);

  useScript(productionRef.current ? sessionRewindScriptUrl : null);
};
