{
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "language": "en-GB",
  "translations": {
    "checkingPaymentStatus": "Checking payment status",
    "checkoutInfo": {
      "bullet1": "Reschedule as many times as you want",
      "bullet2": "Get your parcels delivered on weekends",
      "bullet3": "2 delivery attempts included",
      "bullet4": "Learn more about TOSHI",
      "bullet5": "T&Cs"
    },
    "checkoutSubtitle": "Hi, {0}, Thank you for choosing TOSHI.",
    "checkoutTitle": "On Your Time: Upgrade to a Scheduled Delivery",
    "collectionAddress": "Collection address",
    "deliveryAddress": "Delivery address",
    "deliveryAlreadyUpgraded": "Your delivery has already been upgraded",
    "deliveryUpgraded": "Your delivery has been upgraded successfully",
    "enterPaymentDetails": "Enter payment details",
    "from": "From",
    "inPartnershipWith": "In partnership with",
    "oneHourWindow": "One-hour delivery window",
    "oneHourWindowPrice": "£10",
    "pageTitle": "Checkout",
    "paymentFailed": "Your payment was unsuccessful, please try again",
    "selectService": "Select Service",
    "thanksForUpgrading": "Thanks for opting to upgrade with TOSHI - please sample text goes here explaining the value of adding in TOSHI. Can probably include 1-3 sentences here to engage the customer.",
    "twoHourWindow": "Two-hour delivery window",
    "twoHourWindowPrice": "£5",
    "upgradeHeader": "Choose Your Delivery Date & Time",
    "yourOrder": "YOUR ORDER DETAILS"
  }
}
