import { type FC } from 'react';
import styled from 'styled-components';

import { StyledParagraph } from './Typography';

const StyledLoadingSpinnerContainer = styled.div`
  display: flex;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0 0 0 / 30%);
  gap: 1rem;
`;

const StyledLoadingSpinner = styled.div`
  --border-size: 0.2rem;

  &,
  &::after {
    width: 8em;
    height: 8em;
    border-radius: 50%;
  }

  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  border: var(--border-size) solid var(--colour-dark-grey);
  border-left: var(--border-size) solid var(--colour-black);
  background-color: transparent;
  font-size: 0.6rem;
  text-indent: -9999em;

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingSpinner: FC<{ copy?: string }> = ({ copy }) => (
  <StyledLoadingSpinnerContainer aria-label="Loading spinner">
    <StyledLoadingSpinner />
    <StyledParagraph $size="xl" $noMargin>
      {copy || <br />}
    </StyledParagraph>
  </StyledLoadingSpinnerContainer>
);
