import styled from 'styled-components';
import { type FC } from 'react';

import chevronDown from '@assets/icons/chevronDown.svg';
import clock from '@assets/icons/clock.svg';
import calendar from '@assets/icons/calendar.svg';
import tick from '@assets/images/tick.svg';

import { SelectMode } from './SelectMode.enum';

export const StyledInput = styled.input`
  box-sizing: border-box;
  margin: 0;
  padding: 12px 24px 14px;
  border: 1px solid ${(props) => props.theme.blackColor};
  background: ${(props) => props.theme.whiteColor};

  &:disabled {
    background: ${(props) => props.theme.lightGrey};
    color: ${(props) => props.theme.ghostGrey};
  }
`;

export const StyledSelect = styled.select<{ selected: string; mode: SelectMode }>`
  appearance: none;
  padding: 0.75rem;
  padding-inline-end: 2rem;
  border: 1px solid var(--colour-black);
  border-radius: 4px;
  background:
    url('${chevronDown}') no-repeat,
    url('${(props) => (props.mode === SelectMode.Date ? calendar : clock)}') no-repeat;
  background-color: var(--colour-white);
  background-position:
    calc(100% - 0.5rem) center,
    calc(0.75em) center;
  font-family: FontAwesome, MarkOT;
  font-size: var(--font-size-3);
  font-weight: 300;
  ${(props) => (props.selected ? '' : `color: ${props.theme.ghostGrey};`)}
  text-indent: 2rem;
  text-overflow: ellipsis;

  &:disabled {
    background-color: var(--colour-light-grey);
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox', tabIndex: 0 })`
  all: unset;

  --size: 1rem;

  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  transition: background-color 0.2s ease-in-out;
  border: 1px solid var(--colour-black);
  border-radius: 4px;
  background: url('${tick}') no-repeat;
  background-color: var(--colour-white);
  background-position: center;
  background-size: 75%;
  cursor: pointer;
  user-select: none;

  &:checked {
    background-color: var(--colour-black);
  }
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  visibility: hidden;
  appearance: none;
  margin: 0;

  --colour-border: var(--colour-black);
  --colour-dot: var(--colour-border);
  --size: 1rem;

  &::before {
    content: '';
    display: block;
    visibility: visible;
    width: var(--size);
    height: var(--size);
    border: 1px solid var(--colour-border);
    border-radius: 50%;
    background: none;
    pointer-events: none;
  }

  &:checked::before {
    background: radial-gradient(var(--colour-dot) 0%, var(--colour-dot) 40%, var(--colour-white) 40%);
  }
`;

export interface IOption {
  value: string;
  content: string;
}

export const Options: FC<{ options: IOption[] }> = ({ options }) =>
  options.map(({ value, content }) => (
    <option key={value} value={value}>
      {content}
    </option>
  ));
