import { type Jsonify, type CamelCasedProperties } from 'type-fest';
import { z } from 'zod';

import { transformDTO } from '@data/transformers/responses';

import { addressDTOSchema } from './addressDTO';
import { zodNonEmptyString } from '@utils/helpers/validation';

const journeyStatusSchema = z.enum([
  'abandoned_ecom',
  'pending',
  'scheduled',
  'arrived_at_toshi_depot',
  'en_route_to_pickup',
  'arrived_at_pickup',
  'finished_pickup',
  'en_route_to_dropoff',
  'arrived_at_dropoff',
  'finished_dropoff',
  'unable_to_dropoff',
  'unable_to_pickup',
  'completed',
  'cancelled',
]);

export type JourneyTransitionStatus = z.infer<typeof journeyStatusSchema>;

export const journeyTransitionDTOSchema = z.object({
  created_at: z.string().datetime(),
  id: z.number(),
  most_recent: z.boolean(),
  sort_key: z.number(),
  to_state: journeyStatusSchema,
  updated_at: z.string().datetime(),
});

export type JourneyTransitionDTO = z.infer<typeof journeyTransitionDTOSchema>;
export type JourneyTransition = CamelCasedProperties<JourneyTransitionDTO>;

export const journeyDTOSchema = z
  .object({
    created_at: z.coerce.date(),
    delivery_date: z.string().nullish(),
    delivery_slot: z
      .object({
        id: z.number(),
        start_time: z.string(),
        end_time: z.string(),
      })
      .nullish(),
    end_address: addressDTOSchema,
    end_datetime: z.coerce.date().nullish(),
    id: zodNonEmptyString(),
    in_terminal_state: z.boolean(),
    instant_return: z.boolean(),
    journey_transitions: z
      .array(journeyTransitionDTOSchema)
      .transform((array) => [...array].sort((first, second) => first.sort_key - second.sort_key)),
    start_address: addressDTOSchema,
    start_datetime: z.coerce.date(),
    status: journeyStatusSchema,
    stripe_checkout_secret: z.string().nullish(),
    type: z.union([z.literal('DeliveryJourney'), z.literal('ReturnJourney')]),
  })
  .transform(transformDTO);

export type JourneyDTO = Jsonify<z.input<typeof journeyDTOSchema>>;
export type Journey = z.output<typeof journeyDTOSchema>;
