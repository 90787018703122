import styled, { type CSSProperties } from 'styled-components';
import { theme } from '@config/styling/theme';
import { queriesForTheme } from '@config/styling/breakPoints';

export interface BoldProp {
  $bold?: boolean;
  bold?: boolean;
}

export interface TypographyProp extends BoldProp {
  invertColor?: boolean;
  $uppercase?: boolean;
  center?: boolean;
  $noMargin?: boolean;
  $align?: CSSProperties['textAlign'];
  $center?: boolean;
}

type typographyFn = (props: TypographyProp) => string;

const uppercase: typographyFn = (props) => (props.$uppercase ? 'text-transform: uppercase;' : '');
const bold: typographyFn = (props) => (props.bold || props.$bold ? 'font-weight: 600;' : '');
const center: typographyFn = (props) => (props.center || props.$center ? 'text-align: center;' : '');
const align: typographyFn = (props) => (props.$align ? `text-align: ${props.$align};` : '');
const noMargin: typographyFn = (props) => (props.$noMargin ? 'margin: 0;' : '');

const typographyProps: typographyFn = (props) =>
  [uppercase, bold, center, align, noMargin].map((fn) => fn(props)).join('');

export const StyledH1 = styled.h1<Pick<TypographyProp, '$center' | '$align'>>`
  background: transparent;
  font-size: var(--font-size-7);
  text-align: left;
  ${align}
  ${center}
`;

export const StyledH2 = styled.h2<Pick<TypographyProp, '$center' | '$align'>>`
  background: transparent;
  font-size: var(--font-size-6);
  font-weight: 600;
  text-transform: uppercase;
  ${align}
  ${center}
`;

export const StyledH3 = styled.h3<TypographyProp>`
  background: transparent;
  color: ${(props) => (props.invertColor ? theme.invertColor : theme.color)};
  font-size: var(--font-size-2);
  font-weight: 600;
  text-align: left;
  ${uppercase}

  ${queriesForTheme.isDesktop} {
    font-size: ${(props) => props.theme.fontSizeSubTitle};
  }
`;

export const SpanBold = styled.span`
  font-size: inherit;
  font-weight: 600;
`;

type SizeProp = { $size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 };

export const StyledParagraph = styled.p<TypographyProp & SizeProp>`
  margin-top: 22px;
  margin-bottom: 16px;
  background: transparent;
  color: ${(props) => (props.invertColor ? theme.invertColor : theme.color)};
  font-size: var(
    ${({ $size }) => {
      if (typeof $size === 'number') return `--font-size-${$size}`;

      switch ($size) {
        case 'xxs':
          return '--font-size-1';
        case 'xs':
          return '--font-size-2';
        case 'sm':
          return '--font-size-3';
        case 'md':
          return '--font-size-4';
        case 'lg':
          return '--font-size-5';
        case 'xl':
          return '--font-size-6';
        case 'xxl':
          return '--font-size-7';
        case 'xxxl':
          return '--font-size-8';
      }
    }}
  );
  ${typographyProps}
`;

StyledParagraph.defaultProps = {
  $size: 1,
};

export const StyledParagraphMedium = styled.p<Pick<TypographyProp, '$noMargin' | 'bold' | '$bold'>>`
  margin-top: 22px;
  margin-bottom: 16px;
  background: transparent;
  font-size: var(--font-size-4);
  ${bold}
  ${noMargin}
`;

export const StyledParagraphLarge = styled.p<Pick<TypographyProp, '$noMargin' | 'bold' | '$bold'>>`
  margin-bottom: 12px;
  background: transparent;
  font-size: var(--font-size-6);
  font-weight: ${(props) => props.style?.fontWeight};
  ${bold}
  ${noMargin}

  ${queriesForTheme.isDesktop} {
    font-size: var(--font-size-8);
  }
`;

export const StyledParagraphSmall = styled.p`
  margin-left: 40px;
  background: transparent;
  font-size: var(--font-size-1);
`;

export const StyledParagraphGrey = styled(StyledParagraphMedium)`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.mainGrey};
  ${(props) => (props.bold || props.$bold ? 'font-weight: 700;' : '')}
`;

export const StyledParagraphSubHeader = styled(StyledParagraphMedium)`
  margin-top: 0;
  margin-bottom: 8px;
  margin-left: 40px;
  font-size: var(--font-size-4);
`;

export const FadedParagraphLarge = styled(StyledParagraphLarge)`
  color: ${(props) => props.theme.fontColorFaded};
`;

export const StyledParagraphLargeGrey = styled(StyledParagraphGrey)`
  font-size: var(--font-size-6);
`;
