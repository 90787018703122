import { type FC } from 'react';
import { generatePath, useNavigate } from 'react-router';

import { OrderInfoCard } from '@components/features/OrderInfoCard/OrderInfoCard';
import { Page } from '@pages/Page';
import { ToshiBrand } from '@components/brand/ToshiBrand';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { OrderInfoErrorPage } from '@pages/errors/OrderInfoErrorPage';
import { Column, ResponsiveGridWithHeader } from '@components/ui/Containers';
import { SecondaryCTA } from '@components/ui/buttons/buttons';
import { useMyToshiDocumentTitle } from '@hooks/useMyToshiDocumentTitle';
import { useOrderId } from '@hooks/useOrderId';
import { useURLToast } from '@hooks/useURLToast';
import { useTranslations } from '@locale/useTranslations';
import { type Order } from '@data/models/Order';
import { useGetOrder } from '@data/hooks/useGetOrder';
import { RoutePaths } from 'src/router';

import { DeliveryDetails } from './DeliveryDetails/DeliveryDetails';
import { ToshiAssistantUpdates } from './ToshiAssistantUpdates';
import { translations, type translationsType } from './translations';

interface FirstSectionProps {
  order: Order;
  translationsContent: translationsType;
  mutate: () => void;
}

const FirstSection: FC<FirstSectionProps> = ({ order, translationsContent, mutate }) => {
  const navigate = useNavigate();

  function rescheduleButtonText(): string {
    if (order.unableToComplete) {
      return order.isLastJourneyReturn ? translationsContent.rebookCollection : translationsContent.rebookDelivery;
    } else {
      return order.isLastJourneyReturn
        ? translationsContent.rescheduleCollection
        : translationsContent.rescheduleDelivery;
    }
  }

  function renderSecondaryCTA() {
    let navPath: string = '',
      buttonText: string = '';
    const orderPathParams = { id: order.id };
    if (order.cancelled) {
      return null;
    } else if (order.canBeRescheduled) {
      navPath = generatePath(
        order.isLastJourneyReturn ? RoutePaths.orderRescheduleReturn : RoutePaths.orderRescheduleDelivery,
        orderPathParams,
      );
      buttonText = rescheduleButtonText();
    } else if (!order.isScheduled()) {
      if (order.isGifted()) {
        navPath = generatePath(RoutePaths.gifting, orderPathParams);
        buttonText = translationsContent.bookYourGiftDelivery;
      } else {
        navPath = generatePath(
          order.multiPartyBooking ? RoutePaths.orderBookReturn : RoutePaths.orderBookDelivery,
          orderPathParams,
        );
        buttonText = order.multiPartyBooking ? translationsContent.bookCollection : translationsContent.bookDelivery;
      }
    }

    if (navPath && buttonText) return <SecondaryCTA onClick={() => navigate(navPath)}>{buttonText}</SecondaryCTA>;

    return null;
  }

  return (
    <Column as="article" $noPadding $gap={2}>
      <DeliveryDetails
        order={order}
        mostRecentJourney={order.isLastJourneyReturn ? order.mostRecentReturnJourney : order.mostRecentDeliveryJourney}
        translations={translationsContent}
      />

      {renderSecondaryCTA()}

      <ToshiAssistantUpdates
        order={order}
        transitions={
          order.isLastJourneyReturn
            ? order.mostRecentReturnJourney?.journeyTransitions
            : order.mostRecentDeliveryJourney?.journeyTransitions
        }
        refresh={mutate}
      />
    </Column>
  );
};

export const OrderDetails: FC = () => {
  const { translationsContent } = useTranslations(translations);

  useMyToshiDocumentTitle(translationsContent.pageTitle);

  const { id } = useOrderId();

  const { data: order, error, mutate } = useGetOrder(id);

  useURLToast();

  if (error) {
    return <OrderInfoErrorPage />;
  }

  if (!order) {
    return <LoadingSpinner />;
  }

  return (
    <Page>
      <ResponsiveGridWithHeader>
        <ToshiBrand
          coBrandingEnabled={order?.coBrandingEnabled}
          brandName={order?.brandName}
          brandLogoUrl={order?.brandLogoUrl}
        />
        <FirstSection
          order={order}
          translationsContent={translationsContent}
          mutate={() => mutate(undefined, { revalidate: true })}
        />
        <aside>{<OrderInfoCard order={order} />}</aside>
      </ResponsiveGridWithHeader>
    </Page>
  );
};
