import useSWR, { type SWRConfiguration } from 'swr';
import useSWRMutation, { type SWRMutationConfiguration } from 'swr/mutation';
import { type ZodError, type ZodTypeAny, type z } from 'zod';

import { mutator, type FetcherError } from '../api/fetchers';
import { handleApiError } from '../api/handleApiError';
import { HTTPMethods } from '../api/helpers';
import { type ValidateConfig } from '../api/validator';
import { useNavigateUnauthorised } from './useNavigateUnauthorised';

export interface UseMutationParamsBase<T extends ZodTypeAny, R extends ZodTypeAny> {
  url: string;
  reqSchemaConfig: ValidateConfig<T>;
  resSchemaConfig: ValidateConfig<R>;
  method?: HTTPMethods;
  onError?: (err: FetcherError | ZodError | Error) => unknown;
}

export interface UseMutationParams<T extends ZodTypeAny, R extends ZodTypeAny> extends UseMutationParamsBase<T, R> {
  options?: Partial<SWRMutationConfiguration<z.output<R>, FetcherError | ZodError | Error, string, z.input<T>>>;
}

/**
 *
 * @desc
 * Use for mutations that need to be triggered manually
 */
export const useMutation = <T extends ZodTypeAny, R extends ZodTypeAny>({
  url,
  reqSchemaConfig,
  resSchemaConfig,
  method = HTTPMethods.POST,
  onError,
  options,
}: UseMutationParams<T, R>) => {
  const navigateUnauthorised = useNavigateUnauthorised();

  type Params = z.input<T>;
  type Response = z.output<R>;

  return useSWRMutation<Response, FetcherError | ZodError | Error, string, Params>(
    url,
    async (url, { arg }) => {
      try {
        const data = await mutator(url, {
          method,
          resSchemaConfig,
          reqSchemaConfig,
          reqBody: arg,
        });

        return data;
      } catch (err: FetcherError | ZodError | Error | unknown) {
        await handleApiError(err, navigateUnauthorised, onError);
      }
    },
    options,
  );
};

interface UseAutoMutationParams<T extends ZodTypeAny, R extends ZodTypeAny> extends UseMutationParamsBase<T, R> {
  params: z.input<T>;
  options?: Partial<SWRConfiguration>;
}

/**
 *
 * @desc
 * Same as useMutation but fetches automatically and uses swr cache
 */
export const useAutoMutation = <T extends ZodTypeAny, R extends ZodTypeAny>({
  url,
  reqSchemaConfig,
  resSchemaConfig,
  method = HTTPMethods.POST,
  params,
  onError,
  options,
}: UseAutoMutationParams<T, R>) => {
  const navigateUnauthorised = useNavigateUnauthorised();

  type Response = z.output<R>;
  type CacheKey = {
    url: string;
    params: typeof params;
  };

  return useSWR<Response, FetcherError | ZodError | Error, CacheKey>(
    { url, params },
    async ({ url }) => {
      try {
        const data = await mutator(url, {
          method,
          resSchemaConfig,
          reqSchemaConfig,
          reqBody: params,
        });

        return data;
      } catch (err: FetcherError | ZodError | Error | unknown) {
        await handleApiError(err, navigateUnauthorised, onError);
      }
    },
    options,
  );
};
