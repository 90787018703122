import { Navigate, generatePath, useSearchParams } from 'react-router-dom';

import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { FullPageCenteredColumn } from '@components/ui/Containers';
import { StyledParagraph } from '@components/ui/Typography';
import { SearchParams } from '@utils/helpers/searchParams';
import { useOrderId } from '@hooks/useOrderId';
import { createToastState } from '@hooks/useURLToast';
import { useTranslations } from '@locale/useTranslations';
import {
  StripePaymentStatus,
  ToshiPaymentStatus,
  checkStripeStatus,
  checkStripeStatusDTOSchema,
} from '@data/api/endpoints/v3/my_toshi/stripe/checkout_sessions/[id]';
import { useGetData } from '@data/hooks/useGetData';
import { RoutePaths } from 'src/router';

import { translations } from '../translations';

export const Status = () => {
  const { translationsContent } = useTranslations(translations);
  const [searchParams] = useSearchParams();
  const params = useOrderId();

  const stripeSession = searchParams.get(SearchParams.stripeCheckoutId);

  if (!stripeSession) throw new Error('Missing stripe checkout id');

  const { data, isLoading, error } = useGetData({
    key: checkStripeStatus(stripeSession),
    schemaConfig: {
      schema: checkStripeStatusDTOSchema,
      schemaName: 'checkStripeStatusDTOSchema',
    },
    options: {
      revalidateIfStale: true,
      refreshInterval: 500,
    },
  });

  const spinner = <LoadingSpinner copy={translationsContent.checkingPaymentStatus} />;

  if (isLoading) {
    return spinner;
  }

  if (error) throw error;

  switch (data?.stripePaymentStatus) {
    case StripePaymentStatus.noPaymentRequired:
    case StripePaymentStatus.paid: {
      switch (data.toshiPaymentStatus) {
        case ToshiPaymentStatus.paid: {
          const path = generatePath(RoutePaths.order, params);
          const query = createToastState({ type: 'success', message: translationsContent.deliveryUpgraded });
          return <Navigate to={`${path}?${query}`} />;
        }
        case ToshiPaymentStatus.unpaid:
          return spinner;
      }
    }
    // eslint bug with nested switch, this is safe
    // eslint-disable-next-line no-fallthrough
    case StripePaymentStatus.unpaid: {
      return (
        <FullPageCenteredColumn>
          <StyledParagraph $size="xl">{translationsContent.paymentFailed}</StyledParagraph>
        </FullPageCenteredColumn>
      );
    }
  }
};
