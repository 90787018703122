import flow from 'lodash.flow';
import { type Simplify, type CamelCasedPropertiesDeep, type SnakeCasedPropertiesDeep } from 'type-fest';

import { type NullToUndefined, type UndefinedToNull, nullToUndefined, undefinedToNull } from '../nullToUndefined';
import { mapKeysToSnake } from '../objects/mapKeysToSnake';
import { mapKeysToCamel } from '../objects/mapKeysToCamel';

export const transformParams: <T>(arg: T) => Simplify<UndefinedToNull<SnakeCasedPropertiesDeep<T>>> = flow([
  undefinedToNull,
  mapKeysToSnake,
]);

export const transformDTO: <T>(arg: T) => Simplify<NullToUndefined<CamelCasedPropertiesDeep<T>>> = flow([
  nullToUndefined,
  mapKeysToCamel,
]);
