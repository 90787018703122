import { type ZodTypeAny } from 'zod';
import { journeyDTOSchema } from '../api/endpoints/shared/journeyDTO';
import { type UseMutationParams, useMutation } from './useMutation';

export const useBookerMutation = <T extends ZodTypeAny>({
  url,
  reqSchemaConfig,
}: Pick<UseMutationParams<T, typeof journeyDTOSchema>, 'url' | 'reqSchemaConfig'>) =>
  useMutation({
    url,
    reqSchemaConfig,
    resSchemaConfig: {
      schema: journeyDTOSchema,
      schemaName: 'BookerResponse',
    },
  });
