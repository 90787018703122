import { type Context, createContext, useContext, useDebugValue } from 'react';

type defineContextResult<T> = [context: Context<T | undefined>, useContext: () => T];

export function defineContext<T>(name: string): defineContextResult<T> {
  const context = createContext<T | undefined>(undefined);

  const useContextHook = (): T => {
    useDebugValue(`use${name}`);

    const _context = useContext(context);

    if (_context === undefined) {
      throw new Error(`use${name} must be within ${name}Provider`);
    }

    return _context;
  };

  return [context, useContextHook];
}
