import { type FC } from 'react';

import { Column, WhiteCard } from '@components/ui/Containers';
import { StyledParagraph } from '@components/ui/Typography';
import { useTranslations } from '@locale/useTranslations';
import { type Order } from '@data/models/Order';
import { DeliveryInfo } from '@components/features/OrderInfoCard/DeliveryInfo';

import { translations } from '../translations';
import { DhlBanner } from './DhlBanner';
import { WaybillNumber } from './WaybillNumber';

export const DhlOrderInfo: FC<{ order: Order; card?: boolean }> = ({ card = false, order }) => {
  const { translationsContent } = useTranslations(translations);

  const content = (
    <>
      <DhlBanner translations={translationsContent} />
      <WaybillNumber
        translations={translationsContent}
        orderNumber={order.orderNumber}
        brandName={order.onBehalfOfBrandName ?? order.brandName}
      />
      <DeliveryInfo
        translations={translationsContent}
        deliveryAddress={order.unscheduledOrderAddress!}
        isReturnJourney={false}
      />
    </>
  );

  if (card)
    return (
      <WhiteCard>
        <Column $gap={2} $noPadding>
          <StyledParagraph $noMargin $bold $size={6} $center>
            {translationsContent.yourOrder}
          </StyledParagraph>
          {content}
        </Column>
      </WhiteCard>
    );

  return content;
};
