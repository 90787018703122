import styled from 'styled-components';
import { queriesForTheme } from '@config/styling/breakPoints';
import { type FC } from 'react';

import { Row } from '@components/ui/Containers';
import { useTranslations } from '@locale/useTranslations';
import { ToshiURLs } from '@utils/constants/toshiURLs';

import { translations } from './translations';

const FooterContainer = styled.footer`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-top: 2px solid var(--colour-black);
  background-color: var(--colour-background);
  text-align: right;
  gap: 0.5rem;

  ${queriesForTheme.isMobile} {
    grid-auto-flow: row;
    justify-content: center;
    padding: 1rem 2rem;

    p:first-of-type {
      text-align: center;
    }
  }

  p {
    font-size: var(--font-size-3);
    font-weight: 600;
  }

  a {
    font-size: var(--font-size-2);
    text-decoration: none;
  }
`;

export const Footer: FC = () => {
  const {
    translationsContent: { footer },
  } = useTranslations(translations);

  const links = [
    // { link: 'mailto:contact@toshi.co', copy: 'Contact Us' },
    { link: ToshiURLs.terms, copy: footer.termsAndConditions },
    { link: ToshiURLs.privacy, copy: footer.privacyPolicy },
  ];

  return (
    <FooterContainer>
      <p>{footer.brand}</p>
      <Row $noPadding $gap={2} style={{ justifyContent: 'center' }}>
        {links.map(({ link, copy }) => (
          <a key={copy} target="_blank" href={link} rel="noreferrer">
            {copy}
          </a>
        ))}
      </Row>
    </FooterContainer>
  );
};
