import { useLocation } from 'react-router';

interface useBookerModeResult {
  mode: BookerMode;
  isDeliveryMode: boolean;
  isReturnMode: boolean;
}

export enum BookerMode {
  delivery,
  return,
}

export function useBookerMode(): useBookerModeResult {
  const location = useLocation();

  const mode = location.pathname.includes('return') ? BookerMode.return : BookerMode.delivery;

  return {
    isDeliveryMode: mode === BookerMode.delivery,
    isReturnMode: mode === BookerMode.return,
    mode,
  };
}
