{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "customerAddressorHello": "Hello {0},",
    "customerAddressorDear": "Dear {0} {1},",
    "pleaseBookSentencePt1": "Please book a convenient date and time for the delivery of your",
    "pleaseBookSentencePt2": "gift.",
    "toshiAssistantSentenceLDN": "A TOSHI Assistant will deliver your gift to a London address within zones 1-6.",
    "toshiAssistantSentenceNYC": "A TOSHI Assistant will deliver your gift to an NYC address within Manhattan and Brooklyn."
  }
}
