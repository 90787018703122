import { useCallback, type FC } from 'react';
import { Navigate, useNavigate } from 'react-router';

import { Page } from '@pages/Page';
import { BookerForm, type BookerFormSubmitParams } from '@components/features/bookerForm/BookerForm';
import { ToshiBrand } from '@components/brand/ToshiBrand';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { OrderInfoErrorPage } from '@pages/errors/OrderInfoErrorPage';
import { ResponsiveGridWithHeader } from '@components/ui/Containers';
import { StyledParagraph } from '@components/ui/Typography';
import { useBookerMode } from '@hooks/useBookerMode';
import { useOrderId } from '@hooks/useOrderId';
import { useTranslations } from '@locale/useTranslations';
import {
  rescheduleDeliveryEndpoint,
  rescheduleDeliveryParamsSchema,
} from '@data/api/endpoints/v3/my_toshi/orders/[id]/rescheduled_delivery_journeys';
import {
  rescheduleReturnEndpoint,
  rescheduleReturnParamsSchema,
} from '@data/api/endpoints/v3/my_toshi/orders/[id]/rescheduled_return_journeys';
import { useGetOrder } from '@data/hooks/useGetOrder';
import { OrderInfoCard } from '@components/features/OrderInfoCard/OrderInfoCard';

import { translations } from './translations';
import { useBookerMutation } from '@data/hooks/useBookerMutation';
import { useMyToshiDocumentTitle } from '@hooks/useMyToshiDocumentTitle';

export const RescheduleOrder: FC = () => {
  const { translationsContent } = useTranslations(translations);

  const { mode, isDeliveryMode } = useBookerMode();

  useMyToshiDocumentTitle(isDeliveryMode ? translationsContent.pageTitleDelivery : translationsContent.pageTitleReturn);

  const { id } = useOrderId();

  const { data: order, error } = useGetOrder(id);

  const navigate = useNavigate();

  const { trigger: rescheduleDelivery, error: rescheduleDeliveryError } = useBookerMutation({
    url: rescheduleDeliveryEndpoint(id),
    reqSchemaConfig: {
      schema: rescheduleDeliveryParamsSchema,
      schemaName: 'RescheduleDeliveryParams',
    },
  });

  const { trigger: rescheduleReturn, error: rescheduleReturnError } = useBookerMutation({
    url: rescheduleReturnEndpoint(id),
    reqSchemaConfig: {
      schema: rescheduleReturnParamsSchema,
      schemaName: 'RescheduleReturnParams',
    },
  });

  const onSubmit = useCallback(
    async ({ deliveryDate, deliverySlotId, receiveUpdates, gifted }: BookerFormSubmitParams) => {
      const dateSlot = { deliveryDate, deliverySlotId };

      const response = isDeliveryMode
        ? await rescheduleDelivery({ deliveryJourney: { ...dateSlot, receiveUpdates, gifted } }, { throwOnError: true })
        : await rescheduleReturn({ returnJourney: dateSlot }, { throwOnError: true });

      if (!response) {
        throw error;
      } else {
        navigate(`/orders/${id}`);
      }
    },
    [error, id, isDeliveryMode, navigate, rescheduleDelivery, rescheduleReturn],
  );

  if (error && Object.keys(error).length > 0) {
    return <OrderInfoErrorPage />;
  }

  if (!order) {
    return <LoadingSpinner />;
  }

  if (!order.canBeRescheduled) {
    return <Navigate replace to={`/orders/${id}`} />;
  }

  return (
    <Page>
      <ResponsiveGridWithHeader>
        <ToshiBrand
          coBrandingEnabled={order?.coBrandingEnabled}
          brandName={order?.brandName}
          brandLogoUrl={order?.brandLogoUrl}
        />
        <article>
          <StyledParagraph $size="xl" $noMargin $bold>
            {isDeliveryMode ? translationsContent.rescheduleDeliveryHeader : translationsContent.rescheduleReturnHeader}
          </StyledParagraph>
          <BookerForm
            mode={mode}
            order={order}
            onSubmit={onSubmit}
            submitError={
              rescheduleDeliveryError || rescheduleReturnError ? translationsContent.unableToSchedule : false
            }
          />
        </article>
        <OrderInfoCard order={order} />
      </ResponsiveGridWithHeader>
    </Page>
  );
};
