{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "header": {
      "brand": "THE LUXURY OF CONVENIENCE"
    },
    "footer": {
      "brand": "TOSHI - The luxury of convenience",
      "termsAndConditions": "Terms and Conditions",
      "privacyPolicy": "Privacy Policy"
    }
  }
}
