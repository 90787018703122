import { Navigate, generatePath } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { SearchParams } from '@utils/helpers/searchParams';
import {
  createDhlOrder,
  createDhlOrderParamsSchema,
  createDhlOrderDTOSchema,
} from '@data/api/endpoints/v3/my_toshi/dhl/orders';
import { useAutoMutation } from '@data/hooks/useMutation';
import { RoutePaths } from 'src/router';

export const CreateOrder = () => {
  const [searchParams] = useSearchParams();

  const encryptedParams = searchParams.get(SearchParams.encryptedOrderData)?.replace(/ /g, '+');

  if (!encryptedParams) throw Error();

  const { data, isLoading, error } = useAutoMutation({
    url: createDhlOrder,
    reqSchemaConfig: {
      schema: createDhlOrderParamsSchema,
      schemaName: 'CrateDHLOrderParams',
    },
    resSchemaConfig: {
      schema: createDhlOrderDTOSchema,
      schemaName: 'CreateDHLOrderResponse',
    },
    params: { encryptedParams },
  });

  if (isLoading) return <LoadingSpinner copy={'Redirecting'} />;

  if (error) throw error;

  if (data) {
    const { orderId, authToken, customerId } = data;

    const path = generatePath(RoutePaths.orderCheckout, { id: String(orderId) });
    const query = new URLSearchParams({ [SearchParams.authToken]: authToken, [SearchParams.customerId]: customerId });

    return <Navigate to={`${path}?${query}`} />;
  }
};
