import { type FC, type ReactElement } from 'react';
import styled from 'styled-components';
import { IndentedColumnWithLeadingComponent } from '@components/ui/IndentedColumnWithLeadingComponent';

const Icon = styled.img`
  width: 1.75rem;
  height: 1.75rem;
`;

interface Props {
  iconProps: {
    src: string;
    alt: string;
  };
  title: string | ReactElement;
  content?: ReactElement;
}

export const IconSection: FC<Props> = ({ title, content, iconProps: { src, alt } }) => (
  <IndentedColumnWithLeadingComponent title={title} content={content} leadingComponent={<Icon src={src} alt={alt} />} />
);
