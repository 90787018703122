import { z } from 'zod';

import { zodNonEmptyString } from '@utils/helpers/validation';
import { transformDTO } from '@data/transformers/responses';

export const authEndpoint = 'v2/mytoshi/auth';

export const authParamsSchema = z.object({
  code: zodNonEmptyString(),
  id: zodNonEmptyString(),
});

export type AuthParams = z.infer<typeof authParamsSchema>;

export const authDTOSchema = z
  .object({
    ok: z.boolean(),
    user: z.object({
      email: z.string().email(),
      first_name: z.string(),
      jwt: zodNonEmptyString(),
      last_name: z.string(),
      title: z.string().nullish(),
    }),
  })
  .transform(transformDTO);

export type AuthDTO = z.input<typeof authDTOSchema>;

export type Auth = z.output<typeof authDTOSchema>;

export type User = Auth['user'];
