import { type CamelCasedPropertiesDeep, type SetOptional } from 'type-fest';

import { isSnakeCase } from '@utils/helpers/strings';
import { Availability, type IAvailability } from '@data/models/Availability';
import { type AvailabilityDTO } from '@data/api/endpoints/v2/availability/check_available';

import { deepMapKeys } from '../objects/deepMapKeys';
import { toCamel } from '../strings/toCamel';

export function mapAvailabilityDTO(response: AvailabilityDTO): Availability {
  const camelised = deepMapKeys(
    response,
    (key) => (isSnakeCase(key) ? toCamel(key) : key), // date keys are in kebab case and should not be transformed
  ) as SetOptional<CamelCasedPropertiesDeep<AvailabilityDTO>, 'dates'>;

  const { dates } = camelised;

  delete camelised.dates;

  const iAvailability: IAvailability = {
    ...camelised,
    datesSlots: { ...dates },
  };

  return new Availability(iAvailability);
}
