import { z } from 'zod';

import { addressParamSchema } from '@data/api/endpoints/shared/addressParam';
import { deliveryDateSlotParamSchema } from '@data/api/endpoints/shared/deliveryDateDeliverySlotParam';
import { transformParams } from '@data/transformers/responses';

import { v3MyToshiBaseUrl } from '../../baseUrl';

export const rescheduleReturnEndpoint = (orderId: string): string =>
  `${v3MyToshiBaseUrl}/orders/${orderId}/rescheduled_return_journeys` as const;

export const rescheduleReturnParamsSchema = z
  .object({
    address: addressParamSchema.optional(),
    returnJourney: deliveryDateSlotParamSchema,
    toshiUpdates: z.boolean().optional(),
  })
  .transform(transformParams);
