{
  "language": "en-GB",
  "errors": {
    "default": "Something went wrong.",
    "translations": {
      "missing": "MISSING TRANSLATION"
    }
  },
  "translations": {
    "alteration": "Fitting and Pinning",
    "alterationDescription": "Our assistant will adjust your sunglasses to ensure comfort and fit. (On acetate and metallic frames only)",
    "collectionAddress": "Collection Address",
    "deliveryAddress": "Delivery Address",
    "inspireMe": "Inspire Me",
    "inspireMeDescription": "Your TOSHI assistant will bring additional items for you to try (sizes, colors, styles, etc) - pay only for what you keep and we’ll take back unwanted items",
    "orderNumber": "Order number",
    "perfectFit": "Perfect Fit",
    "perfectFitDescription": "Your TOSHI Assistant will be equipped to pin your items. We will assess and determine what adjustments are possible.",
    "selectedToshiServices": "Selected TOSHI services",
    "upDownSize": "Size up / size down",
    "upDownSizeDescription": "Your TOSHI assistant will bring you different sizes to try on and take back unwanted sizes, hassle free",
    "waitAndTry": "Wait & Try",
    "waitAndTryDescription": "Your TOSHI assistant will wait while you decide what to keep, and take back unwanted item(s), hassle free",
    "yourOrder": "YOUR ORDER DETAILS"
  }
}
