import { z } from 'zod';

import { deliveryDateSlotParamSchema } from '@data/api/endpoints/shared/deliveryDateDeliverySlotParam';
import { transformParams } from '@data/transformers/responses';

import { v3MyToshiBaseUrl } from '../../baseUrl';

export const bookReturnEndpoint = (orderId: string) => `${v3MyToshiBaseUrl}/orders/${orderId}/return_journeys` as const;

export const bookReturnParamsSchema = z
  .object({
    returnJourney: deliveryDateSlotParamSchema,
    toshiUpdates: z.boolean().optional(),
  })
  .transform(transformParams);
