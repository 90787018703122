import { z } from 'zod';

import { v3MyToshiBaseUrl } from '../../baseUrl';
import { transformParams } from '@data/transformers/responses';
import { bookDeliveryParamsBaseSchema } from './delivery_journeys';

export const bookDeliveryPendingEndpoint = (orderId: string): string =>
  `${v3MyToshiBaseUrl}/orders/${orderId}/pending/delivery_journeys`;

export const bookDeliveryWithPriceParamsSchema = bookDeliveryParamsBaseSchema
  .extend({
    chargeablePriceOptions: z.array(z.number()),
  })
  .transform(transformParams);
