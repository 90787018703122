import { z } from 'zod';

export const zodNonEmptyString = () => z.string().trim().min(1);

export const notifiableErrorSchema = z.union([
  z.instanceof(Error),
  z.object({
    errorClass: z.string(),
    errorMessage: z.string(),
  }),
  z.object({
    name: z.string(),
    message: z.string(),
  }),
  z.string(),
]);
