import { zodNonEmptyString } from '@utils/helpers/validation';
import { mapKeysToSnake } from '@data/transformers/objects/mapKeysToSnake';
import { z } from 'zod';

export const generateReauthEmailEndpoint = 'v2/mytoshi/reauth';

export const reauthParamsSchema = z
  .object({
    orderId: zodNonEmptyString(),
    email: z.string().email(),
  })
  .transform(mapKeysToSnake);

export const reauthDTOSchema = z.object({
  ok: z.boolean(),
});
