import { useRef } from 'react';
import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr';
import { type ZodError, type ZodTypeAny, type z } from 'zod';

import { fetcher, type FetcherError } from '../api/fetchers';
import { handleApiError } from '../api/handleApiError';
import { type ValidateConfig } from '../api/validator';
import { useNavigateUnauthorised } from './useNavigateUnauthorised';

export type SWRAbortResponse<Data, Error> = SWRResponse<Data, Error> & {
  abort: () => void;
};

export type UseGetDataOptions = Omit<Partial<SWRConfiguration>, 'onError'>;

type UseGetDataParams<T extends ZodTypeAny> = {
  schemaConfig: ValidateConfig<T>;
  key: string;
  onError?: (err: FetcherError | ZodError | Error) => unknown;
  options?: UseGetDataOptions;
};

export const useGetData = <T extends ZodTypeAny>({ key, schemaConfig, onError, options }: UseGetDataParams<T>) => {
  type Response = z.output<T>;

  const abortControllerRef = useRef<AbortController>();
  const abort = () => abortControllerRef.current?.abort();

  const navigateUnauthorised = useNavigateUnauthorised();

  const res = useSWR<Response, FetcherError, typeof key>(
    key,
    async (url) => {
      try {
        abortControllerRef.current = new AbortController();

        const data = await fetcher<Response>(url, {
          resSchemaConfig: schemaConfig,
          signal: abortControllerRef.current.signal,
        });

        return data;
      } catch (err: FetcherError | ZodError | Error | unknown) {
        await handleApiError(err, navigateUnauthorised, onError);
      }
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      ...options,
    },
  );

  return { ...res, abort };
};
