import { Navigate, generatePath } from 'react-router';
import { useMediaQuery } from 'usehooks-ts';

import { DeliveryInfo } from '@components/features/OrderInfoCard/DeliveryInfo';
import { Page } from '@pages/Page';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { Column, ResponsiveGridWithHeader } from '@components/ui/Containers';
import { StyledH1, StyledParagraph } from '@components/ui/Typography';
import { isMobileMq } from '@config/styling/breakPoints';
import { useMyToshiDocumentTitle } from '@hooks/useMyToshiDocumentTitle';
import { useOrderId } from '@hooks/useOrderId';
import { createToastState } from '@hooks/useURLToast';
import { useTranslations } from '@locale/useTranslations';
import { useGetOrder } from '@data/hooks/useGetOrder';
import { RoutePaths } from 'src/router';

import { ToshiInfoList } from './ToshiInfoList';
import { UpgradeForm } from './upgradeForm/UpgradeForm';
import { DhlBanner } from './dhlOrderInfo/DhlBanner';
import { WaybillNumber } from './dhlOrderInfo/WaybillNumber';
import { translations } from './translations';

export const Checkout = () => {
  const { translationsContent, getTranslationWithValues } = useTranslations(translations);

  useMyToshiDocumentTitle(translationsContent.pageTitle);

  const { id } = useOrderId();

  const { data: order, isLoading, error } = useGetOrder(id);

  const isMobile = useMediaQuery(isMobileMq);

  if (isLoading) {
    return <LoadingSpinner copy="Fetching order details" />;
  }

  if (error) {
    throw error;
  }

  if (order) {
    if (order.chargeableAddOns.length === 0 && order.isScheduled()) {
      const path = generatePath(RoutePaths.order, { id });
      const query = createToastState({ type: 'success', message: translationsContent.deliveryAlreadyUpgraded });
      return <Navigate to={`${path}?${query}`} />;
    }

    const dhlBanner = <DhlBanner translations={translationsContent} />;

    return (
      <Page>
        <ResponsiveGridWithHeader>
          <Column as="header" $noPadding $gap={1.5}>
            <StyledH1>{translationsContent.checkoutTitle}</StyledH1>
            {isMobile ? (
              dhlBanner
            ) : (
              <StyledParagraph $size={5} $noMargin>
                {getTranslationWithValues(translationsContent.checkoutSubtitle, [order.customer.firstName])}
              </StyledParagraph>
            )}
          </Column>
          <UpgradeForm order={order} />

          <Column $noPadding $gap={2}>
            <ToshiInfoList />
            {!isMobile && dhlBanner}
            <WaybillNumber
              translations={translationsContent}
              orderNumber={order.orderNumber}
              brandName={order.onBehalfOfBrandName ?? order.brandName}
            />
            <DeliveryInfo
              translations={translationsContent}
              deliveryAddress={order.unscheduledOrderAddress!}
              isReturnJourney={false}
            />
          </Column>
        </ResponsiveGridWithHeader>
      </Page>
    );
  }
};
